<template>
  <button class="btn m-0" type="submit" value="submit" v-bind:disabled="submitLabel ? true : false">
    <span v-if="!submitLabel">{{ label }}</span>
    <span v-if="submitLabel == 'loading'"><i class="icon-line-loader icon-spin"></i></span>
    <span v-if="submitLabel == 'success'"><i class="icon-checkmark"></i></span>
    <span v-if="submitLabel == 'error'"><i class="icon-line-cross"></i></span>
  </button>
</template>

<script>

export default {
  name: 'FormSubmit',
  props: {
    label: String,
    status: String
  },
  data () {
    return {
      submitLabel: null,
    }
  },
  watch: {
    status(val) {
      this.submitLabel = val;
      if (val == 'success' || val == 'error') {
        setTimeout(() => {
          this.submitLabel = null;
        }, 2000);
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .form-footer .btn {
    color: #711414;
    padding: 10px;
    border: 3px solid #711414;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 700;
    margin-top: 40px !important;
  }
  .form-footer .btn:hover {
    color: #222;
    border: 3px solid #911414;
  }
  .form-footer .btn:focus {
      box-shadow: none;
  }
  .form-footer .btn i {
    padding: 0 20px;
  }

  .btn.whiteSubmit {
    color: #fff;
    border: 3px solid #fff;
  }
  .btn.whiteSubmit:hover {
    color: #aaa;
    border: 3px solid #aaa;
  }
</style>
