<template>
    <div class="sidebar col-lg-12">
        <div class="sidebar-wrap">
            <ul>
                <li v-bind:class="{ active: getRouteName == 'account'}"><router-link :to="{ name: 'account'}"><div>Digital <span class="hideMobile">Game</span> <i class="icon-gamepad"></i></div></router-link></li>
                <li v-bind:class="{ active: getRouteName == 'printables'}"><router-link :to="{ name: 'printables'}"><div>Printable<span class="hideMobile"> Kit</span>s <i class="icon-file-pdf1"></i></div></router-link></li>
                <li class="floatRight hideMobile"><a href="#" v-on:click.stop.prevent="logOut()"><div>Log out <i class="icon-door-open"></i></div></a></li>
                <li class="floatRight profileLink" v-bind:class="{ active: getRouteName == 'profile'}"><router-link :to="{ name: 'profile'}"><div>Profile <i class="icon-pen"></i></div></router-link></li>
            </ul>

        </div>
    </div>
</template>

<script>
export default {
    name: 'AccountMenu',
    props: {
        icon: String
    },
    computed: {
        getRouteName () {
          return this.$route.name
        }
    },
    methods: {
        logOut() {
            sessionStorage.removeItem('Cursed_CustomerReference')
            this.$cookies.remove('Cursed_CustomerReference')
            this.$store.dispatch("setAuth", null)
            this.$router.push({ name: 'login' })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .sidebar h4,
    .talesContent h4 {
        color: #fff;
        font-size: 30px;
    }

    .sidebar ul {
        list-style: none;
        margin: 0;
    }
    .sidebar ul li {
        display: inline-block;
        list-style: none;
        font-size: 20px;
        padding: 5px 20px;
    }
    .sidebar ul li a {
        font-family: 'Andada Pro', sans-serif;
        color: #fff;
        font-size: 30px;
        text-shadow: 1px 1px #000;
    }
    .sidebar ul li i {
        color: #000;
        text-shadow: 1px 1px #cc2626;
    }
    .sidebar ul li.floatRight {
        float: right;
    }
    .sidebar ul li.active {
        border: 2px solid #fff;
        border-bottom: none;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        background: #fff;
    }
    .sidebar ul li.active a {
        color: #762323;
        text-shadow: none;
    }
    .sidebar ul li.active i {
        color: #762323;
        text-shadow: none;
    }

    @media (max-width: 589px) {
        .sidebar ul li.profileLink {
            display: none;
        }
    }
</style>
