import Vue from 'vue'
import VueRouter from 'vue-router'

import HomeContainer from './containers/HomeContainer.vue'
import TaleContainer from './containers/TaleContainer.vue'
import PrintableContainer from './containers/PrintableContainer.vue'

import LoginContainer from './containers/LoginContainer.vue'
import GameCodeContainer from './containers/GameCodeContainer.vue'
import FeedbackContainer from './containers/FeedbackContainer.vue'
import ForgotPasswordContainer from './containers/ForgotPasswordContainer.vue'
import NewPasswordContainer from './containers/NewPasswordContainer.vue'
import AccountContainer from './containers/AccountContainer.vue'
import AccountPrintablesContainer from './containers/AccountPrintablesContainer.vue'
import AccountProfileContainer from './containers/AccountProfileContainer.vue'
import NotFoundContainer from './containers/NotFoundContainer.vue'

import PageContainer from './containers/PageContainer.vue'
import PageTwoContainer from './containers/PageTwoContainer.vue'
//import PageThreeContainer from './containers/PageThreeContainer.vue'

import GiveawayContainer from './containers/GiveawayContainer.vue'
import GiveawayTwoContainer from './containers/GiveawayTwoContainer.vue'

import PrintableSolutionContainer from './containers/PrintableSolutionContainer.vue'
import PrintableSolutionTwoContainer from './containers/PrintableSolutionTwoContainer.vue'
import PrintableSolutionThreeContainer from './containers/PrintableSolutionThreeContainer.vue'
import PrintableSolutionFourContainer from './containers/PrintableSolutionFourContainer.vue'
import PrintableSolutionFiveContainer from './containers/PrintableSolutionFiveContainer.vue'
import PrintableSolutionSixContainer from './containers/PrintableSolutionSixContainer.vue'
import PrintableSolutionSevenContainer from './containers/PrintableSolutionSevenContainer.vue'

//import TalesContainer from './containers/TalesContainer.vue'
//import PurchaseContainer from './containers/PurchaseContainer.vue'

Vue.use(VueRouter);

const routes = [
  { path: '/',  name: 'home', component: HomeContainer },
  { path: '/tale/:taleSlug',  name: 'home', component: TaleContainer },
  { path: '/printable/:printableSlug',  name: 'home', component: PrintableContainer },

  { path: '/giftcode', name: 'giftcode', component: GameCodeContainer },
  { path: '/gamecode', name: 'giftcode', component: GameCodeContainer },
  { path: '/feedback', name: 'feedback', component: FeedbackContainer },

  { path: '/login', name: 'login', component: LoginContainer },
  { path: '/reset', name: 'reset', component: ForgotPasswordContainer },
  { path: '/newpassword', name: 'reset', component: NewPasswordContainer },

  { path: '/account', name: 'account', component: AccountContainer },
  { path: '/printables', name: 'printables', component: AccountPrintablesContainer },
  { path: '/profile', name: 'profile', component: AccountProfileContainer },
  { path: '/404', name: 'not_found', component: NotFoundContainer },

  { path: '/printable/solution/escape-the-date', name: 'escape_the_date', component: PrintableSolutionContainer },
  { path: '/printable/solution/death-marked-love', name: 'death_marked_love', component: PrintableSolutionTwoContainer },
  { path: '/printable/solution/the-wicked-wand', name: 'the_wicked_wand', component: PrintableSolutionThreeContainer },
  { path: '/printable/solution/an-eggcelent-adventure', name: 'an_eggcelent_adventure', component: PrintableSolutionFourContainer },
  { path: '/printable/solution/the-missing-moviestar', name: 'the_missing_moviestar', component: PrintableSolutionFiveContainer },
  { path: '/printable/solution/mystery-manor', name: 'mystery_manor', component: PrintableSolutionSixContainer },
  { path: '/printable/solution/howl-a-ween', name: 'mystery_manor', component: PrintableSolutionSevenContainer },

  { path: '/guide/how-to-play-using-zoom', name: 'guide_zoom', component: PageContainer },
  { path: '/guide/how-do-the-digital-games-work', name: 'guide_digital_games', component: PageTwoContainer },
  //{ path: '/guide/how-do-the-printable-games-work', name: 'guide_digital_games', component: PageThreeContainer },

  { path: '/giveaway/the-mother-ebook', name: 'giveaway_mother_ebook', component: GiveawayContainer },
  { path: '/giveaway/the-forest-ebook', name: 'giveaway_forest_ebook', component: GiveawayTwoContainer },
  { path: '*', name: 'not_found', component: NotFoundContainer },
]

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    if(to || from)
      window.scrollTo(0, 0);

      let customerReference = sessionStorage.getItem('Cursed_CustomerReference')
      if (!customerReference && Vue.$cookies.isKey('Cursed_CustomerReference'))
        customerReference = Vue.$cookies.get('Cursed_CustomerReference')

    if(to && (to.name == "account" || to.name == "profile") && !customerReference)
      next({ name: 'login' })

    next();
})

router.afterEach(() => {
  window.scrollTo(0, 0)
})

export default router
