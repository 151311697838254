<template>
  <div id="content-wrapper">
    <page-title ptitle="How to play using Zoom"></page-title>

    <section id="content">
        <div class="sketch-bottom"></div>
        <div class="content-wrap page-content">

            <div class="container page-container clearfix">
              <h2>How to share your screen on Zoom within a meeting</h2>
              <p>1. Join or start a Zoom meeting.</p>
              <p>2. In your meeting, you'll see a selection of meeting controls, including the ability to mute your voice, start and stop video, and more. Select the green Share Screen icon.</p>
              <img src="https://cursed-tales.com/assets-sales/img/zoom-help1.jpg" alt="Zoom Help 1">
              <p>3. Choose the screen you want to share. Click the screen on your computer that you want to share.</p>
              <img src="https://cursed-tales.com/assets-sales/img/zoom-help2.jpg" alt="Zoom Help 2">
              <p>4. Click share.</p>
            </div>

        </div>
    </section>

  </div>
</template>

<script>
import PageTitle from '../components/PageTitle.vue'

export default {
  name: 'PageContainer',
  components: {
    PageTitle,
  },
  props: {
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .page-container h2 {
    font-family: 'Andada Pro', sans-serif !important;
    font-size: 50px;
    font-weight: 900;
    color: #000;
    text-shadow: 1px 1px #cc2626;
    text-align: center;
  }
  .page-container p {
    font-family: 'Andada Pro', sans-serif !important;
    font-size: 20px;
    color: #fff;
  }
  .page-container img {
    margin-bottom: 20px;
  }
</style>
