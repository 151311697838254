<template>
  <div id="content-wrapper">
    <page-title ptitle="" short="true"></page-title>

    <section id="content">
        <div class="sketch-bottom"></div>
        <div class="content-wrap page-content">

            <div class="container solution-container clearfix">
                <div class="logo"></div>
                <div class="lock-container" v-if="!showConclusion">
                    <p>Enter the nine digit code below to escape</p>
                    <div class="lock">
                        <div class="buttons button-1" v-on:click="clickNum(1)"></div>
                        <div class="buttons button-2" v-on:click="clickNum(2)"></div>
                        <div class="buttons button-3" v-on:click="clickNum(3)"></div>
                        <div class="buttons button-4" v-on:click="clickNum(4)"></div>
                        <div class="buttons button-5" v-on:click="clickNum(5)"></div>
                        <div class="buttons button-6" v-on:click="clickNum(6)"></div>
                        <div class="buttons button-7" v-on:click="clickNum(7)"></div>
                        <div class="buttons button-8" v-on:click="clickNum(8)"></div>
                        <div class="buttons button-9" v-on:click="clickNum(9)"></div>
                        <div class="buttons button-0" v-on:click="clickNum(0)"></div>

                        <div class="code">
                            <p>Entered code:</p>
                            <div class="numbers" v-bind:class="numbersClass">{{ code }}</div>
                            <a href="#" v-on:click.stop.prevent="unlock()" v-if="!success">Unlock</a>
                            <a href="#" class="reset" v-on:click.stop.prevent="resetNum()" v-if="!success">Reset</a>
                        </div>
                    </div>
                </div>
                <div class="success-container" v-if="showConclusion">
                    <p>Great work! You have escaped the Mystery Manor Hotel and that creepy concierge!</p>
                    <p>Using the phone and hotel menu allowed you to access the elevator plan before you soon found yourself in the bar with the intriguing invoice.</p>
                    <p>Even lost property and broken keys could not hold you back as you unraveled clues on crumpled-up notes and tricky towels, finally cracking the lock code in your bid for freedom.</p>
                    <p>Now you can quickly speed off and get to the wedding on time!</p>

                    <div class="congrats">
                        <p>Congratulations!</p>
                    </div>
                </div>
            </div>

        </div>
    </section>

  </div>
</template>

<script>
import PageTitle from '../components/PageTitle.vue'

export default {
    name: 'PrintableSolutionSixContainer',
    components: {
        PageTitle,
    },
    props: {
    },
    data () {
        return {
            success: false,
            showConclusion: false,
            code: "",
            solution: "983472013",
            numbersClass: "",
        }
    },
    watch: {
    },
    methods: {
        clickNum: function(num) {
            if (this.code.length < 9)
                this.code = this.code + num;
        },
        resetNum: function() {
            this.code = "";
        },
        unlock: function() {
            this.success = false;
            if (this.code == this.solution) {
                this.code = "CORRECT";
                this.success = true;
                this.numbersClass = "correct";
                setTimeout(() => {
                    this.showConclusion = true;
                }, 2000);

            } else {
                this.code = "INCORRECT";
                this.numbersClass = "incorrect";
                setTimeout(() => {
                    this.code = "";
                    this.numbersClass = "";
                }, 2000);

            }
        },
    },
    mounted: function() {
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .solution-container .logo {
        position: relative;
        width: 520px;
        height: 339px;
        background-repeat: no-repeat !important;
        background-size: contain !important;
        background: url('https://cursed-tales.com/assets-sales/img/printables/mysery-manor-logo.png');
        margin: 20px auto 20px;
    }
    .solution-container .lock-container {
        text-align: center;
    }
    .solution-container .lock-container p {
        font-family: 'Andada Pro', sans-serif;
        color: #fff;
        font-size: 32px;
        font-weight: 400;
    }
    .solution-container .lock-container .lock {
        position: relative;
        width: 350px;
        height: 368px;
        background-repeat: no-repeat !important;
        background-size: contain !important;
        background: url('https://cursed-tales.com/assets-sales/img/printables/death-marked-love-lock.png');
        margin: 20px auto 20px;
    }
    .solution-container .lock-container .lock .buttons {
        position: absolute;
        height:25px;
        width: 25px;
        cursor: pointer;
    }
    .solution-container .lock-container .lock .buttons.button-1 {
        top: 39px;
        left: 37px;
    }
    .solution-container .lock-container .lock .buttons.button-2 {
        top: 74px;
        left: 37px;
    }
    .solution-container .lock-container .lock .buttons.button-3 {
        top: 108px;
        left: 37px;
    }
    .solution-container .lock-container .lock .buttons.button-4 {
        top: 142px;
        left: 37px;
    }
    .solution-container .lock-container .lock .buttons.button-5 {
        top: 179px;
        left: 37px;
    }
    .solution-container .lock-container .lock .buttons.button-6 {
        top: 39px;
        left: 117px;
    }
    .solution-container .lock-container .lock .buttons.button-7 {
        top: 74px;
        left: 117px;
    }
    .solution-container .lock-container .lock .buttons.button-8 {
        top: 108px;
        left: 117px;
    }
    .solution-container .lock-container .lock .buttons.button-9 {
        top: 142px;
        left: 117px;
    }
    .solution-container .lock-container .lock .buttons.button-0 {
        top: 179px;
        left: 117px
    }
    .solution-container .lock-container .lock .code {
        position: absolute;
        width: 200px;
        top: 6px;
        left: 203px;
    }
    .solution-container .lock-container .lock .code p {
        font-size: 22px;
        margin-bottom: 10px;
    }
    .solution-container .lock-container .lock .code .numbers {
        position: relative;
        height: 44px;
        width: 200px;
        border-radius: 20px;
        border: 3px solid #fff;
        font-family: 'Andada Pro', sans-serif;
        font-size: 20px;
        letter-spacing: 2px;
        color: #ccc;
        margin-bottom: 20px;
        padding-top: 3px;
    }
    .solution-container .lock-container .lock .code .numbers.correct {
        background: #24851e;
    }
    .solution-container .lock-container .lock .code .numbers.incorrect {
        background: #ad1d1d;
    }
    .solution-container .lock-container .lock .code a {
        font-size: 20px;
        color: #fff;
        display: block;
        margin: 15px 0;
        background: #24851e;
        margin: 0 20px 10px;
        border-radius: 5px;
        border: 2px solid #fff;
    }
    .solution-container .lock-container .lock .code a.reset {
        background: #ad1d1d;
    }
    .solution-container .success-container {
        margin: 0 auto;
        width: 800px;
        font-family: 'Andada Pro', sans-serif;
        color: #fff;
        font-size: 30px;
        font-weight: 400;
    }
    .solution-container .success-container .congrats {
        font-size: 60px;
        color: #dd8c36;
        text-align: center;
    }
    .solution-container .success-container .congrats p {
        margin-bottom: 5px;
        line-height: 1;
    }
    .solution-container .success-container .congrats span {
        color: #fff;
    }

    @media (max-width: 800px) {
        .solution-container .success-container {
            width: 100%;
            text-align: center;
            font-size: 26px;
        }
    }

    @media (max-width: 557px) {
        .solution-container .lock-container .lock {
            position: relative;
            width: 250px;
            height: 303px;
            background-repeat: no-repeat !important;
            background-size: contain !important;
            background: url('https://cursed-tales.com/assets-sales/img/printables/death-marked-love-lock-alt.png');
            margin: 20px auto 20px;
        }
        .solution-container .lock-container {
            min-height: 900px;
        }
        .solution-container .lock-container .lock .code {
            position: absolute;
            width: 200px;
            top: 316px;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
        .solution-container .lock-container .lock .buttons {
            height:30px;
            width: 30px;
        }
        .solution-container .lock-container .lock .buttons.button-1 {
            top: 53px;
            left: 52px;
        }
        .solution-container .lock-container .lock .buttons.button-2 {
            top: 99px;
            left: 52px;
        }
        .solution-container .lock-container .lock .buttons.button-3 {
            top: 146px;
            left: 52px;
        }
        .solution-container .lock-container .lock .buttons.button-4 {
            top: 194px;
            left: 52px;
        }
        .solution-container .lock-container .lock .buttons.button-5 {
            top: 241px;
            left: 52px;
        }
        .solution-container .lock-container .lock .buttons.button-6 {
            top: 53px;
            left: 155px;
        }
        .solution-container .lock-container .lock .buttons.button-7 {
            top: 99px;
            left: 155px;
        }
        .solution-container .lock-container .lock .buttons.button-8 {
            top: 146px;
            left: 155px;
        }
        .solution-container .lock-container .lock .buttons.button-9 {
            top: 194px;
            left: 155px;
        }
        .solution-container .lock-container .lock .buttons.button-0 {
            top: 241px;
            left: 155px;
        }
        .solution-container .success-container .congrats {
            font-size: 50px;
        }
        .solution-container .success-container .congrats span {
            font-size: 40px;
        }
    }

    @media (max-width: 538px) {
        .solution-container .logo {
            width: 400px;
            height: 169px;
        }
    }

    @media (max-width: 403px) {
        .solution-container .logo {
            width: 300px;
            height: 127px;
        }
    }

</style>
