<template>
  <div id="content-wrapper">
    <page-title ptitle="Page not found"></page-title>

    <section id="content">
        <div class="sketch-bottom"></div>
        <div class="content-wrap page-content">

            <div class="container notfound-container clearfix">
              <h2>404</h2>
              <p>THE PAGE YOU WERE LOOKING FOR, COULDN'T BE FOUND.</p>
              <router-link :to="{ name: 'home'}" class="backHome">Back to home</router-link>
            </div>

        </div>
    </section>

  </div>
</template>

<script>
import PageTitle from '../components/PageTitle.vue'

export default {
  name: 'NotFoundContainer',
  components: {
    PageTitle,
  },
  props: {
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .notfound-container {
    font-family: 'Andada Pro', sans-serif;
    text-align: center;
  }
  .notfound-container h2 {
    font-size: 200px;
    color: #000;
    text-shadow: 1px 1px #cc2626;
    line-height: 150px;
  }
  .notfound-container p {
    font-size: 30px;
    color: #ffffff;
  }
 .backHome  {
    color: #fff;
    padding: 10px;
    border: 3px solid #fff;
    border-radius: 5px;
    font-size: 42px;
    font-weight: 700;
    margin-top: 20px !important;
  }
 .backHome:hover {
    color: #aaa;
    border: 3px solid #aaa;
  }

</style>
