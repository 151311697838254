<template>
  <div id="content-wrapper">
    <page-title ptitle="My Account"></page-title>

    <section id="content">
        <div class="sketch-bottom"></div>
        <div class="content-wrap page-content">

            <div class="container login-container clearfix">

                <div class="accordion accordion-lg mx-auto mb-0 clearfix" style="max-width: 550px;">

                    <div class="accordion-header" v-on:click="setFormReveal('login')">
                        <div class="accordion-title">
                            <span class="horrorFont blackFont crimsonShadow">Login to your Account</span>
                        </div>
                    </div>
                    <div class="accordion-content clearfix" v-if="formReveal == 'login'">

                        <form-builder v-bind:submitMethod="submitFunction" v-bind:formData="form" v-on:form-error="setFormError">
                            <div class="col-12 form-group">
                                <form-input name="login-email" label="Email Address:" v-model="form.data.email" v-bind:validation="form.validation.email" v-bind:messages="form.error_messages.email" v-bind:class="['whiteInput']"></form-input>
                            </div>

                            <div class="col-12 form-group">
                                <form-input name="login-password" label="Password:" v-model="form.data.password" type="password" v-bind:validation="form.validation.password" v-bind:messages="form.error_messages.password" v-bind:class="['whiteInput']"></form-input>
                            </div>
                            <div class="col-12 forgotPassword">
                              <router-link :to="{ name: 'reset'}">Forgot your password?</router-link>
                            </div>
                            <div class="col-12 form-group form-footer">
                              <div class="row">
                                <div class="col-12" v-if="form.validation.main">
                                  <p class="errorMsg"><i class="icon-warning-sign"></i> {{ form.validation.main }}</p>
                                </div>
                                <div class="col-12">
                                  <form-submit label="Login to Account" v-bind:status="form.status" v-bind:class="['whiteSubmit']"></form-submit>
                                </div>
                              </div>
                            </div>
                        </form-builder>
                    </div>

                    <!--
                    <div class="accordion-header" v-on:click="setFormReveal('register')">
                        <div class="accordion-title">
                            <span class="horrorFont">New Signup? Register for an Account</span>
                        </div>
                    </div>
                    <div class="accordion-content clearfix accordion-active" v-if="formReveal == 'register'">
                       <form-builder v-bind:submitMethod="registerFunction" v-bind:formData="registerForm" v-on:form-error="setRegisterFormError">
                            <div class="col-6 form-group">
                                <form-input name="regiser-name" label="Fullname:" v-model="registerForm.data.name" v-bind:validation="registerForm.validation.name" v-bind:messages="registerForm.error_messages.name"></form-input>
                            </div>

                            <div class="col-6 form-group">
                                <form-input name="regiser-address" label="Address 1:" v-model="registerForm.data.address" v-bind:validation="registerForm.validation.address" v-bind:messages="registerForm.error_messages.address"></form-input>
                            </div>

                            <div class="col-6 form-group">
                                <form-input name="regiser-address2" label="Address 2:" v-model="registerForm.data.address2" v-bind:validation="registerForm.validation.address2" v-bind:messages="registerForm.error_messages.address2"></form-input>
                            </div>

                            <div class="col-6 form-group">
                                <form-input name="regiser-city" label="City:" v-model="registerForm.data.city" v-bind:validation="registerForm.validation.city" v-bind:messages="registerForm.error_messages.city"></form-input>
                            </div>

                            <div class="col-6 form-group">
                                <form-input name="regiser-county" label="County:" v-model="registerForm.data.county" v-bind:validation="registerForm.validation.county" v-bind:messages="registerForm.error_messages.county"></form-input>
                            </div>

                            <div class="col-6 form-group">
                                <form-input name="regiser-postcode" label="Postcode:" v-model="registerForm.data.postcode" v-bind:validation="registerForm.validation.postcode" v-bind:messages="registerForm.error_messages.postcode"></form-input>
                            </div>

                            <div class="col-6 form-group">
                                <form-input name="regiser-email" label="Email:" v-model="registerForm.data.email" v-bind:validation="registerForm.validation.email" v-bind:messages="registerForm.error_messages.email"></form-input>
                            </div>

                            <div class="col-6 form-group">
                                <form-input name="regiser-password" label="Password:" type="password" v-model="registerForm.data.password" v-bind:validation="registerForm.validation.password" v-bind:messages="registerForm.error_messages.password"></form-input>
                            </div>

                            <div class="col-12 form-group form-footer">
                              <div class="row">
                                <div class="col-8">
                                  <span v-if="!registerForm.validation.main">&nbsp;</span>
                                  <p class="errorMsg" v-else>{{ registerForm.validation.main }}</p>
                                </div>
                                <div class="col-4">
                                  <form-submit label="Register" v-bind:status="registerForm.status"></form-submit>
                                </div>
                              </div>
                            </div>
                        </form-builder>
                    </div>
                    -->

                </div>

            </div>

        </div>
    </section>

  </div>
</template>

<script>
import axios from 'axios'
import PageTitle from '../components/PageTitle.vue'
import FormBuilder from '../components/FormBuilder.vue'
import FormInput from '../components/FormInput.vue'
import FormSubmit from '../components/FormSubmit.vue'

export default {
  name: 'LoginContainer',
  components: {
    PageTitle,
    FormBuilder,
    FormInput,
    FormSubmit
  },
  props: {
  },
  data () {
    return {
      formReveal: 'login',

      form: {
        data: {
          email: this.$developer ? "adamwalker297@gmail.com" : null,
          password: this.$developer ? "Foxhound3285" : null,
        },
        validation: {
          email: ['required'],
          password: ['required'],
          main: null
        },
        error_messages: {},
        status: null
      },
      registerForm: {
        data: {
          name: this.$developer ? "Adam Walker" : null,
          email: this.$developer ? "adamwalker297@gmail.com" : null,
          address: this.$developer ? "9 Dobbin Close" : null,
          address2: null,
          city: this.$developer ? "Rawtenstall" : null,
          county: this.$developer ? "Lancashire" : null,
          postcode: this.$developer ? "BB4 7TH" : null,
          password: this.$developer ? "Foxhound3285" : null,
        },
        validation: {
          name: ['required'],
          email: ['required','email'],
          address: ['required'],
          address2: [],
          city: ['required'],
          county: ['required'],
          postcode: ['required'],
          password: ['required','password'],
          main: null
        },
        error_messages: {},
        status: null
      }
    }
  },
  watch: {

  },
  methods: {
    setFormReveal: function (val) {
      this.formReveal = val
    },

    setFormError(error) {
      this.form.validation.main = error
      setTimeout(() => {
        this.form.validation.main = null
      }, 4000);
    },
    submitFunction: function () {
      this.form.status = "loading";

      axios
        .post('https://api.cursed-tales.com/auth', this.form.data)
        .then(response => {
          console.log("response", response)
          sessionStorage.setItem('Cursed_CustomerReference', response.data.customer.reference);
          this.$cookies.set('Cursed_CustomerReference', response.data.customer.reference, 60 * 60 * 24);
          this.$store.dispatch("setAuth", response.data.customer)

          setTimeout(() => {
            this.$router.push({ name: 'account' })
          }, 2000);
        })
        .catch(error => {
          console.log(error)
          this.form.status = "error";
          //this.form.error_messages = error.response.data.error.validation_failed
          this.form.validation.main = "Incorrect username and/or password"
        })
    },

    setRegisterFormError(error) {
      this.registerForm.validation.main = error
      setTimeout(() => {
        this.registerForm.validation.main = null
      }, 4000);
    },
    registerFunction: function () {
      this.registerForm.status = "loading";

      axios
        .post('https://api.cursed-tales.com/customers', this.registerForm.data)
        .then(response => {
          console.log("response", response)
          sessionStorage.setItem('Cursed_CustomerReference', response.data.reference);
          this.$cookies.set('Cursed_CustomerReference', response.data.reference, 60 * 60 * 24);
          this.$store.dispatch("setAuth", response.data)

          setTimeout(() => {
            this.$router.push({ name: 'account' })
          }, 2000);
        })
        .catch(error => {
          console.log(error.response)
          this.registerForm.error_messages = error.response.data.error.validation_failed
          this.registerForm.status = "error";
          //this.registerForm.validation.main = "Incorrect username and/or password"
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .accordion { margin-bottom: 20px; }
  .accordion-header {
      padding: 0.75rem 0;
      border-top: 1px dotted #DDD;
      cursor: pointer;
  }
  .accordion-header .accordion-title {
    font-size: 60px;
    cursor: default;
    text-align: center;
  }
  .accordion:not([data-collapsible="true"]) .accordion-header.accordion-active { cursor: auto; }
  .accordion-header:first-child { border-top: none; }
  .accordion-header:not(.accordion-active) .accordion-open,
  .accordion-active .accordion-closed { display: none; }
  .accordion-active .accordion-open { display: inline-block; }
  .accordion-content {
      padding-top: 0;
      padding-bottom: 1.5rem;
  }
  .accordion-bg .accordion-header {
      background-color: #EEE;
      padding: 0.75rem;
      border-radius: 2px;
      margin: 0;
      margin-bottom: 5px;
      border-top: 0;
  }
  .accordion-bg .accordion-content {
      padding: 0.75rem 0.75rem 1.5rem;
  }
  .accordion-border {
      border: 1px solid #DDD;
      border-radius: 4px;
  }
  .accordion-border .accordion-header {
      border-color: #CCC;
      padding: 0.75rem;
      margin: 0;
  }
  .accordion-border .accordion-content {
      padding: 0 1.125rem 1.5rem;
  }
  .accordion-lg .accordion-header {
      font-size: 1.25rem;
  }

  .forgotPassword {
    text-align: right;
  }
  .forgotPassword a {
    color: #fff;
    text-align: right;
  }

  .form-footer .col-12 {
    text-align: right;
  }
  .form-footer .errorMsg {
    margin: 0;
  }
</style>
