import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'

Vue.use(Vuex);

const state = {
    auth: null,

    tales: {},
    printables: {},
    talesLoaded: false,
}

const getters = {
    getAuth: (state) => {
        return state.auth
    },
    getTalesData: (state) => {
        return {
            loaded: state.talesLoaded,
            data: state.tales
        }
    },
    getPrintablesData: (state) => {
        return {
            loaded: state.talesLoaded,
            data: state.printables
        }
    }
}

const actions = {
    setAuth({ commit }, auth) {
        commit('SET_AUTH', auth)
    },

    checkTales({ commit, state }) {
        commit('SET_TALES', state.tales)
    },
    getTales({ commit }, customerToken) {
        axios.get('https://api.cursed-tales.com/init?customer=' + customerToken)
            .then(response => {
                commit('SET_TALES', response.data)
            })
    }
}

const mutations = {
    SET_AUTH(state, auth) {
        state.auth = auth
    },
    SET_TALES(state, data) {
        state.talesLoaded = true
        state.tales = data.tales
        if (data.customer)
            state.auth = data.customer
        if (data.printables)
            state.printables = data.printables
    }
}

export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations
})
