<template>
  <div id="content-wrapper">
    <page-title ptitle="My Account"></page-title>

    <section id="content">
        <div class="sketch-bottom"></div>
        <div class="content-wrap page-content">

            <div class="container reset-container clearfix">

                <div class="accordion accordion-lg mx-auto mb-0 clearfix">

                    <div class="accordion-header">
                        <div class="accordion-title">
                            <span class="horrorFont blackFont crimsonShadow">Forgot your password?</span>
                        </div>
                    </div>
                    <div class="accordion-content clearfix">

                        <form-builder
                          v-if="!resetSuccess"
                          v-bind:submitMethod="submitFunction"
                          v-bind:formData="form"
                          v-on:form-error="setFormError"
                        >
                            <div class="col-12 form-group">
                                <form-input name="login-email" label="Email Address:" v-model="form.data.email" v-bind:validation="form.validation.email" v-bind:messages="form.error_messages.email" v-bind:class="['whiteInput']"></form-input>
                            </div>
                            <div class="col-12 forgotPassword">
                                <router-link :to="{ name: 'login'}"><i class="icon-chevron-left1"></i> Return to login page</router-link>
                            </div>

                            <div class="col-12 form-group form-footer">
                              <div class="row">
                                <div class="col-12" v-if="form.validation.main">
                                  <p class="errorMsg"><i class="icon-warning-sign"></i> {{ form.validation.main }}</p>
                                </div>
                                <div class="col-12">
                                  <form-submit label="Send Password Reset Request" v-bind:status="form.status" v-bind:class="['whiteSubmit']"></form-submit>
                                </div>
                              </div>
                            </div>
                        </form-builder>
                        <div class="resetSuccess" v-if="resetSuccess">
                          <h3>Thank you</h3>
                          <p>Your request has been sent, if we find an account with the email address <strong>{{ form.data.email }}</strong> you will shortly receive an email</p>
                          <p class="spamWarning"><u>Please note:</u> If you haven't shortly received an email from us please check your spam/junk or promotion folders</p>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    </section>

  </div>
</template>

<script>
import axios from 'axios'
import PageTitle from '../components/PageTitle.vue'
import FormBuilder from '../components/FormBuilder.vue'
import FormInput from '../components/FormInput.vue'
import FormSubmit from '../components/FormSubmit.vue'

export default {
  name: 'ForgotPasswordContainer',
  components: {
    PageTitle,
    FormBuilder,
    FormInput,
    FormSubmit
  },
  props: {
  },
  data () {
    return {
      form: {
        data: {
          email: this.$developer ? "adamwalker297@gmail.com" : null,
        },
        validation: {
          email: ['required','email'],
          main: null
        },
        error_messages: {},
        status: null
      },
      resetSuccess: false
    }
  },
  watch: {

  },
  methods: {
    setFormError(error) {
      this.form.validation.main = error
      setTimeout(() => {
        this.form.validation.main = null
      }, 4000);
    },
    submitFunction: function () {
      this.form.status = "loading";

      axios
        .post('https://api.cursed-tales.com/reset', this.form.data)
        .then(response => {
          console.log("response", response)
          this.form.status = "loading";
          this.resetSuccess = true
        })
        .catch(error => {
          console.log(error)
          this.form.status = "error";
          this.form.validation.main = "There was an error while processing this request"
        })
    },
  },
  mounted: function() {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .accordion { margin-bottom: 20px; }
  .accordion-header {
      padding: 0.75rem 0;
      border-top: 1px dotted #DDD;
      cursor: pointer;
  }
  .accordion-header .accordion-title {
    font-size: 60px;
    cursor: default;
    text-align: center;
  }
  .accordion:not([data-collapsible="true"]) .accordion-header.accordion-active { cursor: auto; }
  .accordion-header:first-child { border-top: none; }
  .accordion-header:not(.accordion-active) .accordion-open,
  .accordion-active .accordion-closed { display: none; }
  .accordion-active .accordion-open { display: inline-block; }
  .accordion-content {
      padding-top: 0;
      padding-bottom: 1.5rem;
  }
  .accordion-content {
    position: relative;
    width: 600px;
    margin: 0 auto;
  }
  .accordion-border {
      border: 1px solid #DDD;
      border-radius: 4px;
  }
  .accordion-border .accordion-header {
      border-color: #CCC;
      padding: 0.75rem;
      margin: 0;
  }
  .accordion-border .accordion-content {
      padding: 0 1.125rem 1.5rem;
  }
  .accordion-lg .accordion-header {
      font-size: 1.25rem;
  }

  .forgotPassword {
    text-align: right;
  }
  .forgotPassword a {
    color: #fff;
    text-align: right;
  }
  .forgotPassword a i {
    margin-right: 5px;
    font-size: 10px;
  }
  .form-footer .col-12 {
    text-align: right;
  }
  .form-footer .errorMsg {
    margin: 0;
  }

  .resetSuccess h3 {
    margin-bottom: 10px;
    color: #fff;
  }
  .resetSuccess p {
    color: #ccc;
  }

  .spamWarning {
    color: #ccc !important;
    font-size: 18px;
  }
</style>
