<template>
  <div id="content-wrapper" v-if="printable">
    <div class="modal-wrapper" v-if="revealModal">
      <div class="modal-overlay"></div>
      <div class="modal-scroller">
        <div class="modal-content">
          <buy-form
            v-if="revealModalType == 'buy'"
            v-bind:currency="currency"
            v-bind:type="taleType"
            v-bind:tale_id="taleId"
            v-bind:promotion="promotion"
            v-on:close-form="closeForm()"
          ></buy-form>
          <video-trailer
            v-if="revealModalType == 'trailer'"
            v-bind:trailer="revealModalTrailer"
            v-on:close-form="closeForm()"
          ></video-trailer>
        </div>
      </div>
    </div>

    <page-title v-bind:ptitle="printable.name" psubtitle="Printable Escape Room Kit"></page-title>

    <section id="content">

        <div class="sketch-top"></div>

        <div class="content-wrap">

            <div class="section mb-0 pt-0 product-section pb-lg-6">
                <div class="anchor" id="digital-games"></div>
                <div class="container games-section-container">

                    <div class="row col-mb-50 online-games-section games-section">
                        <div class="col-4 how-content activeGame">
                            <div class="game-content">
                                <img v-bind:src="printable.featured_image_url" v-bind:alt="printable.name">
                                <div class="clear"></div>
                            </div>
                        </div>
                        <div class="col-8 taleDetails motherDetails pbox-content px-0">
                            <h3 class="pt0"><i class="icon-line-book "></i> STORY &amp; GAMEPLAY</h3>
                            <p>{{ printable.description }}</p>
                            <div class="featureList">
                                <div class="featureItem" v-if="printable.players">
                                    <p><span><i class="icon-users1 icon-lg"></i></span><strong>Number of players:</strong> {{ printable.players }}</p>
                                </div>
                                <div class="featureItem" v-if="printable.gameplay">
                                    <p><span><i class="icon-gamepad1 icon-lg"></i></span><strong>Gameplay:</strong> {{ printable.gameplay }}</p>
                                </div>
                                <div class="featureItem" v-if="printable.age">
                                    <p><span><i class="icon-clock icon-lg"></i></span><strong>Age:</strong> {{ printable.age }}</p>
                                </div>
                                <div class="featureItem" v-if="printable.difficulty">
                                    <p><span><i class="icon-skull icon-lg"></i></span>Difficulty Options:</p>
                                    <p class="difficultyOptions">
                                        <i class="icon-lock skullFirst"></i>
                                        <i class="icon-lock" v-bind:class="{ skullBlack: printable.difficulty < 2 }"></i>
                                        <i class="icon-lock" v-bind:class="{ skullBlack: printable.difficulty < 3 }"></i>
                                        <i class="icon-lock" v-bind:class="{ skullBlack: printable.difficulty < 4 }"></i>
                                        <i class="icon-lock" v-bind:class="{ skullBlack: printable.difficulty < 5 }"></i>
                                    </p>
                                </div>
                                <div class="clear"></div>
                            </div>

                            <h3><i class="icon-line-scissors"></i> SET-UP &amp; ADDITIONAL MATERIALS</h3>
                            <p>To set up the game, you will only need paper, a color printer, and a pair of scissors to give yourself the full experience. To check the final solution, you will need internet access to either scan the QR code or enter the web address manually, where you can enter and check your final solution.</p>
                            <p>Perfect for date nights, valentines, party nights, and the perfect excuse for a bit of fun on your night in.</p>

                            <h3><i class="icon-file-pdf"></i> INCLUDED DOCUMENTS</h3>
                            <p>1. A PDF file with the instructions and game materials</p>
                            <p>2. A PDF file with the walkthrough of the game and solutions</p>

                            <div class="buySection">
                                <div class="clear"></div>
                                <a href="#"
                                   class="buy whiteBg"
                                   v-on:click.stop.prevent="buyTale(printable.id)"
                                >
                                    <i class="icon-play"></i> Print now for <span v-if="showDiscounted" class="discounted">{{ currencySign + originalPrice }}</span> {{ currencySign + totalPrice }}
                                </a>
                            </div>
                        </div>
                        <div class="clearfix"></div>

                    </div>
                </div>
            </div>

            <div class="section m-0 clearfix how-section">
              <div class="anchor" id="faq"></div>
              <div class="container">

                <div class="heading-block center border-bottom-0 bottommargin-sm">
                  <h2 class="nott how-header">Frequently Asked Questions</h2>
                </div>

                <div class="row col-mb-50">
                  <div class="col-lg-6">
                    <p class="question" v-on:click="revealAnswer(1)">Q. When can I play and is there a time limit?</p>
                    <p class="answer" v-if="revealFAQ==1">A. Once purchased your game will be added to your account. You can then <strong>login and play any day or time with no time limit</strong> to complete the game!</p>
                    <p class="question" v-on:click="revealAnswer(3)">Q. How big can my team be?</p>
                    <p class="answer" v-if="revealFAQ==3">A. A good recommendation from us is between 1 and 6 people, but there is no rule or limit. Our tales in fact can be enjoyed alone, with a small group or fill a room with your friends and family and all experience them together.</p>
                    <p class="question" v-on:click="revealAnswer(5)">Q. What is the recommended minimum age?</p>
                    <p class="answer" v-if="revealFAQ==5">A. Due to the adult themes within our tales we recommend that you are 16 or over to play.</p>
                    <p class="question" v-on:click="revealAnswer(7)">Q. Can I buy as a gift for someone?</p>
                    <p class="answer" v-if="revealFAQ==7">A. Yes you can buy our tales as an exciting gift for friends and family to enjoy themselves, just check the ‘buy as gift’ option when purchasing a tale.</p>
                    <p class="question" v-on:click="revealAnswer(9)">Q. How long does it take?</p>
                    <p class="answer" v-if="revealFAQ==9">A. There is no time limit and the time it takes to complete can vary depending the difficulty you choose and your experience. For example, choosing Casual on 'The Mother' will likely take you around 40 minutes, while Standard difficulty on 'The Forest' can take, on average, 1 hour and 30 minutes.</p>
                  </div>
                  <div class="col-lg-6">
                    <p class="question" v-on:click="revealAnswer(2)">Q. What devices are compatible with the game?</p>
                    <p class="answer" v-if="revealFAQ==2">A. A laptop or desktop is the best way to play. Tablets are compatible but not advised due to the small screens.</p>
                    <p class="question" v-on:click="revealAnswer(4)">Q. Can I play with friends in different places?</p>
                    <p class="answer" v-if="revealFAQ==4">A. Yes our tales are designed to be enjoyed from the comfort of your own home however this does not mean one home. Whether you are playing with friends in the next street or family in another country you can all be together in the tale, breaking the curse using the screen share option in Zoom.</p>
                    <p class="question" v-on:click="revealAnswer(6)">Q. What are the technical requirements?</p>
                    <p class="answer" v-if="revealFAQ==6">A. Our tales are web based and require no extra downloads as they are hosted on your browser. We recommend playing on a desktop or laptop to achieve the best interactive quality and visual content. It is compatible to be played on a tablet but  just to be aware that the overall game play experience may be reduced slightly.  We do not recommend using a mobile phone to play the game.</p>
                    <p class="question" v-on:click="revealAnswer(8)">Q. How accessible is it for people?</p>
                    <p class="answer" v-if="revealFAQ==8">A. All of our puzzles comprise of a visual aspect and all can be completed without the audio effects. There is also an audio aspect to any text based clue or content which would aid people who struggle to read.</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="clear"></div>

            <div class="section mb-0 reviews-section">
              <div class="anchor" id="reviews"></div>
              <div class="container clearfix">

                <div class="heading-block center border-bottom-0">
                  <h2 class="nott reviews-header">What Our Customers Say</h2>
                </div>

                <div class="row clearfix">

                  <div class="col-lg-3 col-md-6">
                    <div class="review">
                      <div class="review-desc review-desc-bg">
                        <p><span>"</span>Really enjoyed cursed tales the mother we completed it as a family 2 adults and 2 children it really kept us entertained some of the clues had us really guessing but we solved it in the end. Ready for the next one now.<span>"</span></p>
                        <div class="review-title">
                          <img src="https://cursed-tales.com/assets-sales/img/reviewer4.jpg">
                          <h4 class="fw-normal">Gail Fu <i class="icon-star"></i><i class="icon-star"></i><i class="icon-star"></i><i class="icon-star"></i><i class="icon-star"></i><span>3 November at 16:46</span></h4>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-6">
                    <div class="review">
                      <div class="review-desc review-desc-bg">
                        <p><span>"</span>This was a fun game. The story telling is fantastic and the puzzles entertaining. Defo recommend it. Great start!<span>"</span></p>
                        <div class="review-title">
                          <img src="https://cursed-tales.com/assets-sales/img/reviewer1.jpg">
                          <h4 class="fw-normal">Janine Sinclair <i class="icon-star"></i><i class="icon-star"></i><i class="icon-star"></i><i class="icon-star"></i><i class="icon-star"></i><span>29 October at 10:09</span></h4>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-6">
                    <div class="review">
                      <div class="review-desc review-desc-bg">
                        <p><span>"</span>Lucky enough to have a preview of cursed tales. Excellent creepy story line. The tech was really easy to follow. Really good puzzles and riddles to solve. Really enjoyed our night getting to the bottom of the story of 'The Mother' and would highly recommend!<span>"</span></p>
                        <div class="review-title">
                          <img src="https://cursed-tales.com/assets-sales/img/reviewer3.jpg">
                          <h4 class="fw-normal">Becky Walker <i class="icon-star"></i><i class="icon-star"></i><i class="icon-star"></i><i class="icon-star"></i><i class="icon-star"></i><span>28 October at 19:31</span></h4>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-6">
                    <div class="review">
                      <div class="review-desc review-desc-bg">
                        <p><span>"</span>Managed to get a sneak preview and really enjoyed playing. Creepy storyline which flows through the game and interesting puzzles to solve. Lots of fun, would recommend.<span>"</span></p>
                        <div class="review-title">
                          <img src="https://cursed-tales.com/assets-sales/img/reviewer2.jpg">
                          <h4 class="fw-normal">Chris Flanagan <i class="icon-star"></i><i class="icon-star"></i><i class="icon-star"></i><i class="icon-star"></i><i class="icon-star"></i><span>26 October at 15:46</span></h4>
                        </div>
                      </div>
                    </div>
                  </div>

                <div class="col-lg-12 col-md-12 moreLink">
                    <div class="trustpilot-widget" data-locale="en-GB" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="61962218f9766f44e8470542" data-style-height="80px" data-style-width="100%" data-theme="dark" data-min-review-count="50" data-style-alignment="center">
                        <a href="https://uk.trustpilot.com/review/cursed-tales.com" target="_blank" rel="noopener">Trustpilot</a>
                    </div>
                </div>

                </div>
              </div>
            </div>
        </div>

    </section>

  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import PageTitle from '../components/PageTitle.vue'
import BuyForm from '../components/BuyForm.vue'
import VideoTrailer from '../components/VideoTrailer.vue'

export default {
  name: 'PrintableContainer',
  components: {
    PageTitle,
    BuyForm,
    VideoTrailer
  },
  props: ['taleSlug'],
  data () {
    return {
        printables: [],

        printable: null,
        printableFound: false,

        currency: "GBP",
        currencySign: "£",
        originalPrice: "9.99",
        subtotalPrice: this.$defaultGBP || "9.99",
        totalPrice: this.$defaultGBP || "9.99",
        giftPrice: "6.99",
        showDiscounted: false,
        taleType: "Digital",
        taleId: 1,

        promotion: null,

        revealFAQ: null,
        revealModal: false,
        revealModalType: null,
        revealModalTrailer: null,
    }
  },
  computed: {
    getPrintables() {
      return this.$store.getters.getPrintablesData.data
    },
    ...mapGetters(['getPrintablesData']),
  },
  watch: {
  },
  mounted: function() {
    this.printables = this.getPrintables
    for (let printable of this.printables)
        if (printable.slug == this.$route.params.printableSlug) {
            this.printable = printable
            this.printableFound = true;
        }

    if (!this.printable)
        this.$router.push({ name: 'home' })

    axios
        .post('https://api.cursed-tales.com/misc/get_active_promotion?type=home' + (this.$developer ? '&developer=true' : ''), {})
        .then(response => {
            console.log("get_active_promotion", response.data)
            if (response.data.country == "US") {
                this.currency = "USD"
                this.setCurrency()
            }

            if (response.data.code) {
                this.promotion = response.data.code
                this.subtotalPrice = response.data.default
                this.totalPrice = response.data.default

                this.showDiscounted = false;
                if (parseFloat(this.totalPrice) < parseFloat(this.originalPrice))
                    this.showDiscounted = true;
            }

        })
  },
  methods: {
    setCurrency() {
      if (this.currency == 'USD') {
        this.currencySign = "$"
        this.originalPrice = "13.99"
        this.subtotalPrice = this.$defaultUSD || "13.99"
        this.totalPrice = this.$defaultUSD || "13.99"
        this.giftPrice = "9.79"
      }
    },
    buyTale(taleId, type = "Printable") {
        this.taleType = type
        this.taleId = taleId
        this.revealModal = true
        this.revealModalType = "buy"
    },
    buyGift() {
      this.revealModal = true
      this.revealModalType = "gift"
    },
    modalTrailer(trailer) {
      this.revealModal = true
      this.revealModalType = "trailer"
      this.revealModalTrailer = trailer
      this.$emit('modal-active', true)
    },
    modalGallery() {
      this.revealModal = true
      this.revealModalType = "gallery"
      this.$emit('modal-active', true)
    },
    closeForm() {
      this.revealModal = false
      this.revealModalType = null
      this.revealModalTrailer = null
      this.$emit('modal-active', false)
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .section.how-section {
        background-image: url(https://cursed-tales.com/assets-sales/img/border-bottom-red-black.jpg) !important;
    }
    .section.how-section .container {
        margin-top: 30px;
    }
    .featureIcon i {
        color: #000;
    }
    .taleDetails h3 {
        color: #fff;
    }
    .taleDetails h3.pt0 {
        padding-top: 0;
    }
</style>
