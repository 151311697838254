<template>
    <form class="row mb-0" @submit="submitForm">
        <slot></slot>
    </form>
</template>

<script>
import formValidation from '../mixins/FormValidation'

export default {
  name: 'FormBuilder',
  mixins: [formValidation],
  props: {
    submitMethod: Function,
    formData: Object,
    formBusy: Boolean
  },
  data () {
    return {
      formBusyWorking: false,
    }
  },
  watch: {
    formBusy(val) {
      this.formBusyWorking = val
    },
  },
  methods: {
    setFormReveal: function (val) {
      this.formReveal = val
    },
    submitForm: function (e) {
      if (!this.formBusyWorking) {
        //console.log("form processing...");
        //this.formBusyWorking = true;
        let formError = false;

        for (let dataKey in this.formData.validation) {
          if (this.formData.validation[dataKey] !== null) {
            for (let validation of this.formData.validation[dataKey]) {
              if (validation == "required" && !this.formData.data[dataKey])
                formError = true;
              else if (validation == "email" && !this.validEmail(this.formData.data[dataKey]))
                formError = true;
              else if (validation == "password" && !this.validPassword(this.formData.data[dataKey]))
                formError = true;

            }
          }
        }


        if (formError) {
          this.$emit('form-error', "Please check the form and try again")
          this.formBusyWorking = false;
        } else {
          this.$emit('form-error', null)
          this.submitMethod()
        }

        e.preventDefault();
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  label {
      display: inline-block;
      font-size: 18px;
      font-family: 'Andada Pro', sans-serif;
      font-weight: 600;
      letter-spacing: 1px;
      margin-bottom: 10px;
      color: #711414;
  }
  label.error {
      margin-top: 5px;
      color: #E42C3E;
      font-weight: 400;
  }
  label.error { display: none !important; }
  .submitContainer {
    text-align: right;
  }
  #card-element {
    padding: 6px;
    background: #ddd;
    border: 3px solid #000;
    border-radius: 5px;
    margin-bottom: 10px;
    min-height: 40px;
  }
  #card-element:focus,
  #card-element:active {
    border-color: #888;
  }
  .errorMsg {
    color: #E42C3E !important;
  }
  .whiteInput label {
    color: #fff;
  }
</style>
