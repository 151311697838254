export default {
  methods: {
    validEmail: function (email) {
      let regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regEx.test(email ? email.trim() : "");
    },
    validPassword: function (password) {
      let regEx = /^(?=.*[a-z])(?=.*\d)[A-Za-z\d$@$£!%*?&#'_"%&()+,-./:;<=>[\\\]^`{|}~ ]{6,}$/;
      return password && password.length >= 6 && regEx.test(password);
    }
  }
};