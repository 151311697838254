<template>
    <section id="title_image" class="title-element title_wrapper include-header"  v-bind:class="{ short: short }">
        <div class="title-container">
            <div class="container">
                <div class="title-caption">
                    <div>
                        <h1 class="fw-normal nott horrorFont">{{ ptitle }}</h1>
                        <h2 v-if="psubtitle">{{ psubtitle }}</h2>
                    </div>
                </div>
            </div>
            <div class="title-bg" v-bind:class="backgroundbg"></div>
        </div>

        <div class="sketch-top"></div>
    </section>
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    ptitle: String,
    psubtitle: String,
    backgroundbg: String,
    short: [Boolean,String]
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title_wrapper {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
}
.title_wrapper.short {
    height: 250px;
}
.title_wrapper .title-container {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    overflow: hidden;
}
.title-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.title-bg.buy-bg {
    background-image: url('../../public/img/single-bg1.jpg');
}
.title-element .container {
    position: relative;
    height: 100%;
    margin-top:  70px;
}
.title-caption {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    flex-flow: column;
    z-index: 20;
    max-width: 900px;
    color: #eee;
    font-size: 1.375rem;
    font-weight: 300;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
    -webkit-transition: top 0.3s ease;
    -o-transition: top 0.3s ease;
    transition: top 0.3s ease;
    margin-top: 200px;
}
.title-caption h1 {
    color: #fff;
    text-shadow: 2px 2px #000;
    font-size: 70px;
    margin-bottom: 0px;
}
.title-caption h1.crimson-h1 {
    color: #762323;
}
.title-caption h2 {
    font-family: 'Andada Pro', sans-serif;
    text-transform: uppercase;
    font-size: 23px;
    letter-spacing: 3px;
    color: #bbb;
    text-shadow: 2px 2px #000;
}

.title-caption p {
    font-size: 35px;
    color: #000;
    text-shadow: 1px 1px #fff;
    margin-top: 30px;
    margin-bottom: 10px;
}

@media (max-width: 991.98px) {
    .title_wrapper .title-caption h2 {
        font-size: 5.5vw;
    }
    .title_wrapper .title-caption p {
        font-size: 2.6vw;
    }
}

.content-wrap.page-content {
    padding: 50px 0;
}
</style>
