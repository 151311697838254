<template>
  <div id="content-wrapper">
    <page-title ptitle=""></page-title>

    <section id="content">
        <div class="sketch-bottom"></div>
        <div class="content-wrap page-content">

            <div class="container login-container clearfix">

                <div class="accordion accordion-lg mx-auto mb-0 clearfix" v-bind:style="stage != 'game' ? { 'max-width': '550px' } : {}">

                    <div class="accordion-header">
                        <div class="accordion-title">
                            <span class="horrorFont blackFont crimsonShadow" v-if="stage == 'code'">Enter your code</span>
                            <span class="horrorFont blackFont crimsonShadow" v-if="stage == 'customer'">Enter your details</span>
                            <span class="horrorFont blackFont crimsonShadow" v-if="stage == 'game'">Select your game</span>
                        </div>
                    </div>
                    <div class="accordion-content clearfix" v-if="stage == 'code'">

                        <form-builder v-bind:submitMethod="submitFunction" v-bind:formData="form" v-on:form-error="setFormError">
                            <div class="col-12 form-group">
                                <form-input name="code" v-model="form.data.code" v-bind:validation="form.validation.code" v-bind:messages="form.error_messages.code" v-bind:class="['whiteInput']"></form-input>
                            </div>

                            <div class="col-12 form-group form-footer">
                                <div class="row">
                                    <div class="col-12" v-if="form.validation.main">
                                        <p class="errorMsg"><i class="icon-warning-sign"></i> {{ form.validation.main }}</p>
                                    </div>
                                    <div class="col-12">
                                        <form-submit label="Submit" v-bind:status="form.status" v-bind:class="['whiteSubmit']"></form-submit>
                                    </div>
                                </div>
                            </div>
                        </form-builder>
                    </div>
                    <div class="accordion-content clearfix" v-if="stage == 'customer'">

                        <form-builder v-bind:submitMethod="submitCustomerFunction" v-bind:formData="customerForm" v-on:form-error="setCustomerFormError">
                            <div class="col-12 form-group">
                                <form-input name="name" label="Name:" v-model="customerForm.data.name" v-bind:validation="customerForm.validation.name" v-bind:messages="customerForm.error_messages.name" v-bind:class="['whiteInput']"></form-input>
                            </div>

                            <div class="col-12 form-group">
                                <form-input name="email" label="Email Address:" v-model="customerForm.data.email" v-bind:validation="customerForm.validation.email" v-bind:messages="customerForm.error_messages.email" v-bind:class="['whiteInput']"></form-input>
                            </div>

                            <div class="col-12 form-group">
                                <form-input name="password" label="Password:" v-model="customerForm.data.password" type="password" v-bind:validation="customerForm.validation.password" v-bind:messages="customerForm.error_messages.password" v-bind:class="['whiteInput']"></form-input>
                            </div>

                            <div class="col-md-12 form-group checkBoxWrapper whiteInput">
                                <div class="checkBox"
                                    v-bind:class="{ activated:  customerForm.data.subscribe }"
                                    v-on:click="customerForm.data.subscribe=!customerForm.data.subscribe"
                                >
                                    <i class="icon-check" v-if="customerForm.data.subscribe"></i>
                                </div>
                                <label>Subscribe to receive updates about future offers and new tales</label>
                            </div>

                            <div class="col-12 form-group form-footer">
                              <div class="row">
                                <div class="col-12" v-if="customerForm.validation.main">
                                  <p class="errorMsg"><i class="icon-warning-sign"></i> {{ customerForm.validation.main }}</p>
                                </div>
                                <div class="col-12">
                                  <form-submit label="Submit" v-bind:status="customerForm.status" v-bind:class="['whiteSubmit']"></form-submit>
                                </div>
                              </div>
                            </div>
                        </form-builder>
                    </div>
                    <div class="accordion-content clearfix" v-if="stage == 'game'">

                        <div class="talesContent col-lg-12">

                            <table class="table" v-if="tales.length">
                              <tbody v-for="tale in tales" :key="tale.id">
                                <tr>
                                    <td class="taleCell">
                                        <img v-bind:src="tale.featured_image2_url" v-bind:alt="tale.name">
                                        <h3>{{ tale.name }}</h3>
                                        <p>{{ tale.short }}</p>

                                        <p class="difficultyOptions" v-if="tale.id == 1"><strong>Difficulty Rating:</strong> <i class="icon-skull skullFirst"></i><i class="icon-skull"></i><i class="icon-skull"></i><i class="icon-skull skullBlack"></i><i class="icon-skull skullBlack"></i></p>
                                        <p class="difficultyOptions" v-if="tale.id == 2"><strong>Difficulty Rating:</strong> <i class="icon-skull skullFirst"></i><i class="icon-skull"></i><i class="icon-skull"></i><i class="icon-skull"></i><i class="icon-skull skullBlack"></i></p>
                                        <p class="difficultyOptions" v-if="tale.id == 3"><strong>Difficulty Rating:</strong> <i class="icon-skull skullFirst"></i><i class="icon-skull"></i><i class="icon-skull"></i><i class="icon-skull"></i><i class="icon-skull"></i></p>
                                    </td>
                                    <td>
                                        <div class="difficultySelect">
                                            <a href="#" v-on:click.stop.prevent="setupGame(tale.id)" class="playTale">Choose Game</a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>

                        </div>
                    </div>
                </div>

            </div>

        </div>
    </section>

  </div>
</template>

<script>
import axios from 'axios'
import PageTitle from '../components/PageTitle.vue'
import FormBuilder from '../components/FormBuilder.vue'
import FormInput from '../components/FormInput.vue'
import FormSubmit from '../components/FormSubmit.vue'

export default {
    name: 'GameCodeContainer',
    components: {
        PageTitle,
        FormBuilder,
        FormInput,
        FormSubmit
    },
    props: {
    },
    data () {
        return {
            stage: "code",
            tales: [],
            showPlayOptions: null,
            customer: null,
            code: null,
            form: {
                data: {
                    code: this.$developer ? "TESTCODE" : null,
                },
                validation: {
                    code: ['required'],
                    main: null
                },
                error_messages: {},
                status: null
            },
            customerForm: {
                data: {
                    name: this.$developer ? "Adam Walker" : null,
                    email: this.$developer ? "adamwalker297@gmail.com" : null,
                    password: this.$developer ? "Foxhound3285" : null,
                    subscribe: false,
                    game_code_id: null,
                },
                validation: {
                    name: ['required'],
                    email: ['required'],
                    password: ['required'],
                    main: null
                },
                error_messages: {},
                status: null
            },
        }
    },
    watch: {
    },
    methods: {
        getTales: function () {
            axios
                .get('https://api.cursed-tales.com/tales')
                .then(response => {
                    console.log("response", response)
                    this.tales = response.data.data
                })
                .catch(error => {
                    console.log(error)
                })
        },
        setFormError: function (error) {
            this.form.validation.main = error
            setTimeout(() => {
                this.form.validation.main = null
            }, 4000);
        },
        submitFunction: function () {
            this.form.status = "loading";

            axios
                .post('https://api.cursed-tales.com/gamecodes/check', this.form.data)
                .then(response => {
                    console.log("response", response);
                    this.code = response.data;
                    if (this.code.customer) {
                        sessionStorage.setItem('Cursed_CustomerReference', this.code.customer.reference);
                        this.$cookies.set('Cursed_CustomerReference', this.code.customer.reference, 60 * 60 * 24);
                        this.$store.dispatch("setAuth", this.code.customer)
                        this.customer = this.code.customer;

                        if (this.code.redeemed) {
                            this.$router.push({ name: 'account' })
                        } else {
                            if (this.code.type == "all") {
                                this.setupGame("all");
                            } else {
                                this.stage = "game"
                            }
                        }

                    } else {
                        this.customerForm.data.game_code_id = this.code.id;
                        this.form.status = "success";
                        this.stage = "customer";
                        setTimeout(() => {
                            this.form.status = null;
                        }, 1000);
                    }

                })
                .catch(error => {
                    console.log(error)
                    this.form.status = "error";
                    this.form.validation.main = error.response.data.error.message
                })
        },
        setCustomerFormError: function (error) {
            this.customerForm.validation.main = error
            setTimeout(() => {
                this.customerForm.validation.main = null
            }, 4000);
        },
        submitCustomerFunction: function () {
            this.customerForm.status = "loading";

            axios
                .post('https://api.cursed-tales.com/gamecodes/register', this.customerForm.data)
                .then(response => {
                    console.log("response", response);
                    this.customerForm.status = "success"
                    sessionStorage.setItem('Cursed_CustomerReference', response.data.reference);
                    this.$cookies.set('Cursed_CustomerReference', response.data.reference, 60 * 60 * 24);
                    this.$store.dispatch("setAuth", response.data)
                    this.customer = response.data;

                    if (this.code.type == "all") {
                        this.setupGame("all");
                    } else {
                        this.stage = "game"
                    }

                    setTimeout(() => {
                        this.customerForm.status = null
                    }, 1000);
                })
                .catch(error => {
                    console.log(error)
                    this.customerForm.status = "error"
                    this.customerForm.error_messages = { email: [error.response.data.error.message] }
                    this.customerForm.validation.main = "There was an issue while trying to register your details"
                })
        },
        setupGame: function (tale_id) {

            let setupData = {
                code_id: this.code.id,
                customer_id: this.customer.id,
                tale_id: tale_id,
            }

            axios
                .post('https://api.cursed-tales.com/gamecodes/setup', setupData)
                .then(response => {
                    console.log("response", response);
                    this.$store.dispatch("setAuth", response.data)
                    this.customer = response.data

                    this.$router.push({ name: 'account' })
                })
                .catch(error => {
                    console.log(error)

                })
        },
    },
    mounted: function() {
        this.getTales()
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .accordion { margin-bottom: 20px; }
    .accordion-header {
        padding: 0.75rem 0;
        border-top: 1px dotted #DDD;
        cursor: pointer;
    }
    .accordion-header .accordion-title {
        font-size: 60px;
        cursor: default;
        text-align: center;
    }
    .accordion:not([data-collapsible="true"]) .accordion-header.accordion-active { cursor: auto; }
    .accordion-header:first-child { border-top: none; }
    .accordion-header:not(.accordion-active) .accordion-open,
    .accordion-active .accordion-closed { display: none; }
    .accordion-active .accordion-open { display: inline-block; }
    .accordion-content {
        padding-top: 0;
        padding-bottom: 1.5rem;
    }
    .accordion-bg .accordion-header {
        background-color: #EEE;
        padding: 0.75rem;
        border-radius: 2px;
        margin: 0;
        margin-bottom: 5px;
        border-top: 0;
    }
    .accordion-bg .accordion-content {
        padding: 0.75rem 0.75rem 1.5rem;
    }
    .accordion-border {
        border: 1px solid #DDD;
        border-radius: 4px;
    }
    .accordion-border .accordion-header {
        border-color: #CCC;
        padding: 0.75rem;
        margin: 0;
    }
    .accordion-border .accordion-content {
        padding: 0 1.125rem 1.5rem;
    }
    .accordion-lg .accordion-header {
        font-size: 1.25rem;
    }

    .forgotPassword {
        text-align: right;
    }
    .forgotPassword a {
        color: #fff;
        text-align: right;
    }

    .form-footer .col-12 {
        text-align: right;
    }
    .form-footer .errorMsg {
        margin: 0;
    }

    .form-group.checkBoxWrapper .checkBox {
        display: inline-block;
        float: left;
        width: 25px;
        height: 25px;
        border: 2px solid #fff;
        border-radius: 2px;
        margin: 10px 10px 0 0;
        cursor: pointer;
    }
    .form-group.checkBoxWrapper .checkBox.activated {
        border: 2px solid #2dab3d;
    }
    .form-group.checkBoxWrapper .checkBox i {
        font-size: 20px;
        line-height: 22px;
        margin-left: 1px;
        color: #2dab3d;
    }
    .form-group.checkBoxWrapper label {
        display: block;
        margin-top: 10px;
    }

  .talesContent .table {
      color: #d6d6d6;
  }

  .talesContent .table tr {
      position: relative;
      border-bottom: 2px solid #ddd;
  }
  .talesContent .table tr:last-child {
      border-bottom: none;
  }
  .talesContent .table td {
    position: relative;
    padding: 10px 0;
    vertical-align: top;
    border: none;
  }

  .talesContent .table th {
      font-size: 18px;
      color: #711414;
  }
  .talesContent .table th.thCenter {
      text-align: center;
  }
  .talesContent .table .taleCell {
    padding-left: 220px;
    padding-right: 20px;
    width: 60%;
    padding-bottom: 40px;
  }

  .talesContent .table .taleCell .statusLabel {
    position: absolute;
    top: 20px;
    right: 0;
  }
  .talesContent .table .taleCell .statusLabel .completed {
    font-family: 'Andada Pro', sans-serif;
    color: #3bad16;
    padding: 8px;
    border: 2px solid #3bad16;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 400;
    margin: 0 20px 20px;
  }
  .talesContent .table .taleCell .statusLabel .pending {
    font-family: 'Andada Pro', sans-serif;
    color: #d59f07;
    padding: 8px;
    border: 2px solid #d59f07;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 400;
    margin: 0 20px 20px;
  }

  .talesContent .table .taleCell img {
      position: absolute;
      top: 0;
      left: 0;
      width: 180px;
      margin-right: 10px;
  }

  .talesContent .table .taleCell h3 {
      font-family: 'Andada Pro', sans-serif;
      font-size: 50px;
      color: #ffffff;
      margin-bottom: 0;
  }
  .talesContent .table .difficultySelect {
    margin-top: 90px;
  }
  .talesContent .table .taleCell p {
      font-size: 18px;
      color: #bbbbbb;
      margin-bottom: 10px;
  }
  .talesContent .table .taleCell p.warning {
    font-size: 20px;
    color: #da1717;
  }

  .talesContent .table .taleCell i.skullFirst {
    margin-left: 10px;
  }
  .talesContent .table .taleCell i.skullBlack {
    color: #000;
    opacity: 0.2;
  }

  .talesContent .table .detailsCell {
    width: 15%;
    text-align: center;
    vertical-align: middle;
    padding: 0 20px;
    border-left: 1px solid #eee;
    border-right: 1px solid #ddd;
  }
  .talesContent .table .detailsCell .playTime {
    padding: 10px;
    background: #eee;
    margin-bottom: 10px;
    border: 3px solid #ddd;
  }
  .talesContent .table .detailsCell h4 {
    font-family: 'Andada Pro', sans-serif;
    font-size: 22px;
    color: #762323;
    margin-bottom: 10px;
  }
  .talesContent .table .detailsCell p {
    font-size: 15px;
    color: #333;
    margin-bottom: 10px;
  }
  .talesContent .table .detailsCell a.facebookShare {
    display: block;
    color: #072aa7;
    padding: 5px;
    border: 2px solid #072aa7;
    border-radius: 3px;
    font-size: 15px;
    font-weight: 700;
    margin: 10px 0;
    cursor: pointer;
  }
  .talesContent .table .detailsCell p.gameHints {
    color: #c84d4d
  }
  .talesContent .table .detailsCell p.gameSolutions {
    color: #b01717
  }

  .talesContent .table .playCell {
    width: 15%;
    text-align: center;
    vertical-align: middle;
    border-left: 1px solid #ddd;
  }

  .talesContent .table .playTale,
  .noTales a {
    color: #dddddd;
    padding: 10px;
    border: 5px solid #fff;
    border-radius: 5px;
    font-size: 32px;
    font-weight: 700;
    margin-top: 20px !important;
  }
  .talesContent .table .playTale:hover,
  .noTales a:hover {
    color: #222;
    border: 5px solid #911414;
  }
  .talesContent .table .playTale.expertOption {
    display: block;
    color: #711414;
    padding: 7px;
    border: 3px solid #711414;
    border-radius: 5px;
    font-size: 22px;
    font-weight: 700;
    margin-top: 20px !important;
  }
  .talesContent .table .playTale.expertOption:hover {
    color: #222;
    border: 3px solid #911414;
  }

  .talesContent .table .zoomHelp {
    display: block;
    padding-top: 20px;
    color: #333;
    font-size: 14px;
  }
</style>
