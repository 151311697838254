<template>

    <div class="checkout-form">
        <div class="closeForm" v-on:click="closeForm()">
            <i class="icon-times"></i>
        </div>
        <h3>Buy a gift</h3>

        <div class="col-lg-12 form-group gift-select" v-if="!purchaseSuccess">
            <h4>Select gift voucher type</h4>
            <a href="#" class="buy"
                v-bind:class="{ active: purchaseForm.data.type == 'digital' }"
                v-on:click.stop.prevent="selectType('digital')"
            ><i class="icon-file-pdf"></i> Digital Gift for <span>{{ currencySign + totalPrice }}</span></a>
            <span class="or">or</span>
            <a href="#" class="buy"
                v-bind:class="{ active: purchaseForm.data.type == 'physical' }"
                v-on:click.stop.prevent="selectType('physical')"
            ><i class="icon-gift"></i> Physical Gift for <span>{{ currencySign + totalPricePhysical }}</span></a>
            <p>A digital gift card will be emailed immediately in PDF form, while a physical gift card will be posted to your recipient.</p>
            <p>This gift can be redeemed for one of our games. The choice of what game to play will be chosen by the recipient when they redeem the gift.</p>
        </div>

        <div v-if="purchaseForm.data.type">
            <div class="col-12 seperator"></div>

            <div class="col-lg-12 form-group" v-if="!purchaseSuccess">
                <h4>Gift voucher details</h4>
            </div>

            <form-builder
                v-if="!purchaseSuccess"
                v-bind:submitMethod="purchaseFunction"
                v-bind:formData="purchaseForm"
                v-on:form-error="setPurchaseFormError"
            >
            <div class="col-12 form-group">
                <form-input
                  name="purchase-gift-message"
                  label="Personalised message"
                  placeholder="Enter personalised gift message"
                  v-model="purchaseForm.data.gift_message"
                  v-bind:validation="[]"
                  v-bind:class="['yellowInput']"
                ></form-input>
            </div>
            <div class="col-6 form-group">
                <form-input
                  name="purchase-from"
                  v-bind:label="'From'"
                  placeholder="Enter who is sending this gift"
                  v-model="purchaseForm.data.gift_from"
                  v-bind:validation="purchaseForm.validation.gift_from"
                  v-bind:messages="purchaseForm.error_messages.gift_from"
                  v-bind:class="['yellowInput']"
                ></form-input>
            </div>

            <div class="col-6 form-group giftMessage">
                <form-input
                  name="purchase-recipient-email"
                  label="Recipient's email"
                  placeholder="Enter recipient's email"
                  v-model="purchaseForm.data.gift_recipient"
                  v-bind:validation="purchaseForm.validation.gift_recipient"
                  v-bind:messages="purchaseForm.error_messages.gift_recipient"
                  v-bind:class="['yellowInput']"
                ></form-input>
            </div>

            <div class="col-lg-12 form-group" v-if="purchaseForm.data.type == 'physical'">
                <h4>Address details</h4>
            </div>

            <div class="col-lg-4 col-6 form-group" v-if="purchaseForm.data.type == 'physical'">
                <form-input
                  name="purchase-address1"
                  label="Address 1"
                  v-model="purchaseForm.data.address"
                  v-bind:validation="purchaseForm.validation.address"
                  v-bind:messages="purchaseForm.error_messages.address"
                  v-bind:class="['yellowInput']"
                ></form-input>
            </div>
            <div class="col-lg-4 col-6 form-group" v-if="purchaseForm.data.type == 'physical'">
                <form-input
                  name="purchase-address2"
                  v-bind:label="'Address 2'"
                  v-model="purchaseForm.data.address2"
                  v-bind:validation="purchaseForm.validation.address2"
                  v-bind:messages="purchaseForm.error_messages.address2"
                  v-bind:class="['yellowInput']"
                ></form-input>
            </div>
            <div class="col-lg-4 col-6 form-group" v-if="purchaseForm.data.type == 'physical'">
                <form-input
                  name="purchase-city"
                  v-bind:label="'City'"
                  v-model="purchaseForm.data.city"
                  v-bind:validation="purchaseForm.validation.city"
                  v-bind:messages="purchaseForm.error_messages.city"
                  v-bind:class="['yellowInput']"
                ></form-input>
            </div>

            <div class="col-lg-4 col-6 form-group" v-if="purchaseForm.data.type == 'physical'">
                <form-input
                  name="purchase-county"
                  label="County/State"
                  v-model="purchaseForm.data.county"
                  v-bind:validation="purchaseForm.validation.county"
                  v-bind:messages="purchaseForm.error_messages.county"
                  v-bind:class="['yellowInput']"
                ></form-input>
            </div>
            <div class="col-lg-4 col-6 form-group" v-if="purchaseForm.data.type == 'physical'">
                <form-input
                  name="purchase-postcode"
                  v-bind:label="'Postcode/Zipcode'"
                  v-model="purchaseForm.data.postcode"
                  v-bind:validation="purchaseForm.validation.postcode"
                  v-bind:messages="purchaseForm.error_messages.postcode"
                  v-bind:class="['yellowInput']"
                ></form-input>
            </div>
            <div class="col-lg-4 col-6 form-group" v-if="purchaseForm.data.type == 'physical'">
                <form-input
                  name="purchase-country"
                  v-bind:label="'Country'"
                  v-bind:type="'select'"
                  v-model="purchaseForm.data.country"
                  v-bind:validation="purchaseForm.validation.country"
                  v-bind:messages="purchaseForm.error_messages.country"
                  v-bind:options="options"
                  v-bind:class="['yellowInput']"
                ></form-input>
            </div>

            <div class="col-12 seperator"></div>

            <div class="col-md-12 form-group whiteInput" v-if="showCardDetails">
              <h4><span v-if="purchaseForm.data.gift">Your card</span><span v-else>Card</span> details</h4>
              <form-input
                v-if="purchaseForm.data.gift"
                name="purchase-billing-name"
                placeholder="Enter cardholder name"
                v-model="purchaseForm.data.billing_name"
                v-bind:validation="purchaseForm.data.gift ? ['required'] : []"
                v-bind:messages="purchaseForm.error_messages.billing_name"
                v-bind:class="['yellowInput','cardField']"
              ></form-input>
              <div class="my-input" id="card-element"></div>
            </div>

            <div class="col-12 form-group form-footer" v-if="showCardDetails">
              <div class="row">
                <div class="col-12" v-if="purchaseForm.validation.main">
                  <p class="errorMsg"><i class="icon-warning-sign"></i> {{ purchaseForm.validation.main }}</p>
                </div>
                <div class="col-6 col-lg-8 priceInformation">
                    <p>Gift voucher (<span v-if="purchaseForm.data.type == 'digital'">Digital</span><span v-else>Physical Card</span>)</p>
                    <p class="totalPrice" v-if="purchaseForm.data.type == 'digital'">Total Price: <span>{{ currencySign + totalPrice }}</span></p>
                    <p class="totalPrice" v-else>Total Price: <span>{{ currencySign + totalPricePhysical }}</span></p>
                </div>
                <div class="col-6 col-lg-4 submitContainer">
                  <form-submit label="Buy now" v-bind:status="purchaseForm.status" v-bind:class="['whiteSubmit']"></form-submit>
                </div>
              </div>
            </div>

          </form-builder>
          <div class="orderSuccess" v-if="purchaseSuccess">
            <h3>Thank you for your gift purchase!</h3>
            <p v-if="purchaseForm.data.type == 'digital'">We have emailed the digital voucher to your recipient. We hope they enjoy their gift!</p>
            <p v-else>We will shortly dispatch your phyiscal gift card to your recipient. For postage within the UK that will take 2-4 days to reach them! For postage to the US or Canada it will take 5-6 working days</p>
          </div>
            <ul class="supported-payments">
                <li class="icon-payment">
                    <svg viewBox="0 0 38 24" xmlns="http://www.w3.org/2000/svg" width="38" height="24" role="img" aria-labelledby="pi-maestro"><title id="pi-maestro">Maestro</title><path opacity=".07" d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"></path><path fill="#fff" d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"></path><circle fill="#EB001B" cx="15" cy="12" r="7"></circle><circle fill="#00A2E5" cx="23" cy="12" r="7"></circle><path fill="#7375CF" d="M22 12c0-2.4-1.2-4.5-3-5.7-1.8 1.3-3 3.4-3 5.7s1.2 4.5 3 5.7c1.8-1.2 3-3.3 3-5.7z"></path></svg>
                </li>
                <li class="icon-payment">
                    <svg viewBox="0 0 38 24" xmlns="http://www.w3.org/2000/svg" role="img" width="38" height="24" aria-labelledby="pi-master"><title id="pi-master">Mastercard</title><path opacity=".07" d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"></path><path fill="#fff" d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"></path><circle fill="#EB001B" cx="15" cy="12" r="7"></circle><circle fill="#F79E1B" cx="23" cy="12" r="7"></circle><path fill="#FF5F00" d="M22 12c0-2.4-1.2-4.5-3-5.7-1.8 1.3-3 3.4-3 5.7s1.2 4.5 3 5.7c1.8-1.2 3-3.3 3-5.7z"></path></svg>
                </li>
                <!--
                <li class="icon-payment">
                    <svg viewBox="0 0 38 24" xmlns="http://www.w3.org/2000/svg" width="38" height="24" role="img" aria-labelledby="pi-paypal"><title id="pi-paypal">PayPal</title><path opacity=".07" d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"></path><path fill="#fff" d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"></path><path fill="#003087" d="M23.9 8.3c.2-1 0-1.7-.6-2.3-.6-.7-1.7-1-3.1-1h-4.1c-.3 0-.5.2-.6.5L14 15.6c0 .2.1.4.3.4H17l.4-3.4 1.8-2.2 4.7-2.1z"></path><path fill="#3086C8" d="M23.9 8.3l-.2.2c-.5 2.8-2.2 3.8-4.6 3.8H18c-.3 0-.5.2-.6.5l-.6 3.9-.2 1c0 .2.1.4.3.4H19c.3 0 .5-.2.5-.4v-.1l.4-2.4v-.1c0-.2.3-.4.5-.4h.3c2.1 0 3.7-.8 4.1-3.2.2-1 .1-1.8-.4-2.4-.1-.5-.3-.7-.5-.8z"></path><path fill="#012169" d="M23.3 8.1c-.1-.1-.2-.1-.3-.1-.1 0-.2 0-.3-.1-.3-.1-.7-.1-1.1-.1h-3c-.1 0-.2 0-.2.1-.2.1-.3.2-.3.4l-.7 4.4v.1c0-.3.3-.5.6-.5h1.3c2.5 0 4.1-1 4.6-3.8v-.2c-.1-.1-.3-.2-.5-.2h-.1z"></path></svg>
                </li>
                -->
                <li class="icon-payment">
                    <svg viewBox="0 0 38 24" xmlns="http://www.w3.org/2000/svg" role="img" width="38" height="24" aria-labelledby="pi-visa"><title id="pi-visa">Visa</title><path opacity=".07" d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"></path><path fill="#fff" d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"></path><path d="M28.3 10.1H28c-.4 1-.7 1.5-1 3h1.9c-.3-1.5-.3-2.2-.6-3zm2.9 5.9h-1.7c-.1 0-.1 0-.2-.1l-.2-.9-.1-.2h-2.4c-.1 0-.2 0-.2.2l-.3.9c0 .1-.1.1-.1.1h-2.1l.2-.5L27 8.7c0-.5.3-.7.8-.7h1.5c.1 0 .2 0 .2.2l1.4 6.5c.1.4.2.7.2 1.1.1.1.1.1.1.2zm-13.4-.3l.4-1.8c.1 0 .2.1.2.1.7.3 1.4.5 2.1.4.2 0 .5-.1.7-.2.5-.2.5-.7.1-1.1-.2-.2-.5-.3-.8-.5-.4-.2-.8-.4-1.1-.7-1.2-1-.8-2.4-.1-3.1.6-.4.9-.8 1.7-.8 1.2 0 2.5 0 3.1.2h.1c-.1.6-.2 1.1-.4 1.7-.5-.2-1-.4-1.5-.4-.3 0-.6 0-.9.1-.2 0-.3.1-.4.2-.2.2-.2.5 0 .7l.5.4c.4.2.8.4 1.1.6.5.3 1 .8 1.1 1.4.2.9-.1 1.7-.9 2.3-.5.4-.7.6-1.4.6-1.4 0-2.5.1-3.4-.2-.1.2-.1.2-.2.1zm-3.5.3c.1-.7.1-.7.2-1 .5-2.2 1-4.5 1.4-6.7.1-.2.1-.3.3-.3H18c-.2 1.2-.4 2.1-.7 3.2-.3 1.5-.6 3-1 4.5 0 .2-.1.2-.3.2M5 8.2c0-.1.2-.2.3-.2h3.4c.5 0 .9.3 1 .8l.9 4.4c0 .1 0 .1.1.2 0-.1.1-.1.1-.1l2.1-5.1c-.1-.1 0-.2.1-.2h2.1c0 .1 0 .1-.1.2l-3.1 7.3c-.1.2-.1.3-.2.4-.1.1-.3 0-.5 0H9.7c-.1 0-.2 0-.2-.2L7.9 9.5c-.2-.2-.5-.5-.9-.6-.6-.3-1.7-.5-1.9-.5L5 8.2z" fill="#142688"></path></svg>
                </li>
            </ul>
        </div>
        <a href="#" class="cancelForm" v-on:click.stop.prevent="closeForm()"><i class="icon-times"></i> Cancel and close</a>
    </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

import FormBuilder from '../components/FormBuilder.vue'
import FormInput from '../components/FormInput.vue'
import FormSubmit from '../components/FormSubmit.vue'

export default {
    name: 'BuyGift',
    components: {
        FormBuilder,
        FormInput,
        FormSubmit
    },
    props: {
        tale_id: [Number, String],
        currency: String,
        promotion: String
    },
    computed: {
      getAuth () {
        return this.$store.getters.getAuth
      },
      ...mapGetters(['getAuth']),
    },
    data() {
        return {
            authed: null,

            currencySign: "£",
            subtotalPrice: "6.99",
            totalPrice: "6.99",
            subtotalPricePhysical: "6.99",
            totalPricePhysical: "6.99",

            showCardDetails: false,
            nextError: false,

            purchaseForm: {
                data: {
                    tale_id: null,
                    type: null,
                    name: this.$developer ? "Adam Walker" : null,
                    billing_name: this.$developer ? "Adam Walker" : null,
                    email: this.$developer ? "adamwalker297@gmail.com" : null,
                    gift_from: this.$developer ? "John Smith" : null,
                    gift_message: this.$developer ? "Test personalised message" : null,
                    gift_recipient: this.$developer ? "johnsmith@gmail.com" : null,
                    address: this.$developer ? "9 Dobbin Close" : null,
                    address2: null,
                    city: this.$developer ? "Rossendale" : null,
                    county: this.$developer ? "Lancashire" : null,
                    postcode: this.$developer ? "BB4 7TH" : null,
                    country: this.$developer ? "UK" : null,
                    subtotal: "14.99",
                    discount: null,
                    discount_id: 0,
                    total: "9.99",
                    currency: "GBP",
                    cardholder: null,
                    stripe_intent: {}
                },
                validation: {
                    gift_from: ['required'],
                    gift_recipient: ['required','email'],
                    address: ['required'],
                    address2: [],
                    city: ['required'],
                    county: ['required'],
                    postcode: ['required'],
                    country: ['required'],
                },
                error_messages: {},
                status: null
            },
            purchaseSuccess: false,

            showDiscount: false,
            discountSubmitting: false,
            discountResponse: null,
            discountResponseSuccess: false,

            stripe: null,
            elements: null,
            cardElement: null,
            options: [
                { value: "UK", label: "United Kingdom" },
                { value: "US", label: "United States" },
            ]
        }
    },
    methods: {
        setCurrency() {
            if (this.currency == 'USD') {
                this.currencySign = "$"
                this.subtotalPrice = "9.79"
                this.totalPrice = "9.79"
                this.subtotalPricePhysical = "9.79"
                this.totalPricePhysical = "9.79"
                this.purchaseForm.data.subscribe = true
                this.purchaseForm.data.currency = "USD"
                this.purchaseForm.data.subtotal = this.subtotalPrice
                this.purchaseForm.data.total = this.totalPrice
            }
        },
        checkApplyDiscount() {
            this.discountResponse = null

            if (!this.discountSubmitting && this.purchaseForm.data.discount) {
              this.discountSubmitting = true

              axios
                .post('https://api.cursed-tales.com/discounts/gift_checker', {
                  currency: this.purchaseForm.data.currency,
                  code: this.purchaseForm.data.discount
                })
                .then(response => {
                    console.log("response", response)
                    this.pushMfqVariable("DISCOUNT_USED", this.purchaseForm.data.discount);
                    this.discountResponseSuccess = true
                    this.discountResponse = "Promotional code successfully added"
                    this.discountSubmitting = false

                    this.purchaseForm.data.discount_id = response.data.discount_id
                    this.subtotalPrice = response.data.price.subtotal
                    this.totalPrice = response.data.price.total
                    this.purchaseForm.data.subtotal = response.data.price.subtotal
                    this.purchaseForm.data.total = response.data.price.total

                    setTimeout(() => {
                      this.showDiscount = false
                      this.purchaseForm.data.discount = null
                      this.discountResponse = null
                    }, 2000)
                })
                .catch(error => {
                    console.log(error.response)
                    this.discountResponseSuccess = false
                    this.discountResponse = error.response.data.error.message
                    this.discountSubmitting = false
                })
            }
        },
        buyGift(status) {
          this.purchaseForm.data.gift_message = null;
          this.purchaseForm.data.gift = status;
          if (status)
            this.purchaseForm.validation.password = []
          else
            this.purchaseForm.validation.password = ['required']
        },
        setPurchaseFormError(error) {
          this.purchaseForm.validation.main = error
          this.$forceUpdate();
          setTimeout(() => {
            this.purchaseForm.validation.main = null
            this.$forceUpdate();
          }, 4000);
        },
        purchaseFunction: function () {
          console.log("this.purchaseForm", this.purchaseForm)

          this.purchaseForm.validation.main = null
          this.purchaseForm.status = "loading"
          this.purchaseForm.data.gift_recipient = this.purchaseForm.data.gift_recipient.trim()

          this.stripe.createPaymentMethod('card', this.cardElement, {
              billing_details: { name: this.purchaseForm.data.name }
          }).then(result => {
              if (result.error) {
                  console.log("error", result.error)

                  this.purchaseForm.validation.main = result.error.message
                  this.purchaseForm.status = "error"

              } else {
                  this.purchaseForm.data.stripe_intent = { payment_method_id: result.paymentMethod.id, card_data: result.paymentMethod.card }

                  axios
                    .post('https://api.cursed-tales.com/gift_purchases' + (this.$developer ? '?developer=true' : ''), this.purchaseForm.data)
                    .then(response => {
                        console.log("response.data", response)
                        this.purchaseSuccess = true
                        //this.pushMfqVariable("PURCHASE", "THEMOTHER");
                        //this.$fbq('track', 'Purchase', {currency: this.purchaseForm.data.currency, value: this.purchaseForm.data.total});
                    })
                    .catch(error => {
                      console.log(error.response)

                      if (error.response.data.error && error.response.data.error.type == "intent_action") {
                        this.authorisationResponse = error.response.data.error.action;
                        this.authorisation = true;

                        this.stripe.handleCardAction(this.authorisationResponse.payment_intent_client_secret).then(result => {
                            if (result.error) {
                              this.purchaseForm.error_messages = result.error.message
                              this.purchaseForm.status = "error";

                            } else {
                                this.purchaseForm.data.stripe_intent = { payment_intent_id: result.paymentIntent.id, payment_id: this.authorisationResponse.payment_id || 0 }

                                axios
                                  .post('https://api.cursed-tales.com/gift_purchases', this.purchaseForm.data)
                                  .then(response => {
                                    console.log("response.data", response)
                                    this.purchaseSuccess = true
                                    //this.$fbq('track', 'Purchase', {currency: this.purchaseForm.data.currency, value: this.purchaseForm.data.total});
                                  })
                                  .catch(error => {
                                    console.log(error.response)
                                      this.purchaseForm.error_messages = error.response.data.error.validation_failed
                                      this.purchaseForm.status = "error";
                                  })

                            }
                        });

                      } else {
                        this.purchaseForm.validation.main = error.response.data.error.message
                        if (error.response.data.error.validation_failed)
                          this.purchaseForm.error_messages = error.response.data.error.validation_failed
                        this.purchaseForm.status = "error";

                      }
                    })
              }
          })
        },
        toggleCardDetails(timeout) {
          this.showCardDetails = true
          setTimeout(() => {
            this.stripe  = window.Stripe(this.$pk)
            this.elements = this.stripe.elements();
            this.cardElement = this.elements.create('card', {
              style: {
                base: {
                  iconColor: '#c4f0ff',
                  color: '#333',
                  backgroundColor: '#ddd',
                  fontWeight: '400',
                  fontFamily: 'Arial, sans-serif',
                  fontSize: '18px',
                  fontSmoothing: 'antialiased',
                  ':-webkit-autofill': {
                    color: '#fce883',
                  },
                  '::placeholder': {
                    color: '#aaa',
                  },
                },
                invalid: {
                  iconColor: '#E42C3E',
                  color: '#E42C3E',
                },
              },
            });

            this.cardElement['addEventListener']('change', (event) => {
                this.purchaseForm.validation.main = null
                if (event.error)
                  this.purchaseForm.validation.main = event.error.message;
                this.$forceUpdate();
            });
            this.cardElement.mount('#card-element')
          }, timeout);
        },
        selectType(type) {
            this.purchaseForm.data.type = type
            this.toggleCardDetails(500)

            let validation = ["required"]
            if (type == "digital")
                validation = []

            this.purchaseForm.validation.address = validation
            this.purchaseForm.validation.city = validation
            this.purchaseForm.validation.county = validation
            this.purchaseForm.validation.postcode = validation
            this.purchaseForm.validation.country = validation
        },
        changeRouteTo(route) {
          this.closeForm()
          window.scrollTo(0, 0)
          this.$router.push({ name: route })
        },
        closeForm() {
            this.$emit('close-form', true)
        }
    },
    mounted: function() {
        this.setCurrency()
        /*
        if (this.$defaultPromotion || this.promotion) {
            this.purchaseForm.data.discount = this.$defaultPromotion || this.promotion
            this.checkApplyDiscount()
        }
        */

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.checkout-form {
    padding: 20px;
    background: #711414;
    -moz-box-shadow: 0 0 3px #000;
    -webkit-box-shadow: 0 0 3px #000;
    box-shadow: 0 0 20px #000;
}
.checkout-form h3 {
    font-family: 'Andada Pro', sans-serif;
    color: #fff;
    font-size: 40px;
    padding-bottom: 0px;
    text-align: center;
}
.checkout-form .closeForm {
    position: absolute;
    top: 20px;
    right: 20px;
}
.checkout-form .closeForm i {
    font-size:  40px;
    color: #fff;
    cursor: pointer;
}
.checkout-form .closeForm i:hover {
    color: #aaa;
}
.checkout-form .giftOption {
    color: #75de78;
    font-size: 25px;
    margin-left: 4px;
    margin-bottom: 20px;
    text-align: center;
    border-bottom: 2px solid #7e1a1a;
    padding-bottom: 20px;
}
.checkout-form form p.giftHelp {
    margin: 0 0 20px;
    font-size: 14px;
    color: #aaa;
}

.gift-select {
    padding-bottom: 20px;
}

.gift-select a.buy {
    display: inline-block;
    color: #fff;
    padding: 15px;
    border: 3px solid #fff;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 700;
}
.gift-select a.buy:hover {
    color: #ddd;
    border: 3px solid #ddd;
}
.gift-select a.buy.active {
    color: #e5bf08;
    border: 3px solid #e5bf08;
}
.gift-select span, .gift-select p {
    font-family: 'Andada Pro', sans-serif;
    color: #fff;
}
.gift-select span.or {
    padding: 0 20px;
    font-size: 20px;
}
.gift-select p {
    padding-top: 20px;
    color: #ddd;
    margin: 0;
}

.form-group h4 {
  font-family: 'Andada Pro', sans-serif;
  color: #fff;
  font-size: 24px;
  margin: 0 0 20px 5px;
}
.form-group h4 a {
  color: #aaa;
}
.form-group .authorised {
  padding: 0 5px;
}
.form-group .authorised a,
.form-group .authorised p {
  font-family: 'Andada Pro', sans-serif;
  color: #eee;
  font-size: 19px;
}
.form-group .authorised a.login {
  display: block;
  padding-bottom: 20px;
}
.form-group .authorised p a {
  color: #e5bf08;
  text-decoration: underline !important;
}

.seperator {
  min-height: 10px;
  margin-bottom: 20px;
  border-bottom: 2px solid #7e1a1a;
}

.form-group.nextStep {
  text-align: right;
}
.form-group.nextStep a,
a.toAccount {
    color: #fff;
    padding: 10px;
    border: 3px solid #fff;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 700;
}
.form-group.nextStep a:hover,
a.toAccount:hover {
    color: #aaa;
    border: 3px solid #aaa;
}
a.toAccount {
    margin: 0;
    display: inline-block;
}

.forgotPassword {
  text-align: right;
}
.forgotPassword a {
  color: #fff !important;
}

.form-group.checkBoxWrapper .checkBox {
  display: inline-block;
  float: left;
  width: 25px;
  height: 25px;
  border: 2px solid #fff;
  border-radius: 2px;
  margin: 10px 10px 0 0;
  cursor: pointer;
}
.form-group.checkBoxWrapper .checkBox.activated {
  border: 2px solid #2dab3d;
}
.form-group.checkBoxWrapper .checkBox i {
  font-size: 20px;
  line-height: 22px;
  margin-left: 1px;
  color: #2dab3d;
}
.form-group.checkBoxWrapper label {
  display: block;
  margin-top: 10px;
}

.promotionalWrapper {
  padding-top: 10px;
}
.promotionalWrapper label {
  color: #e5bf08;
  width: 100%;
  text-align: left;
  cursor: pointer;
}
.promotionalWrapper .promotionalField input {
  float: left;
  width: 200px;
  font-family: 'Arial', sans-serif;
  color: #333;
  background: #ddd;
  padding: 6px;
  border: 3px solid #000;
  border-radius: 5px;
  margin-bottom: 10px;
}
.promotionalWrapper .promotionalField input::placeholder {
  color: #222;
}
.promotionalWrapper .promotionalField input:active,
.promotionalWrapper .promotionalField input:focus {
  border-color: #888;
}
.promotionalWrapper .promotionalField .promotionalBtn {
  float: left;
  margin-left: 10px;
  color: #fff;
  padding: 5px;
  border: 3px solid #fff;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 700;
}
.promotionalWrapper .promotionalField .promotionalBtn:hover {
  color: #aaa;
  border: 3px solid #aaa;
}
.promotionalWrapper .promotionalField .discountMessage.messageSuccess {
  margin: 5px 0;
  color: #279f0a
}
.promotionalWrapper .promotionalField .discountMessage.messageError {
  margin: 5px 0;
  color: #000
}
.spamWarning {
  color: #ccc !important;
  font-size: 18px;
}
.form-footer .errorMsg {
  text-align: right;
  margin: 0;
}
.form-footer .priceInformation {
  border-top: 2px solid #cc2626;
  margin-top: 25px;
  padding-top: 10px;
}
.form-footer .priceInformation p {
    color: #fff;
    font-size: 25px;
    margin-bottom: 0;
}
.form-footer .priceInformation p.totalPrice {
    color: #ccc;
    font-size: 20px;
}
.form-footer .priceInformation span {
  color: #fff;
  font-weight: 900;
}
.form-footer .priceInformation span.discounted {
  text-decoration: line-through;
  color: #000;
}
.supported-payments {
    padding-top: 20px;
    text-align: center;
    margin-bottom: 10px;
}
.supported-payments li {
    padding: 0 2px;
}
.cancelForm {
    position: relative;
    display: block;
    width: 100%;
    padding-top: 10px;
    font-size: 20px;
    color: #fff;
    text-align: center;
}

@media (max-width:992px) {
  .checkout-form {
    width: 100%;
  }
}
@media (max-width: 576px) {
    .gift-select a.buy {
        display: block;
        text-align: center;
        margin: 0 30px;
    }
    .gift-select span.or {
        display: block;
        text-align: center;
        padding: 10px 0;
    }
    .gift-select p {
        text-align: center;
    }
}
@media (max-width: 400px) {
  .checkout-form .col-6 {
    width: 100%;
  }
}
</style>
