<template>
  <div id="content-wrapper">
    <page-title ptitle="My Account"></page-title>

    <section id="content">

            <div class="sketch-bottom"></div>

            <div class="content-wrap page-content">

                <div class="container clearfix">

                    <div class="row gutter-40 col-mb-80">

                        <account-menu></account-menu>

                        <div class="profileContent col-lg-12">
                           <form-builder v-bind:submitMethod="updateFunction" v-bind:formData="upateForm" v-on:form-error="setUpateFormError">
                                <div class="col-4 form-group">
                                    <form-input name="regiser-name" label="Fullname:" v-model="upateForm.data.name" v-bind:validation="upateForm.validation.name" v-bind:messages="upateForm.error_messages.name"></form-input>
                                </div>

                                <div class="col-4 form-group">
                                    <form-input name="regiser-address" label="Address 1:" v-model="upateForm.data.address" v-bind:validation="upateForm.validation.address" v-bind:messages="upateForm.error_messages.address"></form-input>
                                </div>

                                <div class="col-4 form-group">
                                    <form-input name="regiser-address2" label="Address 2:" v-model="upateForm.data.address2" v-bind:validation="upateForm.validation.address2" v-bind:messages="upateForm.error_messages.address2"></form-input>
                                </div>

                                <div class="col-4 form-group">
                                    <form-input name="regiser-city" label="City:" v-model="upateForm.data.city" v-bind:validation="upateForm.validation.city" v-bind:messages="upateForm.error_messages.city"></form-input>
                                </div>

                                <div class="col-4 form-group">
                                    <form-input name="regiser-county" label="County:" v-model="upateForm.data.county" v-bind:validation="upateForm.validation.county" v-bind:messages="upateForm.error_messages.county"></form-input>
                                </div>

                                <div class="col-4 form-group">
                                    <form-input name="regiser-postcode" label="Postcode:" v-model="upateForm.data.postcode" v-bind:validation="upateForm.validation.postcode" v-bind:messages="upateForm.error_messages.postcode"></form-input>
                                </div>

                                <div class="col-12 form-group form-footer">
                                  <div class="row">
                                    <div class="col-12" v-if="upateForm.validation.main">
                                      <p class="errorMsg"><i class="icon-warning-sign"></i> {{ upateForm.validation.main }}</p>
                                    </div>
                                    <div class="col-8">
                                      <span >&nbsp;</span>
                                    </div>
                                    <div class="col-4">
                                      <form-submit label="Update" v-bind:status="upateForm.status"></form-submit>
                                    </div>
                                  </div>
                                </div>
                            </form-builder>

                        </div>

                    </div>

                </div>

            </div>

    </section>

  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

import PageTitle from '../components/PageTitle.vue'
import AccountMenu from '../components/AccountMenu.vue'
import FormBuilder from '../components/FormBuilder.vue'
import FormInput from '../components/FormInput.vue'
import FormSubmit from '../components/FormSubmit.vue'

export default {
  name: 'AccountProfileContainer',
  components: {
    PageTitle,
    AccountMenu,
    FormBuilder,
    FormInput,
    FormSubmit
  },
  data () {
    return {
      loaded: false,
      authed: null,

      upateForm: {
        data: {
          name: null,
          address: null,
          address2: null,
          city: null,
          county: null,
          postcode: null,
        },
        validation: {
          name: ['required'],
          address: ['required'],
          address2: [],
          city: ['required'],
          county: ['required'],
          postcode: ['required'],
          main: null
        },
        error_messages: {},
        status: null
      }
    }
  },
  props: {
  },
  computed: {
    getAuth () {
      return this.$store.getters.getAuth
    },
    ...mapGetters(['getAuth']),
  },
  methods: {
    clearAuth() {
      sessionStorage.removeItem('Cursed_CustomerReference')
      this.$cookies.remove('Cursed_CustomerReference')
      this.$store.dispatch("setAuth", null)
    },

    setUpateFormError(error) {
      this.upateForm.validation.main = error
      setTimeout(() => {
        this.upateForm.validation.main = null
      }, 4000);
    },
    updateFunction: function () {
      this.upateForm.status = "loading";

      axios
        .put('https://api.cursed-tales.com/customers/' + this.authed.id, this.upateForm.data)
        .then(response => {
          this.$store.dispatch("setAuth", response.data)
          this.upateForm.status = "success";
          setTimeout(() => {
            this.upateForm.status = null;
          }, 3000);
        })
        .catch(error => {
          this.upateForm.error_messages = error.response.data.error.validation_failed
          this.upateForm.status = "error";
          //this.upateForm.validation.main = "Incorrect username and/or password"
        })
    }
  },
  mounted: function() {
    this.authed = this.getAuth
    console.log("authed", this.authed);

    if (!this.authed) {
      let customerReference = sessionStorage.getItem('Cursed_CustomerReference')
      if (!customerReference && this.$cookies.isKey('Cursed_CustomerReference'))
        customerReference = this.$cookies.get('Cursed_CustomerReference')

      if (customerReference) {
        axios
          .get('https://api.cursed-tales.com/customers/reference/' + customerReference)
          .then(response => {
            console.log("response", response)
            this.authed = response.data;
            this.$store.dispatch("setAuth", response.data)
          })
          .catch(error => {
            console.log(error)
            this.clearAuth()
            this.$router.push({ name: 'login' })
          })
      } else {
        this.clearAuth()
        this.$router.push({ name: 'login' })
      }

    } else {
      this.loaded = true;

      this.upateForm.data.name = this.authed.name
      this.upateForm.data.address = this.authed.address
      this.upateForm.data.address2 = this.authed.address2
      this.upateForm.data.city = this.authed.city
      this.upateForm.data.county = this.authed.county
      this.upateForm.data.postcode = this.authed.postcode

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .profileContent {
      background: #fff;
      margin: 0 12px;
      padding-top: 20px;
      padding-bottom: 50px;
      border-top-right-radius: 5px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
  }

  .profileContent h4 {
      color: #fff;
      font-size: 30px;
  }

  .profileContent .table {
      color: #d6d6d6;
      vertical-align: middle;
  }

  .profileContent .table tr {
      border-top: 2px solid #333;
      border-bottom: 2px solid #333;
  }
  .profileContent .table td {
      padding: 10px 0;
  }
  .profileContent .table tr:first-child {
      border-top: none;
  }

  .profileContent .table th {
      font-size: 18px;
      color: #f1f1f1;
  }
  .profileContent .table th.thCenter {
      text-align: center;
  }

  .profileContent .table .taleCell img {
      width: 50px;
      margin-right: 10px;
  }

  .profileContent .table .taleCell span {
      font-size: 16px;
  }

  .profileContent .table .completedStatus {
      font-size: 30px;
  }

  .profileContent .table .completedStatus.completedSuccess {
      color: #3b8018;
  }
  .profileContent .table .completedStatus.completedPending {
      color: #0b5e7d;
  }

  .profileContent .table .playTale {
      background: #762323;
      color: #fff;
      font-family: Violence;
      letter-spacing: 3px;
      font-weight: 100;
      font-size: 20px;
      padding: 10px;
      border-radius: 5px;
  }

  .form-footer .col-4,
  .form-footer .col-12 {
    text-align: right;
  }
  .form-footer .col-12 p {
    margin-bottom: 0;
  }
</style>
