<template>
  <div id="content-wrapper">
    <page-title ptitle="" short="true"></page-title>

    <section id="content">
        <div class="sketch-bottom"></div>
        <div class="content-wrap page-content">

            <div class="container solution-container clearfix">
                <div class="logo"></div>
                <div class="lock-container" v-if="!showConclusion">
                    <p>Enter the ten digit code below to escape</p>
                    <div class="lock">
                        <div class="buttons button-1" v-on:click="clickNum(1)"></div>
                        <div class="buttons button-2" v-on:click="clickNum(2)"></div>
                        <div class="buttons button-3" v-on:click="clickNum(3)"></div>
                        <div class="buttons button-4" v-on:click="clickNum(4)"></div>
                        <div class="buttons button-5" v-on:click="clickNum(5)"></div>
                        <div class="buttons button-6" v-on:click="clickNum(6)"></div>
                        <div class="buttons button-7" v-on:click="clickNum(7)"></div>
                        <div class="buttons button-8" v-on:click="clickNum(8)"></div>
                        <div class="buttons button-9" v-on:click="clickNum(9)"></div>
                        <div class="buttons button-0" v-on:click="clickNum(0)"></div>

                        <div class="code">
                            <p>Entered code:</p>
                            <div class="numbers" v-bind:class="numbersClass">{{ code }}</div>
                            <a href="#" v-on:click.stop.prevent="unlock()" v-if="!success">Unlock</a>
                            <a href="#" class="reset" v-on:click.stop.prevent="resetNum()" v-if="!success">Reset</a>
                        </div>
                    </div>
                </div>
                <div class="success-container" v-if="showConclusion">
                    <p>You unlock the caravan to find the missing movie star Miss Beatrice Bardell inside tied to a chair. You untie her and through her sobs she confirms your suspicions that her body double Shona Pickford had forcibly taken her from the train on the day she went missing, drugged her and when she woke up she was tied up in the caravan. Shona had regularly visited with food and to tell her how well she was doing on set. Shona had become fixated with being more than just a body double but becoming a real actress and it had driven her to the point of madness. She had purchased this caravan two weeks before and planned the kidnap making it look like she had vanished into thin air and graciously stepping in to help complete the film.</p>
                    <p>You have successfully solved puzzles to correctly locate and rescue the missing movie star.</p>

                    <div class="congrats">
                        <p>Congratulations!</p>
                    </div>
                </div>
            </div>

        </div>
    </section>

  </div>
</template>

<script>
import PageTitle from '../components/PageTitle.vue'

export default {
    name: 'PrintableSolutionFiveContainer',
    components: {
        PageTitle,
    },
    props: {
    },
    data () {
        return {
            success: false,
            showConclusion: false,
            code: "",
            solution: "8563519165",
            numbersClass: "",
        }
    },
    watch: {
    },
    methods: {
        clickNum: function(num) {
            if (this.code.length < 10)
                this.code = this.code + num;
        },
        resetNum: function() {
            this.code = "";
        },
        unlock: function() {
            this.success = false;
            if (this.code == this.solution) {
                this.code = "CORRECT";
                this.success = true;
                this.numbersClass = "correct";
                setTimeout(() => {
                    this.showConclusion = true;
                }, 2000);

            } else {
                this.code = "INCORRECT";
                this.numbersClass = "incorrect";
                setTimeout(() => {
                    this.code = "";
                    this.numbersClass = "";
                }, 2000);

            }
        },
    },
    mounted: function() {
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .solution-container .logo {
        position: relative;
        width: 520px;
        height: 277px;
        background-repeat: no-repeat !important;
        background-size: contain !important;
        background: url('https://cursed-tales.com/assets-sales/img/printables/the-missing-moviestar-logo.png');
        margin: 20px auto 20px;
    }
   .solution-container .lock-container {
        text-align: center;
    }
    .solution-container .lock-container p {
        font-family: 'Andada Pro', sans-serif;
        color: #fff;
        font-size: 32px;
        font-weight: 400;
    }
    .solution-container .lock-container .lock {
        position: relative;
        width: 300px;
        height: 493px;
        background-repeat: no-repeat !important;
        background-size: contain !important;
        background: url('https://cursed-tales.com/assets-sales/img/printables/escape-the-date-lock.png');
        margin: 20px auto 20px;
    }
    .solution-container .lock-container .lock .buttons {
        position: absolute;
        height: 33px;
        width: 28px;
        cursor: pointer;
    }
    .solution-container .lock-container .lock .buttons.button-1 {
        top: 78px;
        left: 31px;
    }
    .solution-container .lock-container .lock .buttons.button-2 {
        top: 78px;
        left: 69px;
    }
    .solution-container .lock-container .lock .buttons.button-3 {
        top: 78px;
        left: 109px;
    }
    .solution-container .lock-container .lock .buttons.button-4 {
        top: 116px;
        left: 31px;
    }
    .solution-container .lock-container .lock .buttons.button-5 {
        top: 116px;
        left: 69px;
    }
    .solution-container .lock-container .lock .buttons.button-6 {
        top: 116px;
        left: 109px;
    }
    .solution-container .lock-container .lock .buttons.button-7 {
        top: 156px;
        left: 31px;
    }
    .solution-container .lock-container .lock .buttons.button-8 {
        top: 156px;
        left: 69px;
    }
    .solution-container .lock-container .lock .buttons.button-9 {
        top: 156px;
        left: 109px;
    }
    .solution-container .lock-container .lock .buttons.button-0 {
        top: 196px;
        left: 70px
    }
    .solution-container .lock-container .lock .code {
        position: absolute;
        width: 200px;
        top: 103px;
        left: 203px;
    }
    .solution-container .lock-container .lock .code p {
        font-size: 22px;
        margin-bottom: 10px;
    }
    .solution-container .lock-container .lock .code .numbers {
        position: relative;
        height: 44px;
        width: 200px;
        border-radius: 20px;
        border: 3px solid #fff;
        font-family: 'Andada Pro', sans-serif;
        font-size: 20px;
        letter-spacing: 2px;
        color: #ccc;
        margin-bottom: 20px;
        padding-top: 3px;
    }
    .solution-container .lock-container .lock .code .numbers.correct {
        background: #24851e;
    }
    .solution-container .lock-container .lock .code .numbers.incorrect {
        background: #ad1d1d;
    }
    .solution-container .lock-container .lock .code a {
        font-size: 20px;
        color: #fff;
        display: block;
        margin: 15px 0;
        background: #24851e;
        margin: 0 20px 10px;
        border-radius: 5px;
        border: 2px solid #fff;
    }
    .solution-container .lock-container .lock .code a.reset {
        background: #ad1d1d;
    }
    .solution-container .success-container {
        margin: 0 auto;
        width: 800px;
        font-family: 'Andada Pro', sans-serif;
        color: #fff;
        font-size: 30px;
        font-weight: 400;
    }
    .solution-container .success-container .congrats {
        font-size: 60px;
        color: #dd8c36;
        text-align: center;
    }
    .solution-container .success-container .congrats p {
        margin-bottom: 5px;
        line-height: 1;
    }
    .solution-container .success-container .congrats span {
        color: #fff;
    }

    @media (max-width: 800px) {
        .solution-container .success-container {
            width: 100%;
            text-align: center;
            font-size: 26px;
        }
    }

    @media (max-width: 557px) {
        .solution-container .lock-container .lock {
            position: relative;
            width: 200px;
            height: 357px;
            background-repeat: no-repeat !important;
            background-size: contain !important;
            background: url('https://cursed-tales.com/assets-sales/img/printables/escape-the-date-lock-alt.png');
            margin: 20px auto 20px;
        }
        .solution-container .lock-container {
            min-height: 900px;
        }
        .solution-container .lock-container .lock .code {
            position: absolute;
            width: 200px;
            top: 366px;
            left: 0;
        }
        .solution-container .lock-container .lock .buttons {
            height: 37px;
            width: 33px;
        }
        .solution-container .lock-container .lock .buttons.button-1 {
            top: 92px;
            left: 36px;
        }
        .solution-container .lock-container .lock .buttons.button-2 {
            top: 92px;
            left: 82px;
        }
        .solution-container .lock-container .lock .buttons.button-3 {
            top: 92px;
            left: 129px;
        }
        .solution-container .lock-container .lock .buttons.button-4 {
            top: 141px;
            left: 36px;
        }
        .solution-container .lock-container .lock .buttons.button-5 {
            top: 141px;
            left: 82px;
        }
        .solution-container .lock-container .lock .buttons.button-6 {
            top: 141px;
            left: 129px;
        }
        .solution-container .lock-container .lock .buttons.button-7 {
            top: 189px;
            left: 36px;
        }
        .solution-container .lock-container .lock .buttons.button-8 {
            top: 189px;
            left: 82px;
        }
        .solution-container .lock-container .lock .buttons.button-9 {
            top: 189px;
            left: 129px;
        }
        .solution-container .lock-container .lock .buttons.button-0 {
            top: 238px;
            left: 83px;
        }
        .solution-container .success-container .congrats {
            font-size: 50px;
        }
        .solution-container .success-container .congrats span {
            font-size: 40px;
        }
    }

    @media (max-width: 538px) {
        .solution-container .logo {
            width: 400px;
            height: 169px;
        }
    }

    @media (max-width: 403px) {
        .solution-container .logo {
            width: 300px;
            height: 127px;
        }
    }

</style>
