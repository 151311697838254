<template>
  <div id="content-wrapper">
    <page-title ptitle="My Account"></page-title>

    <section id="content">
        <div class="sketch-bottom"></div>
        <div class="content-wrap page-content">

            <div class="container reset-container clearfix">

                <div class="accordion accordion-lg mx-auto mb-0 clearfix">

                    <div class="accordion-header">
                        <div class="accordion-title" v-if="!showLoader">
                            <span class="horrorFont blackFont crimsonShadow">Set a new password</span>
                        </div>
                    </div>
                    <div class="accordion-content clearfix">

                        <div class="loader" v-if="showLoader"></div>

                        <form-builder
                          v-if="!newSuccess && !showLoader"
                          v-bind:submitMethod="submitFunction"
                          v-bind:formData="form"
                          v-on:form-error="setFormError"
                        >
                            <div class="col-12 form-group">
                                <form-input name="login-password" label="New Password:" v-bind:type="'password'" v-model="form.data.password" v-bind:validation="form.validation.password" v-bind:messages="form.error_messages.email" v-bind:class="['whiteInput']"></form-input>
                            </div>
                            <div class="col-12 forgotPassword">
                                <router-link :to="{ name: 'login'}"><i class="icon-chevron-left1"></i> Return to login page</router-link>
                            </div>

                            <div class="col-12 form-group form-footer">
                              <div class="row">
                                <div class="col-12" v-if="form.validation.main">
                                  <p class="errorMsg"><i class="icon-warning-sign"></i> {{ form.validation.main }}</p>
                                </div>
                                <div class="col-12">
                                  <form-submit label="Set new password" v-bind:status="form.status" v-bind:class="['whiteSubmit']"></form-submit>
                                </div>
                              </div>
                            </div>
                        </form-builder>
                        <div class="newSuccess" v-if="newSuccess && !showLoader">
                          <h3>Thank you</h3>
                          <p>Your new password has now been set, please <strong><router-link :to="{ name: 'login'}">click here to login</router-link></strong></p>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    </section>

  </div>
</template>

<script>
import axios from 'axios'
import PageTitle from '../components/PageTitle.vue'
import FormBuilder from '../components/FormBuilder.vue'
import FormInput from '../components/FormInput.vue'
import FormSubmit from '../components/FormSubmit.vue'

export default {
  name: 'NewPasswordContainer',
  components: {
    PageTitle,
    FormBuilder,
    FormInput,
    FormSubmit
  },
  props: {
  },
  data () {
    return {
      showLoader: false,
      resetToken: null,
      form: {
        data: {
          password: null,
          token: null
        },
        validation: {
          password: ['required','password'],
          main: null
        },
        error_messages: {},
        status: null
      },
      newSuccess: false
    }
  },
  watch: {

  },
  methods: {
    setFormError(error) {
      this.form.validation.main = error
      setTimeout(() => {
        this.form.validation.main = null
      }, 4000);
    },
    submitFunction: function () {
      this.form.status = "loading";

      axios
        .post('https://api.cursed-tales.com/password_update', this.form.data)
        .then(response => {
          console.log("response", response)
          this.form.status = "loading";
          this.newSuccess = true
        })
        .catch(error => {
          console.log(error)
          this.form.status = "error";
          this.form.validation.main = "There was an error while processing this request"
        })
    },
  },
  mounted: function() {
    this.showLoader = true
    this.resetToken = this.$route.query.token
    if (this.resetToken) {
      axios
        .post('https://api.cursed-tales.com/reset_check', { token: this.resetToken })
        .then(response => {
          console.log("response", response)
          this.form.data.token = this.resetToken
          this.showLoader = false
          this.newPassword = true
        })
        .catch(error => {
          console.log(error)
          this.showLoader = false
        })
    } else {
      this.$router.push({ name: 'login' })
    }
  }


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .loader {
    position: relative;
    width: 100%;
    height: 100px;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: 40px 40px !important;
    background: url('https://cursed-tales.com/assets-sales/img/loading.gif');
  }

  .accordion { margin-bottom: 20px; }
  .accordion-header {
      padding: 0.75rem 0;
      border-top: 1px dotted #DDD;
      cursor: pointer;
  }
  .accordion-header .accordion-title {
    font-size: 60px;
    cursor: default;
    text-align: center;
  }
  .accordion:not([data-collapsible="true"]) .accordion-header.accordion-active { cursor: auto; }
  .accordion-header:first-child { border-top: none; }
  .accordion-header:not(.accordion-active) .accordion-open,
  .accordion-active .accordion-closed { display: none; }
  .accordion-active .accordion-open { display: inline-block; }
  .accordion-content {
      padding-top: 0;
      padding-bottom: 1.5rem;
  }
  .accordion-content {
    position: relative;
    width: 600px;
    margin: 0 auto;
  }
  .accordion-border {
      border: 1px solid #DDD;
      border-radius: 4px;
  }
  .accordion-border .accordion-header {
      border-color: #CCC;
      padding: 0.75rem;
      margin: 0;
  }
  .accordion-border .accordion-content {
      padding: 0 1.125rem 1.5rem;
  }
  .accordion-lg .accordion-header {
      font-size: 1.25rem;
  }

  .forgotPassword {
    text-align: right;
  }
  .forgotPassword a {
    color: #fff;
    text-align: right;
  }
  .forgotPassword a i {
    margin-right: 5px;
    font-size: 10px;
  }
  .form-footer .col-12 {
    text-align: right;
  }
  .form-footer .errorMsg {
    margin: 0;
  }

  .newSuccess h3 {
    margin-bottom: 10px;
    color: #fff;
  }
  .newSuccess p {
    color: #ccc;
  }
  .newSuccess p a {
    color: #fff;
  }
</style>
