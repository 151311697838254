<template>
  <div id="content-wrapper">
    <page-title ptitle="How Do The Digital Games Work?"></page-title>

    <section id="content">
        <div class="sketch-bottom"></div>
        <div class="content-wrap page-content">

            <div class="container page-container clearfix">
              <div class="row col-mb-50">
                <div class="col-12 what-section">
                  <p>Our games are similar to that of a <u>virtual escape room</u>, but what sets us apart is the way that we use film, audio, storytelling, and interaction to create an immersive virtual adventure. This, coupled with our '<u>Choose your own difficulty</u>' feature lets you tailor your experience to how you want to play.</p>
                </div>
                <div class="col-4 how-content">
                  <img src="https://cursed-tales.com/assets-sales/img/how-it-works1.jpg" alt="Gather your team">
                  <p>Once you have purchased a tale it will be added to your account to play right away. Alternatively, you can come back and play later, there is <u>no time limit to complete the game</u>!</p>
                  <p>Recommended team size is between <u>2 and 8 people</u> however, the game can be <u>enjoyed alone</u>. You can play all together in one location or <u>over Zoom, or Google Meet</u>, using the Screenshare option.</p>
                </div>
                <div class="col-4 how-content">
                  <img src="https://cursed-tales.com/assets-sales/img/how-it-works2.jpg" alt="Choose your difficulty">
                  <p>Each tale can be played on two different difficulties depending on your playstyle and experience.<p>
                  <p><u>Casual</u> - This will involve easier puzzles and a shorter experience, ideal for those who enjoy the social aspect of these kinds of games versus a challenge.</p>
                  <p><u>Standard</u> - This difficulty features the standard level of puzzles intended for the tale and results in a longer experience. Depending on the tale's difficulty rating this can be a challenge.</p>
                </div>
                <div class="col-4 how-content">
                  <img src="https://cursed-tales.com/assets-sales/img/how-it-works3.jpg" alt="Explore the map, find the clues and solve the puzzles">
                  <p>Once in the game, you will need to use the map overview to navigate around, uncover any clues or secrets, find items and ultimately solve the puzzles you come across.</p>
                  <p>The length of the game can vary depending on what difficulty setting you choose, your experience, and what tale you are playing. <u>For example</u>, choosing Casual on 'The Mother' will likely take you around 40 minutes, while Standard difficulty on 'The Forest' can take, on average, 1 hour and 30 minutes.</p>
                </div>
                <div class="col-12 moreLink">
                    <router-link :to="{ name: 'home'}" class="seeAll"><i class="icon-chevron-left1"></i> Back to all games</router-link>
                </div>
              </div>
            </div>

        </div>
    </section>

  </div>
</template>

<script>
import PageTitle from '../components/PageTitle.vue'

export default {
  name: 'PageTwoContainer',
  components: {
    PageTitle,
  },
  props: {
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .page-container h2 {
    font-family: 'Andada Pro', sans-serif !important;
    font-size: 50px;
    font-weight: 900;
    color: #000;
    text-shadow: 1px 1px #cc2626;
    text-align: center;
  }
  .page-container p {
    font-family: 'Andada Pro', sans-serif !important;
    font-size: 20px;
    color: #fff;
  }
  .page-container img {
    margin-bottom: 20px;
  }
</style>
