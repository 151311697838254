<template>
  <div class="video-trailer">
    <div class="closeModal" v-on:click="closeForm()">
        <i class="icon-times"></i>
    </div>
    <video id="trailer" autoplay playsinline poster="https://cursed-tales.com/assets-sales/videos/trailer.jpg"
      v-bind:width="video.width"
      v-bind:style="{ 'margin-top': video.margin + 'px' }"
      ref="trailer"
      @ended="trailerEnd()"
    >
      <source v-bind:src="trailer">
      <img src="https://cursed-tales.com/assets-sales/videos/trailer.jpg" alt="">

    </video>
    <div class="clear"></div>

    <a href="#" class="cancel" v-on:click.stop.prevent="closeForm()"><i class="icon-times"></i> Close trailer</a>
  </div>
</template>

<script>
export default {
  name: 'VideoTrailer',
  props: {
    trailer: String,
  },
  data () {
    return {
      video: {
        width: '100%',
        margin: 0
      },
    }
  },
  methods: {
    setVideoStyle() {

      let width = document.documentElement.clientWidth, height = document.documentElement.clientHeight
      this.video.margin = 0
      if (width < height && width < 992) {
        height = width / 1.77
        this.video.margin = ((document.documentElement.clientHeight - 100) - height) / 2
      }
    },
    trailerStart() {
      window.scrollTo(0, 0)
      this.pushMfqVariable("CTA_TRAILER", "STARTED");
    },
    trailerEnd() {
      this.pushMfqVariable("CTA_TRAILER", "ENDED");
    },
    closeForm() {
        this.$emit('close-form', true)
    }
  },
  mounted: function() {
    this.setVideoStyle()
    window.addEventListener('resize', this.setVideoStyle)
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.setVideoStyle);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.video-trailer {
    height: 100%;
    position: relative;
    top: 0;
    padding-bottom: 100px;
}
.video-trailer .closeModal {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 10;
}
.video-trailer .closeModal i {
    font-size:  40px;
    color: #fff;
    cursor: pointer;
}
.video-trailer .closeModal i:hover {
    color: #aaa;
}
.video-trailer .cancel {
    position: absolute;
    bottom: -100px;
    display: block;
    width: 100%;
    padding-top: 10px;
    font-size: 20px;
    color: #fff;
    text-align: center;
    z-index: 10;
}
@media (max-width: 992px) {
    .video-trailer .cancel {
      bottom: -50px;
    }
}
</style>
