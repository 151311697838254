<template>
  <div id="content-wrapper">
    <page-title ptitle="Giveaway: The Forest eBook"></page-title>

    <section id="content">
        <div class="sketch-bottom"></div>
        <div class="content-wrap page-content">

            <div class="container giveaway-container clearfix">
              <div class="row">
                <div class="col-6 subcribeForm">
                  <h2>Get the <span>FREE</span> backstory to The Forest tale</h2>
                  <p>Find out what happened to the students before the malevolent events of the Forest in this compelling short story.</p>

                  <div class="subscribe">
                    <p class="emailSuccess">Click below to download your free eBook</p>
                    <a href="https://cursed-tales.com/downloads/TheForest-TheStudentsFate.pdf" class="downloadLink" target="_blank">Download The Forest eBook</a>
                  </div>

                </div>
                <div class="col-6 ebookBG"></div>
              </div>
            </div>

        </div>
    </section>

  </div>
</template>

<script>
import axios from 'axios'

import PageTitle from '../components/PageTitle.vue'

export default {
  name: 'GiveawayTwoContainer',
  components: {
    PageTitle,
  },
  props: {
  },
  data () {
    return {
      emailAddress: this.$developer ? "adamwalker297@gmail.com" : null,
      emailValid: true,
      emailSubmitted: false,
      emailSuccess: false,
      emailLoading: false,
    }
  },
  methods: {
    validEmail: function (email) {
        let regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regEx.test(email);
    },
    emailSubmit() {
        this.emailSubmitted = true
        this.emailValid = true
        this.emailLoading = false
        if (!this.validEmail(this.emailAddress))
            this.emailValid = false

        if (this.emailAddress && this.emailValid && !this.emailLoading) {
            this.emailLoading = true
            axios
                .post('https://cursed-tales.com/subscribe', { email: this.emailAddress })
                .then(response => {
                    console.log("response", response)
                    this.emailSuccess = true
                    this.emailLoading = false
                })
                .catch(error => {
                    this.emailSuccess = true
                    this.emailLoading = false
                    console.log(error)
                })
        }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .giveaway-container {
    -webkit-box-shadow: 0 0 10px #000;
    box-shadow: 0 0 20px #000;
    border-radius: 10px;
    background: #480c0c;
  }
  .giveaway-container .row {
    padding: 20px 20px 50px;
  }
  .giveaway-container h2 {
    font-family: 'Andada Pro', sans-serif !important;
    font-size: 50px;
    font-weight: 900;
    color: #fff;
    text-shadow: 3px 3px #000;
    text-align: center;
  }
  .giveaway-container h2 span {
    color: #d1c14f;
  }
  .giveaway-container p {
    font-family: 'Andada Pro', sans-serif !important;
    font-size: 20px;
    color: #fff;
  }
  .giveaway-container img {
    margin-bottom: 20px;
  }
  .giveaway-container .ebookBG {
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: contain !important;
    background: url('https://cursed-tales.com/assets-sales/img/the-forest-ebook.png');
  }

  .giveaway-container .subscribe {
  }
  .giveaway-container .subscribe input {
      font-family: 'Andada Pro', sans-serif;
      width: 100%;
      display: block;
      color: #fff;
      background: rgba(0, 0, 0, 0.2);
      padding: 6px;
      border: 2px solid #333;
      border-radius: 5px;
      margin-bottom: 10px;
      text-align: center;
  }
  .giveaway-container .subscribe input::placeholder {
      color: #aaa;
  }
  .giveaway-container .subscribe input.error {
      border: 2px solid #E42C3E;
  }
  .giveaway-container .subscribe button {
      font-family: 'Andada Pro', sans-serif;
      width: 100%;
      display: block;
      color: #fff;
      padding: 5px 20px;
      border: 3px solid #fff;
      border-radius: 5px;
      background: #711414;
      margin-bottom: 10px;
  }
  .giveaway-container .subscribe .subscribe button:hover {
      color: #aaa;
      border: 3px solid #ccc;
  }
  .giveaway-container .subscribe button i {
      margin-right: 10px;
  }
  .giveaway-container .subscribe p.disclaimer {
      font-size: 14px;
  }
  .giveaway-container .subscribe .emailSuccess,
  .giveaway-container .subscribe .emailError {
      font-family: 'Andada Pro', sans-serif !important;
      margin-top: 10px;
      margin-bottom: 10px
  }
  .giveaway-container .subscribe .emailSuccess {
      margin-top: 50px;
      margin-bottom: 30px;
      font-size: 30px;
      color: #e02a2a;
      text-shadow: 3px 3px #000;
  }
  .giveaway-container .subscribe .emailSuccess strong {
      color: #333;
      font-weight: 900;
      font-size: 40px;
  }
  .giveaway-container .subscribe .emailError {
      font-size: 14px !important;
      color: #E42C3E;
  }
  .giveaway-container .subscribe a.downloadLink {
    display: inline-block;
    color: #fff;
    padding: 20px;
    border: 5px solid #fff;
    border-radius: 5px;
    font-size: 30px;
    font-weight: 700;
  }
  .giveaway-container .subscribe a.downloadLink:hover {
      color: #aaa;
      border: 5px solid #aaa;
  }

  @media (max-width: 767px) {
    .giveaway-container .ebookBG {
      display: none;
    }
    .giveaway-container .subcribeForm {
      width:  100% !important;
    }
  }

</style>
