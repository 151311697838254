<template>
  <div>
    <label v-bind:for="name" v-if="label">{{ label }}</label>
    <input v-bind:type="type" class="form-control" v-bind:class="{ error: error }" v-bind:name="name" v-bind:placeholder="placeholder" v-bind:value="data" v-on:change="inputUpdated" v-if="type != 'textarea' && type != 'select'">
    <textarea class="form-control" v-bind:class="{ error: error }" v-bind:name="name" v-bind:placeholder="placeholder" v-bind:value="data" v-on:change="inputUpdated" v-if="type == 'textarea'"></textarea>
    <select class="form-control" v-bind:class="{ error: error }" v-bind:name="name" v-bind:value="data" v-on:change="inputUpdated" v-if="type == 'select'">
      <option v-bind:value="option.value" v-for="option in options" :key="option.value">{{ option.label }}</option>
    </select>
    <span class="fieldError" v-for="message in errorMessages" :key="message">{{ message }}</span>
    <span class="fieldError" v-if="errors.required">This field is required</span>
    <span class="fieldError" v-if="errors.email">This needs to be a valid email address</span>
    <span class="fieldError" v-if="errors.password">Passwords need to contain a number and be at least 6 characters long</span>
    <a href="#" class="forgotPassword" v-if="incForgotPassword">Forgot Password?</a>
  </div>
</template>

<script>
import formValidation from '../mixins/FormValidation'

export default {
  name: 'FormInput',
  mixins: [formValidation],
  model: {
    prop: 'data',
    event: 'change'
  },
  props: {
    name: String,
    placeholder: String,
    type: {
      type: String,
      default: 'text'
    },
    label: String,
    data: String,
    validation: Array,
    messages: Array,
    options: Array,
    incForgotPassword: Boolean,
  },
  data () {
    return {
      error: false,
      errors: Object,
      errorMessages: Array
    }
  },
  watch: {
    data(val) {
      this.errorMessages = []

      if (this.validation.includes('required'))
        this.errors.required = !val ? true : false

      if (val && this.validation.includes('email'))
        this.errors.email = !this.validEmail(val) ? true : false

      if (val && this.validation.includes('password'))
        this.errors.password = !this.validPassword(val) ? true : false

      this.error = false
      if (this.errors.required || this.errors.email || this.errors.password)
        this.error = true

    },
    messages(val) {
      this.errorMessages = val
      this.error = true
      console.log("error message", val)
    }
  },
  methods: {
    inputUpdated: function(e) {
      this.$emit('change', e.target.value)
    }
  },
  mounted: function() {
    this.errors = {}
    for (let validation of this.validation)
        this.errors[validation] = false
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .form-process {
      display: none;
      z-index: 1;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #000;
      opacity: 0.7;
  }
  .form-process.process-show {
      display: block;
  }

  .sm-form-control {
      display: block;
      width: 100%;
      height: 38px;
      padding: 8px 14px;
      font-size: 15px;
      line-height: 1.42857143;
      color: #555555;
      background-color: #ffffff;
      background-image: none;
      border: 2px solid #DDD;
      border-radius: 0 !important;
      -webkit-transition: border-color ease-in-out .15s;
      -o-transition: border-color ease-in-out .15s;
      transition: border-color ease-in-out .15s;
  }

  .form-control {
      font-family: 'Arial', sans-serif;
      color: #333;
      background: #ddd;
      padding: 6px;
      border: 3px solid #000;
      border-radius: 5px;
      margin-bottom: 10px;
  }

  textarea.form-control {
      min-height: 150px;
  }

  .form-control::placeholder {
      color: #444;
  }
  .form-control:active,
  .form-control:focus {
      border-color: #888;
  }

  .form-footer {
      padding-top: 20px;
      text-align: right;
  }
  .form-footer .btn {
      background: #762323;
      color:  #fff;
      font-family: Violence;
      letter-spacing: 3px;
      font-weight: 100;
  }
  .form-footer .errorMsg {
    color: #b62323;
  }
  .contact-form-overlay {
      position: relative;
      background-color: #FFF;
      z-index: 10;
      border-radius: 4px;
      box-shadow: 0 1px 10px rgba(0,0,0,0.15);
  }
  label {
      display: inline-block;
      font-size: 18px;
      font-family: 'Andada Pro', sans-serif;
      font-weight: 600;
      letter-spacing: 1px;
      margin-bottom: 10px;
      color: #711414;
  }
  label.error {
      margin-top: 5px;
      color: #E42C3E;
      font-weight: 400;
  }
  label.error { display: none !important; }
  .show-error-msg + label.error { display: block !important; }

  input[type=checkbox]:not(:checked) + label.error { display: block !important; }

  .form-control.error,
  .sm-form-control.error { border-color: #E42C3E; }

  .fieldError {
    color: #b62323;
    padding: 0 0 10px;
    display: block;
  }

  .forgotPassword {
    position: absolute;
    top: 39px;
    right: 25px;
  }

  .whiteInput label {
    color: #fff;
    padding-left: 5px;
  }
  .yellowInput label {
    color: #e5bf08;
    padding-left: 5px;
  }
  .cardField input {
    padding-left: 42px;
    font-size: 18px;
  }
  .cardField input::placeholder {
    color: #999;
  }

</style>
