<template>

  <div id="content-wrapper">
    <div class="modal-wrapper" v-if="revealModal">
      <div class="modal-overlay"></div>
      <div class="modal-scroller">
        <div class="modal-content">
          <buy-form
            v-if="revealModalType == 'buy'"
            v-bind:currency="currency"
            v-bind:type="taleType"
            v-bind:tale_id="taleId"
            v-bind:promotion="promotion"
            v-on:close-form="closeForm()"
          ></buy-form>
          <buy-gift
            v-if="revealModalType == 'gift'"
            v-bind:currency="currency"
            v-bind:tale_id="taleId"
            v-bind:promotion="promotion"
            v-on:close-form="closeForm()"
          ></buy-gift>
          <video-trailer
            v-if="revealModalType == 'trailer'"
            v-bind:trailer="revealModalTrailer"
            v-on:close-form="closeForm()"
          ></video-trailer>
        </div>
      </div>
    </div>

    <section id="featured_image" class="featured-element featured_wrapper include-header">
      <div class="featured-inner">

        <div class="featured-container">
          <div class="featured-wrapper">
            <div class="featured-slide">

              <div class="firefly"></div>
              <div class="firefly"></div>
              <div class="firefly"></div>
              <div class="firefly"></div>
              <div class="firefly"></div>
              <div class="firefly"></div>
              <div class="firefly"></div>
              <div class="firefly"></div>
              <div class="firefly"></div>
              <div class="firefly"></div>
              <div class="firefly"></div>
              <div class="firefly"></div>
              <div class="firefly"></div>
              <div class="firefly"></div>
              <div class="firefly"></div>

              <div class="container">
                <div class="featured-caption">
                  <div>
                    <div class="headerContent row">
                        <div class="col-6 headerCTA">
                          <div class="featuredLogo">
                            <img src="https://cursed-tales.com/assets-sales/img/logo2.png" alt="">
                          </div>
                          <p class="d-sm-block">Online escape room adventures. Play at home or online with friends</p>
                          <a href="#digital-games" class="trailer">Discover our games <i class="icon-arrow-down2"></i></a>
                        </div>
                        <div class="col-6 headerLaptop">
                          <img src="https://cursed-tales.com/assets-sales/img/laptop4.png" class="featuredLaptop" alt="">
                          <a href="#digital-games" class="trailer">Discover our games <i class="icon-arrow-down2"></i></a>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="featured-slide-bg home-bg"></div>
            </div>
          </div>
        </div>

      </div>
      <div class="sketch-top"></div>
    </section>

    <section id="content">
        <div class="sketch-bottom"></div>
        <div class="content-wrap">

            <div class="section mb-0 pt-0 product-section">
                <div class="anchor" id="digital-games"></div>
                <div class="container games-section-container">

                    <div class="heading-block center border-bottom-0 bottommargin-sm">
                        <h2 class="nott how-header">Our online escape rooms</h2>
                    </div>

                    <div class="row col-mb-50 online-games-section games-section">
                        <div class="col-12 what-section">
                            <p>Our digital games are played online with friends or family. Each game can be played on two different difficulties depending on your playstyle and experience, casual or standard.</p>
                        </div>

                        <div class="col-4 how-content"
                            v-on:click.stop.prevent="selectTale(1)"
                            v-bind:class="{ 'activeGame': selectedTale == 1 }"
                            v-if="!selectedTale || selectedTale == 1"
                        >
                            <div class="overlay" v-if="!selectedTale">
                                <div class="select-game">
                                    <a href="#"><i class="icon-play"></i> Play Game </a>
                                </div>
                            </div>
                            <div class="game-content">
                                <h2 class="nott product-header mother-header top-header" v-bind:style="{ 'display': selectedTale ? 'none' : 'block' }">The Mother</h2>
                                <a href="#" class="backToAll"
                                    v-if="selectedTale == 1"
                                    v-on:click.stop.prevent="selectedTale=null"
                                >
                                    <i class="icon-chevron-left"></i> Back to all
                                </a>
                                <img src="https://cursed-tales.com/assets-sales/img/the-mother-boxt.jpg" alt="The Mother">
                                <div class="difficulty-rating" v-if="!selectedTale">
                                    <i class="icon-skull"></i>
                                    <i class="icon-skull"></i>
                                    <i class="icon-skull"></i>
                                    <i class="icon-skull skullBlack"></i>
                                    <i class="icon-skull skullBlack"></i>
                                </div>
                                <p v-if="!selectedTale">A broken heart, an unforgivable murder and a curse to be lifted. It is 1890 and the ghost of a mother who took her own life haunts a once thriving mill town.</p>
                                <div class="row media-container" v-if="selectedTale == 1">
                                    <div class="col-6">
                                        <a href="#" v-on:click.stop.prevent="modalTrailer('https://cursed-tales.com/assets-sales/videos/trailer.mp4')">
                                            <img src="https://cursed-tales.com/assets-sales/img/trailer-themother.jpg" alt="The Mother Trailer">
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 how-content"
                            v-on:click.stop.prevent="selectTale(2)"
                            v-bind:class="{ 'activeGame': selectedTale == 2 }"
                            v-if="!selectedTale || selectedTale == 2"
                        >
                            <div class="overlay" v-if="!selectedTale">
                                <div class="select-game">
                                    <a href="#"><i class="icon-play"></i> Play Game </a>
                                </div>
                            </div>
                            <div class="game-content">
                                <h2 class="nott forest-header top-header" v-bind:style="{ 'display': selectedTale ? 'none' : 'block' }"><span>The Forest</span></h2>
                                <a href="#" class="backToAll"
                                    v-if="selectedTale == 2"
                                    v-on:click.stop.prevent="selectedTale=null"
                                >
                                    <i class="icon-chevron-left"></i> Back to all
                                </a>

                                <img src="https://cursed-tales.com/assets-sales/img/the-forest-boxt.jpg" alt="The Forest">
                                <div class="difficulty-rating" v-if="!selectedTale">
                                    <i class="icon-skull"></i>
                                    <i class="icon-skull"></i>
                                    <i class="icon-skull"></i>
                                    <i class="icon-skull"></i>
                                    <i class="icon-skull skullBlack"></i>
                                </div>
                                <p v-if="!selectedTale">An innocent evening of trick or treating takes a sinister turn. Lured away from home by an evil masked spirit you become trapped in 'The Forest'. There seems to be no escape, will you belong to the forest?</p>
                                <div class="row media-container" v-if="selectedTale == 2">
                                    <div class="col-6">
                                        <a href="#" v-on:click.stop.prevent="modalTrailer('https://cursed-tales.com/assets-sales/videos/theforest-trailer.mp4')">
                                            <img src="https://cursed-tales.com/assets-sales/img/trailer-theforest.jpg" alt="The Forest Trailer">
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 how-content"
                            v-on:click.stop.prevent="selectTale(3)"
                            v-bind:class="{ 'activeGame': selectedTale == 3 }"
                            v-if="!selectedTale || selectedTale == 3"
                        >
                            <div class="overlay" v-if="!selectedTale">
                                <div class="select-game">
                                    <a href="#"><i class="icon-play"></i> Play Game </a>
                                </div>
                            </div>

                            <div class="game-content">
                                <h2 class="nott product-header dollshouse-header top-header" v-bind:style="{ 'display': selectedTale ? 'none' : 'block' }">The Dolls House</h2>
                                <a href="#" class="backToAll"
                                    v-if="selectedTale == 3"
                                    v-on:click.stop.prevent="selectedTale=null"
                                >
                                    <i class="icon-chevron-left"></i> Back to all
                                </a>

                                <img src="https://cursed-tales.com/assets-sales/img/the-dollshouse-boxt.jpg" alt="The Dolls House">
                                <div class="difficulty-rating" v-if="!selectedTale">
                                    <i class="icon-skull"></i>
                                    <i class="icon-skull"></i>
                                    <i class="icon-skull"></i>
                                    <i class="icon-skull"></i>
                                    <i class="icon-skull"></i>
                                </div>
                                <p v-if="!selectedTale">"A curse from a witch was upon the door, you touched it, now you're human no more. You have until sunset to puzzle and play, if you can't get out a doll you will stay."</p>
                                <div class="row media-container" v-if="selectedTale == 3">
                                    <div class="col-6">
                                        <a href="#" v-on:click.stop.prevent="modalTrailer('https://cursed-tales.com/assets-sales/videos/thedollshouse-trailer.mp4')">
                                            <img src="https://cursed-tales.com/assets-sales/img/trailer-thedollshouse.jpg" alt="The Dolls House Trailer">
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-8 taleDetails motherDetails pbox-content px-0" v-if="selectedTale == 1">
                            <h2 class="nott product-header mother-header bottom-header">The Mother</h2>
                            <p>A broken heart, an unforgivable murder and a curse to be lifted. It is 1890 and the ghost of a mother who took her own life haunts the once thriving mill town.</p>
                            <div class="featureList">
                                <div class="featureItem">
                                    <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-player2.png" alt="Icon Player"></span>Play the role of inspector</p>
                                </div>
                                <div class="featureItem">
                                    <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-feet2.png" alt="Icon Feet"></span>Journey through the town solving puzzles and unravelling more of the harrowing story</p>
                                </div>
                                <div class="featureItem">
                                    <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-inspect2.png" alt="Icon Inspect"></span>Discover the murderous truth to set The Mother free</p>
                                </div>
                                <div class="featureItem">
                                    <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-warning2.png" alt="Icon Warning"></span>Due to adult themes, we recommend that players are age 16 or over</p>
                                </div>
                                <div class="featureItem">
                                    <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-lock2.png" alt="Icon Lock"></span>Difficulty Options:</p>
                                    <p class="difficultyOptions"><strong>Casual</strong> <i class="icon-skull skullFirst"></i><i class="icon-skull skullBlack"></i><i class="icon-skull skullBlack"></i><i class="icon-skull skullBlack"></i><i class="icon-skull skullBlack"></i><span> &nbsp;&nbsp;|&nbsp;&nbsp; </span><strong>Standard</strong> <i class="icon-skull skullFirst"></i><i class="icon-skull"></i><i class="icon-skull"></i><i class="icon-skull skullBlack"></i><i class="icon-skull skullBlack"></i></p>
                                </div>
                                <div class="clear"></div>
                            </div>

                            <div class="buySection">
                                <div class="clear"></div>
                                <a href="#"
                                   class="buy whiteBg"
                                   v-on:click.stop.prevent="buyTale(1)"
                                >
                                    <i class="icon-play"></i> Buy now for <span v-if="showDiscounted" class="discounted">{{ currencySign + originalPrice }}</span> {{ currencySign + totalPrice }}
                                </a>
                            </div>
                            <a href="#" class="backToAll bottomLink" v-on:click.stop.prevent="selectedTale=null"><i class="icon-chevron-left"></i> Back to all </a>
                        </div>

                        <div class="col-8 taleDetails forestDetails pbox-content px-0" v-if="selectedTale == 2">
                            <h2 class="nott forest-header bottom-header"><span>The Forest</span></h2>
                            <p>An innocent evening of trick or treating takes a sinister turn. Lured away from home by an evil masked spirit you become trapped in 'The Forest'. There seems to be no escape, will you belong to the forest?</p>
                            <div class="featureList">
                                <div class="featureItem">
                                    <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-player2.png" alt="Icon Player"></span>Play the role of a trapped father</p>
                                </div>
                                <div class="featureItem">
                                    <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-feet2.png" alt="Icon Feet"></span>Journey through the Forest solving puzzles in a desperate bid to escape</p>
                                </div>
                                <div class="featureItem">
                                    <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-inspect2.png" alt="Icon Inspect"></span>Discover the secret of the Forest to finally be free</p>
                                </div>
                                <div class="featureItem">
                                    <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-lock2.png" alt="Icon Lock"></span>Difficulty Options:</p>
                                    <p class="difficultyOptions"><strong>Casual</strong> <i class="icon-skull skullFirst"></i><i class="icon-skull"></i><i class="icon-skull skullBlack"></i><i class="icon-skull skullBlack"></i><i class="icon-skull skullBlack"></i><span> &nbsp;&nbsp;|&nbsp;&nbsp; </span><strong>Standard</strong> <i class="icon-skull skullFirst"></i><i class="icon-skull"></i><i class="icon-skull"></i><i class="icon-skull"></i><i class="icon-skull skullBlack"></i></p>
                                </div>
                                <div class="clear"></div>
                            </div>
                            <div class="buySection">
                                <div class="clear"></div>
                                <a href="#" class="buy whiteBg"
                                    v-on:click.stop.prevent="buyTale(2)"
                                ><i class="icon-play"></i> Buy now for <span v-if="showDiscounted" class="discounted">{{ currencySign + originalPrice }}</span> {{ currencySign + totalPrice }}</a>
                            </div>
                            <a href="#" class="backToAll bottomLink" v-on:click.stop.prevent="selectedTale=null"><i class="icon-chevron-left"></i> Back to all </a>
                        </div>

                        <div class="col-8 taleDetails dollshouseDetails pbox-content px-0" v-if="selectedTale == 3">
                            <h2 class="nott product-header dollshouse-header bottom-header">The Dolls House</h2>
                            <p>"A curse from a witch was upon the door, you touched it, now you're human no more. You have until sunset to puzzle and play, if you can't get out a doll you will stay."</p>
                            <div class="featureList">
                                <div class="featureItem">
                                    <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-player2.png" alt="Icon Player"></span>Play the role of a woman cursed by a witch</p>
                                </div>
                                <div class="featureItem">
                                    <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-feet2.png" alt="Icon Feet"></span>Journey through the Dolls House solving puzzles to discover a way out</p>
                                </div>
                                <div class="featureItem">
                                    <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-inspect2.png" alt="Icon Inspect"></span>Uncover the secret of the Dolls House to finally escape</p>
                                </div>
                                <div class="featureItem">
                                    <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-lock2.png" alt="Icon Lock"></span>Difficulty Options:</p>
                                    <p class="difficultyOptions"><strong>Casual</strong> <i class="icon-skull skullFirst"></i><i class="icon-skull"></i><i class="icon-skull"></i><i class="icon-skull skullBlack"></i><i class="icon-skull skullBlack"></i><span> &nbsp;&nbsp;|&nbsp;&nbsp; </span><strong>Standard</strong> <i class="icon-skull skullFirst"></i><i class="icon-skull"></i><i class="icon-skull"></i><i class="icon-skull"></i><i class="icon-skull"></i></p>
                                </div>
                                <div class="clear"></div>
                            </div>
                            <div class="buySection">
                                <div class="clear"></div>
                                <a href="#" class="buy whiteBg"
                                    v-on:click.stop.prevent="buyTale(3)"
                                ><i class="icon-play"></i> Buy now for <span v-if="showDiscounted" class="discounted">{{ currencySign + originalPrice }}</span> {{ currencySign + totalPrice }}</a>
                            </div>
                            <a href="#" class="backToAll bottomLink" v-on:click.stop.prevent="selectedTale=null"><i class="icon-chevron-left"></i> Back to all </a>
                        </div>

                        <div class="col-12 sale-container">
                            <div class="sale-banner" v-on:click.stop.prevent="buyTale(1, 'Collection')">
                                <h3>The Cursed Collection</h3>
                                <h4>3 Games for only {{ currencySign + collectionPrice }}</h4>
                                <p class="first">Includes The Mother, The Forest &amp; The Dollshouse digital games</p>
                                <p>for a limited time get the Wicked Wand (Printable Escape kit) included for FREE</p>
                            </div>
                        </div>

                        <div class="col-12 moreLink" v-if="!selectedTale">
                            <router-link :to="{ name: 'guide_digital_games'}" class="seeAll"><i class="icon-question-sign"></i> Have questions? See how to play</router-link>
                        </div>

                    </div>
                </div>
            </div>

            <div class="section mb-0 pt-0 product-section product2-section">
                <div class="anchor" id="printables"></div>
                <div class="container games-section-container printable-section-container">

                    <div class="heading-block center border-bottom-0 bottommargin-sm">
                        <h2 class="nott how-header">Our printable escape kits</h2>
                    </div>

                    <div class="row col-mb-50 printable-games-section games-section">
                        <div class="col-12 what-section">
                            <p>Our games are so simple and easy to set up, making them the perfect addition to a fun night with family and friends, whatever the occasion. To set up, you simply download the game files, print the documents, and enjoy!</p>
                        </div>

                        <div class="col-4 how-content printable-content"
                            v-on:click.stop.prevent="selectKit(1)"
                            v-bind:class="{ 'activeGame': selectedKit == 1 }"
                            v-if="!selectedKit || selectedKit == 1"
                        >
                            <div class="overlay" v-if="!selectedKit">
                                <div class="select-game printable">
                                    <a href="#"><i class="icon-print"></i> Print Game </a>
                                </div>
                            </div>
                            <div class="game-content">
                                <h2 class="nott product-header printable-header printable1-header top-header" v-bind:style="{ 'display': selectedKit ? 'none' : 'block' }">Escape The Date</h2>
                                <a href="#" class="backToAll"
                                    v-if="selectedKit == 1"
                                    v-on:click.stop.prevent="selectedKit=null"
                                >
                                    <i class="icon-chevron-left"></i> Back to all
                                </a>
                                <img src="https://cursed-tales.com/assets-sales/img/printable1-main.jpg" alt="Escape The Date">
                                <div class="difficulty-rating" v-if="!selectedKit">
                                    <i class="icon-lock"></i>
                                    <i class="icon-lock"></i>
                                    <i class="icon-lock"></i>
                                    <i class="icon-lock"></i>
                                    <i class="icon-lock skullBlack"></i>
                                </div>
                                <p v-if="!selectedKit">You have been single for a long time, and your best friend, desperate to help you find your soulmate, has set you up on a blind date. You meet your date at ‘Romeo’s’ bar, and it is quite clear that you are not a match made in heaven.</p>
                                <div class="row media-container" v-if="selectedKit == 1">
                                    <!--
                                    <div class="col-6">
                                        <a href="#" v-on:click.stop.prevent="modalTrailer('https://cursed-tales.com/assets-sales/videos/trailer.mp4')">
                                            <img src="https://cursed-tales.com/assets-sales/img/trailer-themother.jpg" alt="The Mother Trailer">
                                        </a>
                                    </div>
                                    -->
                                </div>
                            </div>
                        </div>
                        <div class="col-4 how-content printable-content"
                            v-on:click.stop.prevent="selectKit(2)"
                            v-bind:class="{ 'activeGame': selectedKit == 2 }"
                            v-if="!selectedKit || selectedKit == 2"
                        >
                            <div class="overlay" v-if="!selectedKit">
                                <div class="select-game printable">
                                    <a href="#"><i class="icon-print"></i> Print Game </a>
                                </div>
                            </div>
                            <div class="game-content">
                                <h2 class="nott product-header printable-header printable2-header top-header" v-bind:style="{ 'display': selectedKit ? 'none' : 'block' }"><span>Death Marked Love</span></h2>
                                <a href="#" class="backToAll"
                                    v-if="selectedKit == 2"
                                    v-on:click.stop.prevent="selectedKit=null"
                                >
                                    <i class="icon-chevron-left"></i> Back to all
                                </a>

                                <img src="https://cursed-tales.com/assets-sales/img/printable2-main.jpg" alt="Death Marked Love">
                                <div class="difficulty-rating" v-if="!selectedKit">
                                    <i class="icon-lock"></i>
                                    <i class="icon-lock"></i>
                                    <i class="icon-lock"></i>
                                    <i class="icon-lock"></i>
                                    <i class="icon-lock skullBlack"></i>
                                </div>
                                <p v-if="!selectedKit">You’ve heard the tragic tale of Romeo and Juliet…. but what if that doesn’t have to be the ending?  What if there was a way to save Romeo and Juliet from their fate?</p>
                                <div class="row media-container" v-if="selectedKit == 2">
                                    <!--
                                    <div class="col-6">
                                        <a href="#" v-on:click.stop.prevent="modalTrailer('https://cursed-tales.com/assets-sales/videos/theforest-trailer.mp4')">
                                            <img src="https://cursed-tales.com/assets-sales/img/trailer-theforest.jpg" alt="The Forest Trailer">
                                        </a>
                                    </div>
                                    -->
                                </div>
                            </div>
                        </div>
                        <div class="col-4 how-content printable-content"
                            v-on:click.stop.prevent="selectKit(3)"
                            v-bind:class="{ 'activeGame': selectedKit == 3 }"
                            v-if="!selectedKit || selectedKit == 3"
                        >
                            <div class="overlay" v-if="!selectedKit">
                                <div class="select-game printable">
                                    <a href="#"><i class="icon-print"></i> Print Game </a>
                                </div>
                            </div>

                            <div class="game-content">
                                <h2 class="nott product-header printable-header printable3-header top-header" v-bind:style="{ 'display': selectedKit ? 'none' : 'block' }">The Missing Moviestar</h2>
                                <a href="#" class="backToAll"
                                    v-if="selectedKit == 3"
                                    v-on:click.stop.prevent="selectedKit=null"
                                >
                                    <i class="icon-chevron-left"></i> Back to all
                                </a>

                                <img src="https://cursed-tales.com/assets-sales/img/printable3-main.jpg" alt="The Missing Moviestar">
                                <div class="difficulty-rating" v-if="!selectedKit">
                                    <i class="icon-lock"></i>
                                    <i class="icon-lock"></i>
                                    <i class="icon-lock"></i>
                                    <i class="icon-lock"></i>
                                    <i class="icon-lock"></i>
                                </div>
                                <p v-if="!selectedKit">The sleepy town of Hill View has been rocked by the shocking disappearance of their much-loved local celebrity Miss Beatrice Bardell. She was last seen leaving her home in a picturesque part of Hill View, can you uncover what became of her?</p>
                                <div class="row media-container" v-if="selectedKit == 3">
                                    <!--
                                    <div class="col-6">
                                        <a href="#" v-on:click.stop.prevent="modalTrailer('https://cursed-tales.com/assets-sales/videos/thedollshouse-trailer.mp4')">
                                            <img src="https://cursed-tales.com/assets-sales/img/trailer-thedollshouse.jpg" alt="The Dolls House Trailer">
                                        </a>
                                    </div>
                                    -->
                                </div>
                            </div>
                        </div>

                        <div class="col-4 how-content printable-content"
                            v-on:click.stop.prevent="selectKit(4)"
                            v-bind:class="{ 'activeGame': selectedKit == 4 }"
                            v-if="!selectedKit || selectedKit == 4"
                        >
                            <div class="overlay" v-if="!selectedKit">
                                <div class="select-game printable">
                                    <a href="#"><i class="icon-print"></i> Print Game </a>
                                </div>
                            </div>
                            <div class="game-content">
                                <h2 class="nott product-header printable-header printable4-header top-header" v-bind:style="{ 'display': selectedKit ? 'none' : 'block' }">Mystery Manor</h2>
                                <a href="#" class="backToAll"
                                    v-if="selectedKit == 4"
                                    v-on:click.stop.prevent="selectedKit=null"
                                >
                                    <i class="icon-chevron-left"></i> Back to all
                                </a>
                                <img src="https://cursed-tales.com/assets-sales/img/printable4-main.jpg" alt="Mystery Manor">
                                <div class="difficulty-rating" v-if="!selectedKit">
                                    <i class="icon-lock"></i>
                                    <i class="icon-lock"></i>
                                    <i class="icon-lock"></i>
                                    <i class="icon-lock skullBlack"></i>
                                    <i class="icon-lock skullBlack"></i>
                                </div>
                                <p v-if="!selectedKit">Things take an exciting turn when circumstances force to stay at an isolated hotel. Alone with the less-than-helpful concierge, you quickly realise all is not as it seems.</p>
                                <div class="row media-container" v-if="selectedKit == 4">
                                    <!--
                                    <div class="col-6">
                                        <a href="#" v-on:click.stop.prevent="modalTrailer('https://cursed-tales.com/assets-sales/videos/trailer.mp4')">
                                            <img src="https://cursed-tales.com/assets-sales/img/trailer-themother.jpg" alt="The Mother Trailer">
                                        </a>
                                    </div>
                                    -->
                                </div>
                            </div>
                        </div>
                        <div class="col-4 how-content printable-content"
                            v-on:click.stop.prevent="selectKit(5)"
                            v-bind:class="{ 'activeGame': selectedKit == 5 }"
                            v-if="!selectedKit || selectedKit == 5"
                        >
                            <div class="overlay" v-if="!selectedKit">
                                <div class="select-game printable">
                                    <a href="#"><i class="icon-print"></i> Print Game </a>
                                </div>
                            </div>
                            <div class="game-content">
                                <h2 class="nott product-header printable-header printable5-header top-header" v-bind:style="{ 'display': selectedKit ? 'none' : 'block' }"><span>The Eggcelent Adventure</span></h2>
                                <a href="#" class="backToAll"
                                    v-if="selectedKit == 5"
                                    v-on:click.stop.prevent="selectedKit=null"
                                >
                                    <i class="icon-chevron-left"></i> Back to all
                                </a>

                                <img src="https://cursed-tales.com/assets-sales/img/printable5-main.jpg" alt="The Eggcelent Adventure">
                                <div class="difficulty-rating" v-if="!selectedKit">
                                    <i class="icon-lock"></i>
                                    <i class="icon-lock skullBlack"></i>
                                    <i class="icon-lock skullBlack"></i>
                                    <i class="icon-lock skullBlack"></i>
                                    <i class="icon-lock skullBlack"></i>
                                </div>
                                <p v-if="!selectedKit">It's Easter's Eve, and you've discovered the Easter Bunny looking for his missing eggs! Can you follow the clues and solve the puzzles to help him find them and save Easter?</p>
                                <div class="row media-container" v-if="selectedKit == 5">
                                    <!--
                                    <div class="col-6">
                                        <a href="#" v-on:click.stop.prevent="modalTrailer('https://cursed-tales.com/assets-sales/videos/theforest-trailer.mp4')">
                                            <img src="https://cursed-tales.com/assets-sales/img/trailer-theforest.jpg" alt="The Forest Trailer">
                                        </a>
                                    </div>
                                    -->
                                </div>
                            </div>
                        </div>
                        <div class="col-4 how-content printable-content"
                            v-on:click.stop.prevent="selectKit(6)"
                            v-bind:class="{ 'activeGame': selectedKit == 6 }"
                            v-if="!selectedKit || selectedKit == 6"
                        >
                            <div class="overlay" v-if="!selectedKit">
                                <div class="select-game printable">
                                    <a href="#"><i class="icon-print"></i> Print Game </a>
                                </div>
                            </div>

                            <div class="game-content">
                                <h2 class="nott product-header printable-header printable6-header top-header" v-bind:style="{ 'display': selectedKit ? 'none' : 'block' }">The Wicked Wand</h2>
                                <a href="#" class="backToAll"
                                    v-if="selectedKit == 6"
                                    v-on:click.stop.prevent="selectedKit=null"
                                >
                                    <i class="icon-chevron-left"></i> Back to all
                                </a>

                                <img src="https://cursed-tales.com/assets-sales/img/printable6-main.jpg" alt="The Wicked Wand">
                                <div class="difficulty-rating" v-if="!selectedKit">
                                    <i class="icon-lock"></i>
                                    <i class="icon-lock skullBlack"></i>
                                    <i class="icon-lock skullBlack"></i>
                                    <i class="icon-lock skullBlack"></i>
                                    <i class="icon-lock skullBlack"></i>
                                </div>
                                <p v-if="!selectedKit">You and your friends are students at 'The Wimsey school for Wizards and Witches'. The Wicked Wand has found its way into the hands of one of your friends, and only you can stop it before it causes more trouble!</p>
                                <div class="row media-container" v-if="selectedKit == 6">
                                    <!--
                                    <div class="col-6">
                                        <a href="#" v-on:click.stop.prevent="modalTrailer('https://cursed-tales.com/assets-sales/videos/thedollshouse-trailer.mp4')">
                                            <img src="https://cursed-tales.com/assets-sales/img/trailer-thedollshouse.jpg" alt="The Dolls House Trailer">
                                        </a>
                                    </div>
                                    -->
                                </div>
                            </div>
                        </div>

                        <div class="col-8 taleDetails motherDetails pbox-content px-0" v-if="selectedKit == 1">
                            <h2 class="nott product-header printable-header printable1-header bottom-header">Escape The Date</h2>

                            <h3><i class="icon-line-book"></i> STORY &amp; GAMEPLAY</h3>
                            <p>You have been single for a long time, and your best friend, desperate to help you find your soulmate, has set you up on a blind date. You meet your date at ‘Romeo’s’ bar, and it is quite clear that you are not a match made in heaven.</p>
                            <div class="featureList">
                                <div class="featureItem">
                                    <p><span><i class="icon-users1 icon-lg"></i></span><strong>Number of players:</strong> Up to 6 players</p>
                                </div>
                                <div class="featureItem">
                                    <p><span><i class="icon-gamepad1 icon-lg"></i></span><strong>Gameplay:</strong> 30-60 minutes</p>
                                </div>
                                <div class="featureItem">
                                    <p><span><i class="icon-clock icon-lg"></i></span><strong>Age:</strong> 14 and up</p>
                                </div>
                                <div class="featureItem">
                                    <p><span><i class="icon-skull icon-lg"></i></span>Difficulty Options:</p>
                                    <p class="difficultyOptions"><i class="icon-lock skullFirst"></i><i class="icon-lock"></i><i class="icon-lock"></i><i class="icon-lock"></i><i class="icon-lock skullBlack"></i></p>
                                </div>
                                <div class="clear"></div>
                            </div>

                            <h3><i class="icon-line-scissors"></i> SET-UP &amp; ADDITIONAL MATERIALS</h3>
                            <p>To set up the game, you will only need paper, a color printer, and a pair of scissors to give yourself the full experience. To check the final solution, you will need internet access to either scan the QR code or enter the web address manually, where you can enter and check your final solution.</p>
                            <p>Perfect for date nights, valentines, party nights, and the perfect excuse for a bit of fun on your night in.</p>

                            <h3><i class="icon-file-pdf"></i> INCLUDED DOCUMENTS</h3>
                            <p>1. A PDF file with the instructions and game materials</p>
                            <p>2. A PDF file with the walkthrough of the game and solutions</p>

                            <div class="buySection">
                                <div class="clear"></div>
                                <a href="#"
                                   class="buy"
                                   v-on:click.stop.prevent="buyTale(1, 'Printable')"
                                >
                                    <i class="icon-play"></i> Print now for <span v-if="showDiscounted" class="discounted">{{ currencySign + originalPrice }}</span> {{ currencySign + totalPrice }}
                                </a>
                            </div>
                        </div>

                        <div class="col-8 taleDetails motherDetails pbox-content px-0" v-if="selectedKit == 2">
                            <h2 class="nott product-header printable-header printable2-header bottom-header"><span>Death Marked Love</span></h2>

                            <h3><i class="icon-line-book"></i> STORY &amp; GAMEPLAY</h3>
                            <p>You’ve heard the tragic tale of Romeo and Juliet…. but what if that doesn’t have to be the ending?  What if there was a way to save Romeo and Juliet from their fate?</p>
                            <p>Can you work out the puzzles and help these Star Crossed Lovers escape Verona to live happily ever after?  Or is this ‘Death Marked Love’ inevitable?</p>
                            <div class="featureList">
                                <div class="featureItem">
                                    <p><span><i class="icon-users1 icon-lg"></i></span><strong>Number of players:</strong> Up to 6 players</p>
                                </div>
                                <div class="featureItem">
                                    <p><span><i class="icon-gamepad1 icon-lg"></i></span><strong>Gameplay:</strong> 30-60 minutes</p>
                                </div>
                                <div class="featureItem">
                                    <p><span><i class="icon-clock icon-lg"></i></span><strong>Age:</strong> 14 and up</p>
                                </div>
                                <div class="featureItem">
                                    <p><span><i class="icon-skull icon-lg"></i></span>Difficulty Options:</p>
                                    <p class="difficultyOptions"><i class="icon-lock skullFirst"></i><i class="icon-lock"></i><i class="icon-lock"></i><i class="icon-lock"></i><i class="icon-lock skullBlack"></i></p>
                                </div>
                                <div class="clear"></div>
                            </div>

                            <h3><i class="icon-line-scissors"></i> SET-UP &amp; ADDITIONAL MATERIALS</h3>
                            <p>To set up the game, you will only need paper, a color printer, and a pair of scissors to give yourself the full experience. To check the final solution, you will need internet access to either scan the QR code or enter the web address manually, where you can enter and check your final solution.</p>
                            <p>Perfect for date nights, valentines, party nights, and the perfect excuse for a bit of fun on your night in.</p>

                            <h3><i class="icon-file-pdf"></i> INCLUDED DOCUMENTS</h3>
                            <p>1. A PDF file with the instructions and game materials</p>
                            <p>2. A PDF file with the walkthrough of the game and solutions</p>

                            <div class="buySection">
                                <div class="clear"></div>
                                <a href="#"
                                   class="buy"
                                   v-on:click.stop.prevent="buyTale(2, 'Printable')"
                                >
                                    <i class="icon-play"></i> Print now for <span v-if="showDiscounted" class="discounted">{{ currencySign + originalPrice }}</span> {{ currencySign + totalPrice }}
                                </a>
                            </div>
                        </div>

                        <div class="col-8 taleDetails motherDetails pbox-content px-0" v-if="selectedKit == 3">
                            <h2 class="nott product-header printable-header printable3-header bottom-header"><span>The Missing Moviestar</span></h2>

                            <h3><i class="icon-line-book"></i> STORY &amp; GAMEPLAY</h3>
                            <p>The sleepy town of Hill View has been rocked by the shocking disappearance of their much-loved local celebrity Miss Beatrice Bardell. She was last seen leaving her home in a picturesque part of Hill View, but she never showed up at the set of her new film ‘Pathway to Peril’ and has now not been seen for 36 hours. You need to find and solve the clues and puzzles to solve the mystery of the missing movie star!</p>
                            <div class="featureList">
                                <div class="featureItem">
                                    <p><span><i class="icon-users1 icon-lg"></i></span><strong>Number of players:</strong> Up to 6 players</p>
                                </div>
                                <div class="featureItem">
                                    <p><span><i class="icon-gamepad1 icon-lg"></i></span><strong>Gameplay:</strong> 30-60 minutes</p>
                                </div>
                                <div class="featureItem">
                                    <p><span><i class="icon-clock icon-lg"></i></span><strong>Age:</strong> 14 and up</p>
                                </div>
                                <div class="featureItem">
                                    <p><span><i class="icon-skull icon-lg"></i></span>Difficulty Options:</p>
                                    <p class="difficultyOptions"><i class="icon-lock skullFirst"></i><i class="icon-lock"></i><i class="icon-lock"></i><i class="icon-lock"></i><i class="icon-lock"></i></p>
                                </div>
                                <div class="clear"></div>
                            </div>

                            <h3><i class="icon-line-scissors"></i> SET-UP &amp; ADDITIONAL MATERIALS</h3>
                            <p>To set up the game, you will only need paper, a color printer, and a pair of scissors to give yourself the full experience. To check the final solution, you will need internet access to either scan the QR code or enter the web address manually, where you can enter and check your final solution.</p>
                            <p>Perfect for date nights, valentines, party nights, and the perfect excuse for a bit of fun on your night in.</p>

                            <h3><i class="icon-file-pdf"></i> INCLUDED DOCUMENTS</h3>
                            <p>1. A PDF file with the instructions and game materials</p>
                            <p>2. A PDF file with the walkthrough of the game and solutions</p>

                            <div class="buySection">
                                <div class="clear"></div>
                                <a href="#"
                                   class="buy"
                                   v-on:click.stop.prevent="buyTale(3, 'Printable')"
                                >
                                    <i class="icon-play"></i> Print now for <span v-if="showDiscounted" class="discounted">{{ currencySign + originalPrice }}</span> {{ currencySign + totalPrice }}
                                </a>
                            </div>
                        </div>

                        <div class="col-8 taleDetails motherDetails pbox-content px-0" v-if="selectedKit == 4">
                            <h2 class="nott product-header printable-header printable4-header bottom-header"><span>Mystery Manor</span></h2>

                            <h3><i class="icon-line-book"></i> STORY &amp; GAMEPLAY</h3>
                            <p>Things take an exciting turn when circumstances force to stay at an isolated hotel. Alone with the less-than-helpful concierge, you quickly realise all is not as it seems. You're going to have to solve your way through the clues to escape! This will certainly be a night to remember!</p>
                            <div class="featureList">
                                <div class="featureItem">
                                    <p><span><i class="icon-users1 icon-lg"></i></span><strong>Number of players:</strong> Up to 6 players</p>
                                </div>
                                <div class="featureItem">
                                    <p><span><i class="icon-gamepad1 icon-lg"></i></span><strong>Gameplay:</strong> 30-60 minutes</p>
                                </div>
                                <div class="featureItem">
                                    <p><span><i class="icon-clock icon-lg"></i></span><strong>Age:</strong> 14 and up</p>
                                </div>
                                <div class="featureItem">
                                    <p><span><i class="icon-skull icon-lg"></i></span>Difficulty Options:</p>
                                    <p class="difficultyOptions"><i class="icon-lock skullFirst"></i><i class="icon-lock"></i><i class="icon-lock"></i><i class="icon-lock skullBlack"></i><i class="icon-lock skullBlack"></i></p>
                                </div>
                                <div class="clear"></div>
                            </div>

                            <h3><i class="icon-line-scissors"></i> SET-UP &amp; ADDITIONAL MATERIALS</h3>
                            <p>To set up the game, you will only need paper, a color printer, and a pair of scissors to give yourself the full experience. To check the final solution, you will need internet access to either scan the QR code or enter the web address manually, where you can enter and check your final solution.</p>
                            <p>Perfect for date nights, valentines, party nights, and the perfect excuse for a bit of fun on your night in.</p>

                            <h3><i class="icon-file-pdf"></i> INCLUDED DOCUMENTS</h3>
                            <p>1. A PDF file with the instructions and game materials</p>
                            <p>2. A PDF file with the walkthrough of the game and solutions</p>

                            <div class="buySection">
                                <div class="clear"></div>
                                <a href="#"
                                   class="buy"
                                   v-on:click.stop.prevent="buyTale(4, 'Printable')"
                                >
                                    <i class="icon-play"></i> Print now for <span v-if="showDiscounted" class="discounted">{{ currencySign + originalPrice }}</span> {{ currencySign + totalPrice }}
                                </a>
                            </div>
                        </div>

                        <div class="col-8 taleDetails motherDetails pbox-content px-0" v-if="selectedKit == 5">
                            <h2 class="nott product-header printable-header printable5-header bottom-header"><span>The Eggcelent Adventure</span></h2>

                            <h3><i class="icon-line-book"></i> STORY &amp; GAMEPLAY</h3>
                            <p>It's Easter's Eve, and you've discovered the Easter Bunny looking for his missing eggs!  Can you follow the clues and solve the puzzles to help him find them and save Easter?</p>
                            <div class="featureList">
                                <div class="featureItem">
                                    <p><span><i class="icon-users1 icon-lg"></i></span><strong>Number of players:</strong> Up to 6 players</p>
                                </div>
                                <div class="featureItem">
                                    <p><span><i class="icon-gamepad1 icon-lg"></i></span><strong>Gameplay:</strong> 30-60 minutes</p>
                                </div>
                                <div class="featureItem">
                                    <p><span><i class="icon-clock icon-lg"></i></span><strong>Age:</strong> 14 and up</p>
                                </div>
                                <div class="featureItem">
                                    <p><span><i class="icon-skull icon-lg"></i></span>Difficulty Options:</p>
                                    <p class="difficultyOptions"><i class="icon-lock skullFirst"></i><i class="icon-lock skullBlack"></i><i class="icon-lock skullBlack"></i><i class="icon-lock skullBlack"></i><i class="icon-lock skullBlack"></i></p>
                                </div>
                                <div class="clear"></div>
                            </div>

                            <h3><i class="icon-line-scissors"></i> SET-UP &amp; ADDITIONAL MATERIALS</h3>
                            <p>To set up the game, you will only need paper, a color printer, and a pair of scissors to give yourself the full experience. To check the final solution, you will need internet access to either scan the QR code or enter the web address manually, where you can enter and check your final solution.</p>
                            <p>Perfect for date nights, valentines, party nights, and the perfect excuse for a bit of fun on your night in.</p>

                            <h3><i class="icon-file-pdf"></i> INCLUDED DOCUMENTS</h3>
                            <p>1. A PDF file with the instructions and game materials</p>
                            <p>2. A PDF file with the walkthrough of the game and solutions</p>

                            <div class="buySection">
                                <div class="clear"></div>
                                <a href="#"
                                   class="buy"
                                   v-on:click.stop.prevent="buyTale(5, 'Printable')"
                                >
                                    <i class="icon-play"></i> Print now for <span v-if="showDiscounted" class="discounted">{{ currencySign + originalPrice }}</span> {{ currencySign + totalPrice }}
                                </a>
                            </div>
                        </div>

                        <div class="col-8 taleDetails motherDetails pbox-content px-0" v-if="selectedKit == 6">
                            <h2 class="nott product-header printable-header printable6-header bottom-header"><span>The Wicked Wand</span></h2>

                            <h3><i class="icon-line-book"></i> STORY &amp; GAMEPLAY</h3>
                            <p>You and your friends are students at 'The Wimsey school for Wizards and Witches'. The Wicked Wand has found its way into the hands of one of your friends, and only you can stop it before it causes more trouble! Can you save your friend and banish The Wicked Wand forever?</p>
                            <div class="featureList">
                                <div class="featureItem">
                                    <p><span><i class="icon-users1 icon-lg"></i></span><strong>Number of players:</strong> Up to 6 players</p>
                                </div>
                                <div class="featureItem">
                                    <p><span><i class="icon-gamepad1 icon-lg"></i></span><strong>Gameplay:</strong> 30-60 minutes</p>
                                </div>
                                <div class="featureItem">
                                    <p><span><i class="icon-clock icon-lg"></i></span><strong>Age:</strong> 14 and up</p>
                                </div>
                                <div class="featureItem">
                                    <p><span><i class="icon-skull icon-lg"></i></span>Difficulty Options:</p>
                                    <p class="difficultyOptions"><i class="icon-lock skullFirst"></i><i class="icon-lock skullBlack"></i><i class="icon-lock skullBlack"></i><i class="icon-lock skullBlack"></i><i class="icon-lock skullBlack"></i></p>
                                </div>
                                <div class="clear"></div>
                            </div>

                            <h3><i class="icon-line-scissors"></i> SET-UP &amp; ADDITIONAL MATERIALS</h3>
                            <p>To set up the game, you will only need paper, a color printer, and a pair of scissors to give yourself the full experience. To check the final solution, you will need internet access to either scan the QR code or enter the web address manually, where you can enter and check your final solution.</p>
                            <p>Perfect for date nights, valentines, party nights, and the perfect excuse for a bit of fun on your night in.</p>

                            <h3><i class="icon-file-pdf"></i> INCLUDED DOCUMENTS</h3>
                            <p>1. A PDF file with the instructions and game materials</p>
                            <p>2. A PDF file with the walkthrough of the game and solutions</p>

                            <div class="buySection">
                                <div class="clear"></div>
                                <a href="#"
                                   class="buy"
                                   v-on:click.stop.prevent="buyTale(6, 'Printable')"
                                >
                                    <i class="icon-play"></i> Print now for <span v-if="showDiscounted" class="discounted">{{ currencySign + originalPrice }}</span> {{ currencySign + totalPrice }}
                                </a>
                            </div>
                        </div>

                        <div class="col-12 moreLink">
                            <a href="#faq" class="seeAll"><i class="icon-question-sign"></i> Have questions? See how to play</a>
                        </div>

                    </div>

                </div>
            </div>

        <div class="section m-0 clearfix gift-section">
            <div class="anchor" id="gift"></div>
            <div class="container">

                <div class="heading-block center border-bottom-0 bottommargin-sm">
                    <h2 class="nott how-header">Buy a gift voucher</h2>
                </div>

                <div class="row col-mb-50">
                    <div class="col-lg-6">
                        <img src="https://cursed-tales.com/assets-sales/img/giftcard.png">
                    </div>
                    <div class="col-lg-6">
                        <h2 class="nott product-header">Gift an online escape room!</h2>
                        <p>Send a personalised gift card to someone special by email or buy and send a physical gift card.</p>
                        <a href="#" class="buyGift"
                          v-on:click.stop.prevent="buyGift()"
                      ><i class="icon-shopping-cart"></i> Buy a gift for {{ currencySign + giftPrice }}</a>
                    </div>
                </div>
            </div>
            <div class="bottom-border"></div>
        </div>

        <div class="clear"></div>

        <div class="section m-0 clearfix how-section">
          <div class="anchor" id="faq"></div>
          <div class="container">

            <div class="heading-block center border-bottom-0 bottommargin-sm">
              <h2 class="nott how-header">Frequently Asked Questions</h2>
            </div>

            <div class="row col-mb-50">
              <div class="col-lg-6">
                <p class="question" v-on:click="revealAnswer(1)">Q. When can I play and is there a time limit?</p>
                <p class="answer" v-if="revealFAQ==1">A. Once purchased your game will be added to your account. You can then <strong>login and play any day or time with no time limit</strong> to complete the game!</p>
                <p class="question" v-on:click="revealAnswer(3)">Q. How big can my team be?</p>
                <p class="answer" v-if="revealFAQ==3">A. A good recommendation from us is between 1 and 6 people, but there is no rule or limit. Our tales in fact can be enjoyed alone, with a small group or fill a room with your friends and family and all experience them together.</p>
                <p class="question" v-on:click="revealAnswer(5)">Q. What is the recommended minimum age?</p>
                <p class="answer" v-if="revealFAQ==5">A. Due to the adult themes within our tales we recommend that you are 16 or over to play.</p>
                <p class="question" v-on:click="revealAnswer(7)">Q. Can I buy as a gift for someone?</p>
                <p class="answer" v-if="revealFAQ==7">A. Yes you can buy our tales as an exciting gift for friends and family to enjoy themselves, just check the ‘buy as gift’ option when purchasing a tale.</p>
                <p class="question" v-on:click="revealAnswer(9)">Q. How long does it take?</p>
                <p class="answer" v-if="revealFAQ==9">A. There is no time limit and the time it takes to complete can vary depending the difficulty you choose and your experience. For example, choosing Casual on 'The Mother' will likely take you around 40 minutes, while Standard difficulty on 'The Forest' can take, on average, 1 hour and 30 minutes.</p>
              </div>
              <div class="col-lg-6">
                <p class="question" v-on:click="revealAnswer(2)">Q. What devices are compatible with the game?</p>
                <p class="answer" v-if="revealFAQ==2">A. A laptop or desktop is the best way to play. Tablets are compatible but not advised due to the small screens.</p>
                <p class="question" v-on:click="revealAnswer(4)">Q. Can I play with friends in different places?</p>
                <p class="answer" v-if="revealFAQ==4">A. Yes our tales are designed to be enjoyed from the comfort of your own home however this does not mean one home. Whether you are playing with friends in the next street or family in another country you can all be together in the tale, breaking the curse using the screen share option in Zoom.</p>
                <p class="question" v-on:click="revealAnswer(6)">Q. What are the technical requirements?</p>
                <p class="answer" v-if="revealFAQ==6">A. Our tales are web based and require no extra downloads as they are hosted on your browser. We recommend playing on a desktop or laptop to achieve the best interactive quality and visual content. It is compatible to be played on a tablet but  just to be aware that the overall game play experience may be reduced slightly.  We do not recommend using a mobile phone to play the game.</p>
                <p class="question" v-on:click="revealAnswer(8)">Q. How accessible is it for people?</p>
                <p class="answer" v-if="revealFAQ==8">A. All of our puzzles comprise of a visual aspect and all can be completed without the audio effects. There is also an audio aspect to any text based clue or content which would aid people who struggle to read.</p>
              </div>
            </div>
          </div>
        </div>

        <div class="clear"></div>

        <div class="section mb-0 reviews-section">
          <div class="anchor" id="reviews"></div>
          <div class="container clearfix">

            <div class="heading-block center border-bottom-0">
              <h2 class="nott reviews-header">What Our Customers Say</h2>
            </div>

            <div class="row clearfix">

              <div class="col-lg-3 col-md-6">
                <div class="review">
                  <div class="review-desc review-desc-bg">
                    <p><span>"</span>Really enjoyed cursed tales the mother we completed it as a family 2 adults and 2 children it really kept us entertained some of the clues had us really guessing but we solved it in the end. Ready for the next one now.<span>"</span></p>
                    <div class="review-title">
                      <img src="https://cursed-tales.com/assets-sales/img/reviewer4.jpg">
                      <h4 class="fw-normal">Gail Fu <i class="icon-star"></i><i class="icon-star"></i><i class="icon-star"></i><i class="icon-star"></i><i class="icon-star"></i><span>3 November at 16:46</span></h4>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-6">
                <div class="review">
                  <div class="review-desc review-desc-bg">
                    <p><span>"</span>This was a fun game. The story telling is fantastic and the puzzles entertaining. Defo recommend it. Great start!<span>"</span></p>
                    <div class="review-title">
                      <img src="https://cursed-tales.com/assets-sales/img/reviewer1.jpg">
                      <h4 class="fw-normal">Janine Sinclair <i class="icon-star"></i><i class="icon-star"></i><i class="icon-star"></i><i class="icon-star"></i><i class="icon-star"></i><span>29 October at 10:09</span></h4>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-6">
                <div class="review">
                  <div class="review-desc review-desc-bg">
                    <p><span>"</span>Lucky enough to have a preview of cursed tales. Excellent creepy story line. The tech was really easy to follow. Really good puzzles and riddles to solve. Really enjoyed our night getting to the bottom of the story of 'The Mother' and would highly recommend!<span>"</span></p>
                    <div class="review-title">
                      <img src="https://cursed-tales.com/assets-sales/img/reviewer3.jpg">
                      <h4 class="fw-normal">Becky Walker <i class="icon-star"></i><i class="icon-star"></i><i class="icon-star"></i><i class="icon-star"></i><i class="icon-star"></i><span>28 October at 19:31</span></h4>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-6">
                <div class="review">
                  <div class="review-desc review-desc-bg">
                    <p><span>"</span>Managed to get a sneak preview and really enjoyed playing. Creepy storyline which flows through the game and interesting puzzles to solve. Lots of fun, would recommend.<span>"</span></p>
                    <div class="review-title">
                      <img src="https://cursed-tales.com/assets-sales/img/reviewer2.jpg">
                      <h4 class="fw-normal">Chris Flanagan <i class="icon-star"></i><i class="icon-star"></i><i class="icon-star"></i><i class="icon-star"></i><i class="icon-star"></i><span>26 October at 15:46</span></h4>
                    </div>
                  </div>
                </div>
              </div>

            <div class="col-lg-12 col-md-12 moreLink">
                <div class="trustpilot-widget" data-locale="en-GB" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="61962218f9766f44e8470542" data-style-height="80px" data-style-width="100%" data-theme="dark" data-min-review-count="50" data-style-alignment="center">
                    <a href="https://uk.trustpilot.com/review/cursed-tales.com" target="_blank" rel="noopener">Trustpilot</a>
                </div>
            </div>

            </div>
          </div>
        </div>

      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import BuyForm from '../components/BuyForm.vue'
import BuyGift from '../components/BuyGift.vue'
import VideoTrailer from '../components/VideoTrailer.vue'

export default {
  name: 'HomeContainer',
  props: {
  },
  components: {
    BuyForm,
    BuyGift,
    VideoTrailer
  },
  watch: {
    revealModal(val) {
        if (val) {
            document.body.classList.add('bodyHideScroll')
        } else {
            document.body.classList.remove('bodyHideScroll')
        }
    }
  },
  data () {
    return {
      currency: "GBP",
      currencySign: "£",
      originalPrice: "9.99",
      subtotalPrice: this.$defaultGBP || "9.99",
      totalPrice: this.$defaultGBP || "9.99",
      giftPrice: "6.99",
      collectionPrice: "23.99",
      showDiscounted: false,
      taleType: "Digital",
      taleId: 1,

      selectedTale: null,
      selectedKit: null,

      promotion: null,

      revealFAQ: null,
      revealModal: false,
      revealModalType: null,
      revealModalTrailer: null,

      emailAddress: this.$developer ? "adamwalker297@gmail.com" : null,
      emailValid: true,
      emailSubmitted: false,
      emailSuccess: false,
      emailLoading: false,

      authorisation: false,
      authorisationResponse: null
    }
  },
  methods: {
    validEmail: function (email) {
        let regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regEx.test(email);
    },
    checkValid() {
        this.emailValid = false
        if (!this.emailSubmitted || !this.emailAddress || this.validEmail(this.emailAddress.trim()))
            this.emailValid = true
    },
    emailSubmit() {
        this.emailSubmitted = true
        this.emailValid = true
        this.emailLoading = false
        if (!this.validEmail(this.emailAddress))
            this.emailValid = false

        if (this.emailAddress && this.emailValid && !this.emailLoading) {
            this.emailLoading = true
            axios
                .post('https://cursed-tales.com/subscribe', { email: this.emailAddress.trim() })
                .then(response => {
                    console.log("response", response)
                    this.emailSuccess = true
                    this.emailLoading = false
                })
                .catch(error => {
                    this.emailSuccess = true
                    this.emailLoading = false
                    console.log(error)
                })
        }
    },
    revealAnswer(num) {
      this.pushMfqVariable("FAQ_VIEWED", "FAQ-"+num)
      if (this.revealFAQ == num)
        this.revealFAQ = null
      else
        this.revealFAQ = num
    },
    setCurrency() {
      if (this.currency == 'USD') {
        this.currencySign = "$"
        this.originalPrice = "13.99"
        this.subtotalPrice = this.$defaultUSD || "13.99"
        this.totalPrice = this.$defaultUSD || "13.99"
        this.giftPrice = "9.79"
        this.collectionPrice = "28.99"
      }
    },
    buyTale(taleId, type = "Digital") {
        this.taleType = type
        this.taleId = taleId
        this.revealModal = true
        this.revealModalType = "buy"
    },
    buyGift() {
      this.revealModal = true
      this.revealModalType = "gift"
    },
    modalTrailer(trailer) {
      this.revealModal = true
      this.revealModalType = "trailer"
      this.revealModalTrailer = trailer
      this.$emit('modal-active', true)
    },
    modalGallery() {
      this.revealModal = true
      this.revealModalType = "gallery"
      this.$emit('modal-active', true)
    },
    closeForm() {
      this.revealModal = false
      this.revealModalType = null
      this.revealModalTrailer = null
      this.$emit('modal-active', false)
    },

    selectTale(taleId) {
        this.selectedTale = taleId
    },
    selectKit(kitId) {
        this.selectedKit = kitId
    }
  },
  mounted: function() {
    //this.currency = this.$route.query.currency || this.currency
    //this.setCurrency()

    axios
        .post('https://api.cursed-tales.com/misc/get_active_promotion?type=home' + (this.$developer ? '&developer=true' : ''), {})
        .then(response => {
            console.log("get_active_promotion", response.data)
            if (response.data.country == "US") {
                this.currency = "USD"
                this.setCurrency()
            }

            if (response.data.code) {
                this.promotion = response.data.code
                this.subtotalPrice = response.data.default
                this.totalPrice = response.data.default

                this.showDiscounted = false;
                if (parseFloat(this.totalPrice) < parseFloat(this.originalPrice))
                    this.showDiscounted = true;
            }

        })

  },
  beforeDestroy: function() {
    if (this.cardElement)
      this.cardElement.unmount();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.moreLink {
  padding-top: 30px;
  text-align: center;
}
.moreLink a.seeAll {
    display: inline-block;
    color: #fff;
    padding: 10px;
    border: 3px solid #fff;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 700;
}
.moreLink a.seeAll i {
    font-size: 16px;
    margin-right: 5px;
}
.moreLink a.seeAll:hover {
    opacity: 0.6;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.buyingNotice {
  margin: 0 0 20px;
  font-size: 14px;
  color: #ddd;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

@media (prefers-reduced-motion: no-preference) {
    :root {
        scroll-behavior: smooth;
    }
}

</style>
