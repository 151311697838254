<template>
  <div id="content-wrapper">
    <div class="modal-wrapper" v-if="revealModal">
      <div class="modal-overlay"></div>
      <div class="modal-scroller">
        <div class="modal-content">
          <buy-form
            v-if="revealModalType == 'buy'"
            v-bind:currency="currency"
            v-bind:tale_id="taleId"
            v-bind:promotion="promotion"
            v-on:close-form="closeForm()"
          ></buy-form>
        </div>
      </div>
    </div>

    <page-title ptitle="" short="true"></page-title>

    <section id="content">
        <div class="sketch-bottom"></div>
        <div class="content-wrap page-content">

            <div class="container clearfix">

                <div class="loader-container" v-if="!reaction"></div>

                <div class="accordion accordion-lg mx-auto mb-0 clearfix" v-bind:style="{ 'max-width': '800px' }" v-if="reaction">

                    <div class="accordion-header" v-if="reaction == 'no'">
                        <div class="accordion-title" v-if="!form.result">
                            <h2 class="pageTitle negative">Oh no!</h2>
                            <h3 class="pageSubtitle">We are sorry to hear you didn't enjoy the game. We would appreciate you sharing some feedback with us below on what you didn't enjoy so that we can improve our games in the future.</h3>
                        </div>
                        <div class="accordion-title" v-if="form.result">
                            <h2 class="pageTitle">Thank you!</h2>
                            <h3 class="pageSubtitle">We really appreciate your feedback.</h3>
                        </div>
                    </div>
                    <div class="accordion-content clearfix" v-if="reaction == 'no' && !form.result">
                        <div class="feedbackForm">
                            <form-builder v-bind:submitMethod="submitFunction" v-bind:formData="form" v-on:form-error="setFormError">
                                <form-input type="textarea" name="feedback" label="" v-model="form.data.feedback" v-bind:validation="form.validation.feedback" v-bind:messages="form.error_messages.feedback" v-bind:class="['whiteInput']"></form-input>
                                <form-submit label="Send Feedback" v-bind:status="form.status" v-bind:class="['whiteSubmit']"></form-submit>
                            </form-builder>
                        </div>
                    </div>

                    <div class="accordion-header" v-if="reaction == 'yes'">
                        <div class="accordion-title">
                            <h2 class="pageTitle">Thank you!</h2>
                            <h3 class="pageSubtitle">We are happy to hear you enjoyed the game. If you could please take the time to leave us a review on trustpilot we would be eternally grateful.</h3>
                        </div>
                    </div>
                    <div class="accordion-content clearfix" v-if="reaction == 'yes'">
                        <a href="https://uk.trustpilot.com/evaluate/cursed-tales.com" target="_blank" class="reviewUs"></a>

                        <h3 class="pageSubtitle otherGames" v-if="showTales.length">Other tales you may like with an <span>exclusive discount</span></h3>
                        <div class="talesContent otherTales col-lg-12" v-if="showTales.length">

                            <table class="table">
                              <tbody v-for="tale in showTales" :key="tale.id">
                                <tr>
                                    <td class="taleCell">
                                        <img v-bind:src="tale.featured_image2_url" v-bind:alt="tale.name" class="tableImg">
                                        <h3>{{ tale.name }}</h3>
                                        <p>{{ tale.short }}</p>

                                        <div class="featureList altList" v-if="tale.id == 1">
                                          <div class="featureItem">
                                            <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-player2.png" alt="Icon Player"></span>Play the role of inspector</p>
                                          </div>
                                          <div class="featureItem">
                                            <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-feet2.png" alt="Icon Feet"></span>Journey through the town solving puzzles and unravelling more of the harrowing story</p>
                                          </div>
                                          <div class="featureItem">
                                            <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-inspect2.png" alt="Icon Inspect"></span>Discover the murderous truth to set The Mother free</p>
                                          </div>
                                          <div class="featureItem">
                                            <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-warning2.png" alt="Icon Warning"></span>Due to adult themes, we recommend that players are age 16 or over</p>
                                          </div>
                                          <div class="featureItem">
                                            <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-lock2.png" alt="Icon Lock"></span>Difficulty Options:</p>
                                            <p class="difficultyOptions"><strong>Casual</strong> <i class="icon-skull skullFirst"></i><i class="icon-skull skullBlack"></i><i class="icon-skull skullBlack"></i><i class="icon-skull skullBlack"></i><i class="icon-skull skullBlack"></i><span> &nbsp;&nbsp;|&nbsp;&nbsp; </span><strong>Standard</strong> <i class="icon-skull skullFirst"></i><i class="icon-skull"></i><i class="icon-skull"></i><i class="icon-skull skullBlack"></i><i class="icon-skull skullBlack"></i></p>
                                          </div>
                                          <div class="clear"></div>
                                        </div>

                                        <div class="featureList altList" v-if="tale.id == 2">
                                          <div class="featureItem">
                                            <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-player2.png" alt="Icon Player"></span>Play the role of a trapped father</p>
                                          </div>
                                          <div class="featureItem">
                                            <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-feet2.png" alt="Icon Feet"></span>Journey through the Forest solving puzzles in a desperate bid to escape</p>
                                          </div>
                                          <div class="featureItem">
                                            <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-inspect2.png" alt="Icon Inspect"></span>Discover the secret of the Forest to finally be free</p>
                                          </div>
                                          <div class="featureItem">
                                            <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-lock2.png" alt="Icon Lock"></span>Difficulty Options:</p>
                                            <p class="difficultyOptions"><strong>Casual</strong> <i class="icon-skull skullFirst"></i><i class="icon-skull"></i><i class="icon-skull skullBlack"></i><i class="icon-skull skullBlack"></i><i class="icon-skull skullBlack"></i><span> &nbsp;&nbsp;|&nbsp;&nbsp; </span><strong>Standard</strong> <i class="icon-skull skullFirst"></i><i class="icon-skull"></i><i class="icon-skull"></i><i class="icon-skull"></i><i class="icon-skull skullBlack"></i></p>
                                          </div>
                                          <div class="clear"></div>
                                        </div>

                                        <div class="featureList altList" v-if="tale.id == 3">
                                          <div class="featureItem">
                                            <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-player2.png" alt="Icon Player"></span>Play the role of a woman cursed by a witch</p>
                                          </div>
                                          <div class="featureItem">
                                            <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-feet2.png" alt="Icon Feet"></span>Journey through the Dolls House solving puzzles to discover a way out</p>
                                          </div>
                                          <div class="featureItem">
                                            <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-inspect2.png" alt="Icon Inspect"></span>Uncover the secret of the Dolls House to finally escape</p>
                                          </div>
                                          <div class="featureItem">
                                            <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-lock2.png" alt="Icon Lock"></span>Difficulty Options:</p>
                                            <p class="difficultyOptions"><strong>Casual</strong> <i class="icon-skull skullFirst"></i><i class="icon-skull"></i><i class="icon-skull skullBlack"></i><i class="icon-skull skullBlack"></i><i class="icon-skull skullBlack"></i><span> &nbsp;&nbsp;|&nbsp;&nbsp; </span><strong>Standard</strong> <i class="icon-skull skullFirst"></i><i class="icon-skull"></i><i class="icon-skull"></i><i class="icon-skull"></i><i class="icon-skull skullBlack"></i></p>
                                          </div>
                                          <div class="clear"></div>
                                        </div>
                                        <div class="difficultySelect">
                                          <a href="#" class="playTale" v-on:click.stop.prevent="buyTale(tale.id)"><i class="icon-play"></i> Buy now for <span v-if="showDiscounted" class="discounted">{{ currencySign + originalPrice }}</span> {{ currencySign + totalPrice }}</a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </section>

  </div>
</template>

<script>
import axios from 'axios'
import PageTitle from '../components/PageTitle.vue'
import BuyForm from '../components/BuyForm.vue'
import FormBuilder from '../components/FormBuilder.vue'
import FormInput from '../components/FormInput.vue'
import FormSubmit from '../components/FormSubmit.vue'

export default {
    name: 'FeedbackContainer',
    components: {
        PageTitle,
        BuyForm,
        FormBuilder,
        FormInput,
        FormSubmit
    },
    props: {
    },
    data () {
        return {
            tales: [],
            showTales: [],

            reaction: null,
            gameRef: null,

            currency: "GBP",
            currencySign: "£",
            originalPrice: "9.99",
            subtotalPrice: "9.99",
            totalPrice: "9.99",
            showDiscounted: false,
            taleId: 1,

            promotion: null,

            revealModal: false,
            revealModalType: null,

            form: {
                data: {
                  feedback: null,
                },
                validation: {
                  feedback: ['required'],
                  main: null
                },
                error_messages: {},
                status: null,
                result: false,
            },
        }
    },
    watch: {
    },
    methods: {
        getTales: function () {
            axios
                .get('https://api.cursed-tales.com/tales')
                .then(response => {
                    this.tales = response.data.data
                })
                .catch(error => {
                    console.log(error)
                })
        },
        setCurrency() {
            if (this.currency == 'USD') {
                this.currencySign = "$"
                this.originalPrice = "13.99"
                this.subtotalPrice = this.$defaultUSD || "13.99"
                this.totalPrice = this.$defaultUSD || "13.99"
            }
        },
        buyTale(taleId) {
            this.taleId = taleId
            this.revealModal = true
            this.revealModalType = "buy"
        },
        closeForm() {
            this.revealModal = false
            this.revealModalType = null
            this.$emit('modal-active', false)
        },
        setFormError(error) {
            this.form.validation.main = error
            setTimeout(() => {
                this.form.validation.main = null
            }, 4000);
        },
        submitFunction: function () {
            this.form.status = "loading";

            axios
                .post('https://api.cursed-tales.com/misc/set_feedback/'+this.gameRef+'/'+this.reaction+'/0', { feedback: this.form.data.feedback })
                .then(response => {
                    console.log("response", response)
                    setTimeout(() => {
                        this.form.result = true;
                    }, 1000);
                })
                .catch(error => {
                    console.log(error)
                    this.form.status = "error";
                })
        },

    },
    mounted: function() {
        this.getTales()

        this.reaction = this.$route.query.feedback
        this.gameRef = this.$route.query.tale

        if (this.reaction == "yes" && this.gameRef) {
            axios
                .post('https://api.cursed-tales.com/misc/check_tales?reference=' + this.gameRef, {})
                .then(response => {
                    this.showTales = response.data.tales
                })
        }

        axios
            .post('https://api.cursed-tales.com/misc/get_active_promotion?type=customer' + (this.$developer ? '&developer=true' : ''), {})
            .then(response => {
                console.log("get_active_promotion", response.data)
                if (response.data.country == "US") {
                    this.currency = "USD"
                    this.setCurrency()
                }

                if (response.data.code) {
                    this.promotion = response.data.code
                    this.subtotalPrice = response.data.default
                    this.totalPrice = response.data.default

                    this.showDiscounted = false;
                    if (parseFloat(this.totalPrice) < parseFloat(this.originalPrice))
                        this.showDiscounted = true;
                }

            })
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .accordion { margin-bottom: 20px; }
    .accordion-header {
        padding: 0.75rem 0;
        border-top: 1px dotted #DDD;
        cursor: pointer;
    }
    .accordion-header .accordion-title {
        font-size: 60px;
        cursor: default;
        text-align: center;
    }
    .accordion:not([data-collapsible="true"]) .accordion-header.accordion-active { cursor: auto; }
    .accordion-header:first-child { border-top: none; }
    .accordion-header:not(.accordion-active) .accordion-open,
    .accordion-active .accordion-closed { display: none; }
    .accordion-active .accordion-open { display: inline-block; }
    .accordion-content {
        padding-top: 0;
        padding-bottom: 1.5rem;
    }
    .accordion-bg .accordion-header {
        background-color: #EEE;
        padding: 0.75rem;
        border-radius: 2px;
        margin: 0;
        margin-bottom: 5px;
        border-top: 0;
    }
    .accordion-bg .accordion-content {
        padding: 0.75rem 0.75rem 1.5rem;
    }
    .accordion-border {
        border: 1px solid #DDD;
        border-radius: 4px;
    }
    .accordion-border .accordion-header {
        border-color: #CCC;
        padding: 0.75rem;
        margin: 0;
    }
    .accordion-border .accordion-content {
        padding: 0 1.125rem 1.5rem;
    }
    .accordion-lg .accordion-header {
        font-size: 1.25rem;
    }

    .pageTitle {
        font-size: 60px;
        color: #53d62b;
    }
    .pageTitle.negative {
        color: #d6522b;
    }
    .pageSubtitle {
        font-size: 30px;
        color: #fff;
    }
    .pageTitle,
    .pageSubtitle {
        text-shadow: 2px 2px #000;
        font-family: 'Andada Pro', sans-serif;
        margin: 0;
    }

    .pageSubtitle.otherGames {
        text-align: center;
        padding: 80px 0 40px;
    }
    .pageSubtitle.otherGames span {
        color: #53d62b;
    }

    .reviewUs {
        width: 428px;
        height: 100px;
        background-image: url(https://cursed-tales.com/assets-sales/img/trustpilot.png);
        background-position: center center;
        background-repeat: no-repeat;
        position: relative;
        display: block;
        border: 5px solid #51b37f;
        border-radius: 5px;
        margin: 20px auto 0;
    }
    .reviewUs:hover {
        opacity: 0.8;
    }

    .forgotPassword {
        text-align: right;
    }
    .forgotPassword a {
        color: #fff;
        text-align: right;
    }

    .form-footer .col-12 {
        text-align: right;
    }
    .form-footer .errorMsg {
        margin: 0;
    }

    .form-group.checkBoxWrapper .checkBox {
        display: inline-block;
        float: left;
        width: 25px;
        height: 25px;
        border: 2px solid #fff;
        border-radius: 2px;
        margin: 10px 10px 0 0;
        cursor: pointer;
    }
    .form-group.checkBoxWrapper .checkBox.activated {
        border: 2px solid #2dab3d;
    }
    .form-group.checkBoxWrapper .checkBox i {
        font-size: 20px;
        line-height: 22px;
        margin-left: 1px;
        color: #2dab3d;
    }
    .form-group.checkBoxWrapper label {
        display: block;
        margin-top: 10px;
    }

  .talesContent .table {
      color: #d6d6d6;
  }

  .talesContent .table tr {
      position: relative;
      border-bottom: 2px solid #ddd;
  }
  .talesContent .table tr:last-child {
      border-bottom: none;
  }
  .talesContent .table td {
    position: relative;
    padding: 10px 0;
    vertical-align: top;
    border: none;
  }

  .talesContent .table th {
      font-size: 18px;
      color: #711414;
  }
  .talesContent .table th.thCenter {
      text-align: center;
  }
  .talesContent .table .taleCell {
    padding-left: 220px;
    padding-right: 20px;
    width: 100%;
    padding-bottom: 40px;
    border-bottom: 2px solid #000;
  }

  .talesContent .table .taleCell .statusLabel {
    position: absolute;
    top: 20px;
    right: 0;
  }
  .talesContent .table .taleCell .statusLabel .completed {
    font-family: 'Andada Pro', sans-serif;
    color: #3bad16;
    padding: 8px;
    border: 2px solid #3bad16;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 400;
    margin: 0 20px 20px;
  }
  .talesContent .table .taleCell .statusLabel .pending {
    font-family: 'Andada Pro', sans-serif;
    color: #d59f07;
    padding: 8px;
    border: 2px solid #d59f07;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 400;
    margin: 0 20px 20px;
  }

  .talesContent .table .taleCell img.tableImg {
      position: absolute;
      top: 0;
      left: 0;
      width: 180px;
      margin-right: 10px;
  }

  .talesContent .table .taleCell h3 {
      font-family: 'Andada Pro', sans-serif;
      font-size: 50px;
      color: #ffffff;
      margin-bottom: 0;
  }
  .talesContent .table .difficultySelect {
    margin-top: 40px;
  }
  .talesContent .table .taleCell p {
      font-size: 18px;
      color: #bbbbbb;
      margin-bottom: 10px;
  }
  .talesContent .table .taleCell p.warning {
    font-size: 20px;
    color: #da1717;
  }

  .talesContent .table .taleCell i.skullFirst {
    margin-left: 10px;
  }
  .talesContent .table .taleCell i.skullBlack {
    color: #000;
    opacity: 0.2;
  }

  .talesContent .table .taleCell .featureList.altList p {
      color: #ddd;
  }

  .talesContent .table .detailsCell {
    width: 15%;
    text-align: center;
    vertical-align: middle;
    padding: 0 20px;
    border-left: 1px solid #eee;
    border-right: 1px solid #ddd;
  }
  .talesContent .table .detailsCell .playTime {
    padding: 10px;
    background: #eee;
    margin-bottom: 10px;
    border: 3px solid #ddd;
  }
  .talesContent .table .detailsCell h4 {
    font-family: 'Andada Pro', sans-serif;
    font-size: 22px;
    color: #762323;
    margin-bottom: 10px;
  }
  .talesContent .table .detailsCell p {
    font-size: 15px;
    color: #333;
    margin-bottom: 10px;
  }
  .talesContent .table .detailsCell a.facebookShare {
    display: block;
    color: #072aa7;
    padding: 5px;
    border: 2px solid #072aa7;
    border-radius: 3px;
    font-size: 15px;
    font-weight: 700;
    margin: 10px 0;
    cursor: pointer;
  }
  .talesContent .table .detailsCell p.gameHints {
    color: #c84d4d
  }
  .talesContent .table .detailsCell p.gameSolutions {
    color: #b01717
  }

  .talesContent .table .playCell {
    width: 15%;
    text-align: center;
    vertical-align: middle;
    border-left: 1px solid #ddd;
  }

  .talesContent .table .playTale,
  .noTales a {
    color: #dddddd;
    padding: 10px;
    border: 5px solid #fff;
    border-radius: 5px;
    font-size: 32px;
    font-weight: 700;
    margin-top: 20px !important;
  }
  .talesContent .table .playTale:hover,
  .noTales a:hover {
    color: #222;
    border: 5px solid #911414;
  }
  .talesContent .table .playTale.expertOption {
    display: block;
    color: #711414;
    padding: 7px;
    border: 3px solid #711414;
    border-radius: 5px;
    font-size: 22px;
    font-weight: 700;
    margin-top: 20px !important;
  }
  .talesContent .table .playTale.expertOption:hover {
    color: #222;
    border: 3px solid #911414;
  }

  .talesContent .table .zoomHelp {
    display: block;
    padding-top: 20px;
    color: #333;
    font-size: 14px;
  }

  .feedbackForm {
    width: 60%;
    margin: 0 auto;
    padding: 20px 0;
  }
  .feedbackForm .btn {
    width: 60%;
    margin: 0 auto !important;
  }

    .loader-container {
        margin: 50px auto;
    }

    @media (max-width: 420px) {
        .pageTitle {
            font-size: 50px;
            color: #53d62b;
        }
        .pageSubtitle {
            font-size: 25px;
        }
        .feedbackForm {
            width: 100%;
        }
        .feedbackForm .btn {
            width: 90%;
        }
        .reviewUs {
            width: 90%;
            background-size: contain;
            border: 0;
        }

        .talesContent .table .playTale {
            font-size: 25px;
            font-weight: 100;
        }
    }

    @media (max-width: 500px) {
        .discounted {
            display: none;
        }
    }
    @media (max-width: 768px) {

        .talesContent .table .taleCell {
            padding-left: 0;
            text-align: center;
        }
        .talesContent .table .taleCell .tableImg {
            display: none;
        }
    }

</style>
