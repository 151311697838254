<template>
  <div id="content-wrapper">
    <div class="modal-wrapper" v-if="revealModal">
      <div class="modal-overlay"></div>
      <div class="modal-scroller">
        <div class="modal-content">
          <buy-form
            v-if="revealModalType == 'buy'"
            v-bind:currency="currency"
            v-bind:type="taleType"
            v-bind:tale_id="taleId"
            v-bind:promotion="promotion"
            v-on:close-form="closeForm()"
          ></buy-form>
        </div>
      </div>
    </div>

    <page-title ptitle="My Account"></page-title>

    <section id="content">
            <div class="sketch-bottom"></div>
            <div class="content-wrap page-content">

                <div class="container clearfix account-container">

                    <div class="row gutter-40 col-mb-80">

                        <account-menu></account-menu>

                        <div class="talesContent col-lg-12" v-if="authed">

                            <table class="table" v-if="authed.tales.length">
                              <tbody v-for="tale in authed.tales" :key="tale.id">
                                <tr>
                                    <td class="taleCell">
                                        <img v-bind:src="tale.tale.featured_image2_url" v-bind:alt="tale.tale.name" class="tableImg">
                                        <h3>{{ tale.tale.name }}</h3>
                                        <p>{{ tale.tale.short }}</p>

                                        <p class="warning" v-if="$mobileDevice"><u><strong>Warning:</strong></u> The tale cannot be played on a mobile device, you need to use a Laptop or Desktop</p>

                                        <div class="difficultySelect">
                                          <a href="#" class="playTale" v-if="showPlayOptions!=tale.tale.id" v-on:click="showPlayOptions=tale.tale.id">Play tale now</a>
                                          <div class="clearfix"></div>
                                          <a href="https://cursed-tales.com/guide/how-to-play-using-zoom" class="zoomHelp" v-if="tale.status != 'completed' && showPlayOptions!=tale.tale.id">Looking to play this using Zoom? Click here to find out how</a>
                                          <div v-if="showPlayOptions==tale.tale.id">
                                            <h4>Select your difficulty level</h4>
                                            <h5>Don't worry, if you choose Standard and find it too tough, you can comeback here and start over on Casual</h5>
                                            <div class="row">
                                              <div class="col-6 first">
                                                <a v-bind:href="'https://cursed-tales.com/player?ref=' + authed.reference + '&tale=' + tale.reference + '&difficulty=' + (tale.tale.id == 1 ? 2 : 1)" class="playTale">
                                                  Casual
                                                </a>
                                                <div class="clear"></div>
                                                <p class="difficultyOptions" v-if="tale.tale.id == 1"><strong>Difficulty Rating:</strong> <i class="icon-skull skullFirst"></i><i class="icon-skull skullBlack"></i><i class="icon-skull skullBlack"></i><i class="icon-skull skullBlack"></i><i class="icon-skull skullBlack"></i></p>
                                                <p class="difficultyOptions" v-if="tale.tale.id == 2"><strong>Difficulty Rating:</strong> <i class="icon-skull skullFirst"></i><i class="icon-skull"></i><i class="icon-skull skullBlack"></i><i class="icon-skull skullBlack"></i><i class="icon-skull skullBlack"></i></p>
                                                <p>This will involve easier puzzles and a shorter experience, ideal for those who enjoy the social aspect of these kind of games versus a challenge.</p>
                                              </div>
                                              <div class="col-6">
                                                <a v-bind:href="'https://cursed-tales.com/player?ref=' + authed.reference + '&tale=' + tale.reference + '&difficulty=' + (tale.tale.id == 1 ? 1 : 2)" class="playTale">Standard</a>
                                                <div class="clear"></div>
                                                <p class="difficultyOptions" v-if="tale.tale.id == 1"><strong>Difficulty Rating:</strong> <i class="icon-skull skullFirst"></i><i class="icon-skull"></i><i class="icon-skull"></i><i class="icon-skull skullBlack"></i><i class="icon-skull skullBlack"></i></p>
                                                <p class="difficultyOptions" v-if="tale.tale.id == 2"><strong>Difficulty Rating:</strong> <i class="icon-skull skullFirst"></i><i class="icon-skull"></i><i class="icon-skull"></i><i class="icon-skull"></i><i class="icon-skull skullBlack"></i></p>

                                                <p>This difficulty features the standard level of puzzles intended for the tale and will result in a longer and more challenging experience.</p>
                                                <!--<a v-bind:href="'https://cursed-tales.com/player?ref=' + authed.reference + '&tale=' + tale.reference + '&difficulty=' + (tale.tale.id == 1 ? 1 : 2) + '&nohints=true'" class="playTale expertOption">Want to take the challenge up to the next level? Click here to play Standard with hints turned off</a>-->
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    </td>
                                    <td class="detailsCell" v-if="tale.tale.id == 1">
                                      <div class="playTime">
                                        <h4>Casual difficulty</h4>
                                         <div v-if="tale.status == 'completed'">
                                          <p><strong>Completed Time:</strong> {{ tale.status == 'completed' ? tale.completed_time : 'N/A' }}</p>
                                          <p class="gameHints"><strong>Hints Used:</strong> {{ tale.status == 'completed' ? tale.hints_used : 'N/A' }}</p>
                                          <p class="gameSolutions"><strong>Solutions Used:</strong> {{ tale.status == 'completed' ? tale.solutions_used : 'N/A' }}</p>
                                        </div>

                                        <div v-else>
                                          <p><strong>Not completed</strong></p>
                                        </div>
                                      </div>
                                      <div class="playTime">
                                        <h4>Standard difficulty</h4>
                                        <div v-if="tale.casual_status == 'completed'">
                                          <p><strong>Completed Time:</strong> {{ tale.casual_status == 'completed' ? tale.completed_time_casual : 'N/A' }}</p>
                                          <p class="gameHints"><strong>Hints Used:</strong> {{ tale.casual_status == 'completed' ? tale.hints_casual : 'N/A' }}</p>
                                          <p class="gameSolutions"><strong>Solutions Used:</strong> {{ tale.casual_status == 'completed' ? tale.solutions_casual : 'N/A' }}</p>
                                        </div>
                                        <div v-else>
                                          <p><strong>Not completed</strong></p>
                                        </div>
                                      </div>
                                      <!--
                                      <a href="#" v-on:click.stop.prevent="facebookShare(tale)" class="facebookShare" v-if="tale.status == 'completed'"><i class="icon-facebook-sign"></i> Share on facebook</a>
                                      <a href="https://www.facebook.com/thecursedtales/reviews/" target="_blank" class="facebookShare" v-if="tale.status == 'completed'"><i class="icon-pen"></i>Write a review</a>
                                      -->
                                    </td>
                                    <td class="detailsCell" v-if="tale.tale.id > 1">
                                      <div class="playTime">
                                        <h4>Casual difficulty</h4>
                                        <div v-if="tale.casual_status == 'completed'">
                                          <p><strong>Completed Time:</strong> {{ tale.casual_status == 'completed' ? tale.completed_time_casual : 'N/A' }}</p>
                                          <p class="gameHints"><strong>Hints Used:</strong> {{ tale.casual_status == 'completed' ? tale.hints_casual : 'N/A' }}</p>
                                          <p class="gameSolutions"><strong>Solutions Used:</strong> {{ tale.casual_status == 'completed' ? tale.solutions_casual : 'N/A' }}</p>
                                        </div>
                                        <div v-else>
                                          <p><strong>Not completed</strong></p>
                                        </div>
                                      </div>
                                      <div class="playTime">
                                        <h4>Standard difficulty</h4>
                                        <div v-if="tale.status == 'completed'">
                                          <p><strong>Completed Time:</strong> {{ tale.status == 'completed' ? tale.completed_time : 'N/A' }}</p>
                                          <p class="gameHints"><strong>Hints Used:</strong> {{ tale.status == 'completed' ? tale.hints_used : 'N/A' }}</p>
                                          <p class="gameSolutions"><strong>Solutions Used:</strong> {{ tale.status == 'completed' ? tale.solutions_used : 'N/A' }}</p>
                                        </div>
                                        <div v-else>
                                          <p><strong>Not completed</strong></p>
                                        </div>
                                      </div>
                                      <!--
                                      <a href="#" v-on:click.stop.prevent="facebookShare(tale)" class="facebookShare" v-if="tale.status == 'completed'"><i class="icon-facebook-sign"></i> Share on facebook</a>
                                      <a href="https://www.facebook.com/thecursedtales/reviews/" target="_blank" class="facebookShare" v-if="tale.status == 'completed'"><i class="icon-pen"></i>Write a review</a>
                                      -->
                                    </td>
                                    <!--
                                    <td class="playCell" v-if="!$mobileDevice">
                                      <a v-bind:href="'https://cursed-tales.com/player?ref=' + authed.reference + '&tale=' + tale.reference" class="playTale">{{ tale.status == 'completed' ? 'View' : 'Play' }}</a>
                                      <a href="https://cursed-tales.com/guide/how-to-play-using-zoom" class="zoomHelp" v-if="tale.status != 'completed'">Looking to play this using Zoom? Click here to find out how</a>
                                    </td>
                                    -->
                                </tr>
                                <tr>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>

                            <div class="noTales" v-else>
                              <i class="icon-book1"></i>
                              <span>You have currently purchased no games.</span>
                              <a href="https://cursed-tales.com" class="taleButton">View Games</a>
                            </div>

                        </div>

                        <div class="moreTales col-lg-12" v-if="otherTales.length">
                            <ul>
                                <li>
                                    <span><div>Other Games to buy <i class="icon-shopping-cart"></i></div></span>
                                </li>
                            </ul>
                        </div>
                        <div class="talesContent otherTales col-lg-12" v-if="otherTales.length">

                            <table class="table">
                              <tbody v-for="tale in otherTales" :key="tale.id">
                                <tr>
                                    <td class="taleCell">
                                        <img v-bind:src="tale.featured_image2_url" v-bind:alt="tale.name" class="tableImg">
                                        <h3>{{ tale.name }}</h3>
                                        <p>{{ tale.short }}</p>

                                        <div class="featureList" v-if="tale.id == 1">
                                          <div class="featureItem">
                                            <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-player2.png" alt="Icon Player"></span>Play the role of inspector</p>
                                          </div>
                                          <div class="featureItem">
                                            <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-feet2.png" alt="Icon Feet"></span>Journey through the town solving puzzles and unravelling more of the harrowing story</p>
                                          </div>
                                          <div class="featureItem">
                                            <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-inspect2.png" alt="Icon Inspect"></span>Discover the murderous truth to set The Mother free</p>
                                          </div>
                                          <div class="featureItem">
                                            <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-warning2.png" alt="Icon Warning"></span>Due to adult themes, we recommend that players are age 16 or over</p>
                                          </div>
                                          <div class="featureItem">
                                            <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-lock2.png" alt="Icon Lock"></span>Difficulty Options:</p>
                                            <p class="difficultyOptions"><strong>Casual</strong> <i class="icon-skull skullFirst"></i><i class="icon-skull skullBlack"></i><i class="icon-skull skullBlack"></i><i class="icon-skull skullBlack"></i><i class="icon-skull skullBlack"></i><span> &nbsp;&nbsp;|&nbsp;&nbsp; </span><strong>Standard</strong> <i class="icon-skull skullFirst"></i><i class="icon-skull"></i><i class="icon-skull"></i><i class="icon-skull skullBlack"></i><i class="icon-skull skullBlack"></i></p>
                                          </div>
                                          <div class="clear"></div>
                                        </div>

                                        <div class="featureList" v-if="tale.id == 2">
                                          <div class="featureItem">
                                            <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-player2.png" alt="Icon Player"></span>Play the role of a trapped father</p>
                                          </div>
                                          <div class="featureItem">
                                            <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-feet2.png" alt="Icon Feet"></span>Journey through the Forest solving puzzles in a desperate bid to escape</p>
                                          </div>
                                          <div class="featureItem">
                                            <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-inspect2.png" alt="Icon Inspect"></span>Discover the secret of the Forest to finally be free</p>
                                          </div>
                                          <div class="featureItem">
                                            <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-lock2.png" alt="Icon Lock"></span>Difficulty Options:</p>
                                            <p class="difficultyOptions"><strong>Casual</strong> <i class="icon-skull skullFirst"></i><i class="icon-skull"></i><i class="icon-skull skullBlack"></i><i class="icon-skull skullBlack"></i><i class="icon-skull skullBlack"></i><span> &nbsp;&nbsp;|&nbsp;&nbsp; </span><strong>Standard</strong> <i class="icon-skull skullFirst"></i><i class="icon-skull"></i><i class="icon-skull"></i><i class="icon-skull"></i><i class="icon-skull skullBlack"></i></p>
                                          </div>
                                          <div class="clear"></div>
                                        </div>

                                        <div class="featureList" v-if="tale.id == 3">
                                          <div class="featureItem">
                                            <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-player2.png" alt="Icon Player"></span>Play the role of a woman cursed by a witch</p>
                                          </div>
                                          <div class="featureItem">
                                            <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-feet2.png" alt="Icon Feet"></span>Journey through the Dolls House solving puzzles to discover a way out</p>
                                          </div>
                                          <div class="featureItem">
                                            <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-inspect2.png" alt="Icon Inspect"></span>Uncover the secret of the Dolls House to finally escape</p>
                                          </div>
                                          <div class="featureItem">
                                            <p><span><img src="https://cursed-tales.com/assets-sales/img/icon-lock2.png" alt="Icon Lock"></span>Difficulty Options:</p>
                                            <p class="difficultyOptions"><strong>Casual</strong> <i class="icon-skull skullFirst"></i><i class="icon-skull"></i><i class="icon-skull skullBlack"></i><i class="icon-skull skullBlack"></i><i class="icon-skull skullBlack"></i><span> &nbsp;&nbsp;|&nbsp;&nbsp; </span><strong>Standard</strong> <i class="icon-skull skullFirst"></i><i class="icon-skull"></i><i class="icon-skull"></i><i class="icon-skull"></i><i class="icon-skull skullBlack"></i></p>
                                          </div>
                                          <div class="clear"></div>
                                        </div>
                                        <div class="difficultySelect">
                                          <a href="#" class="playTale" v-on:click.stop.prevent="buyTale(tale.id)"><i class="icon-play"></i> Buy now for <span v-if="showDiscounted" class="discounted">{{ currencySign + originalPrice }}</span> {{ currencySign + totalPrice }}</a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>
                        </div>

                    </div>

                </div>

            </div>

    </section>

  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

import AccountMenu from '../components/AccountMenu.vue'
import BuyForm from '../components/BuyForm.vue'
import PageTitle from '../components/PageTitle.vue'


export default {
  name: 'AccountContainer',
  components: {
    AccountMenu,
    BuyForm,
    PageTitle
  },
  data () {
    return {
      loaded: false,
      authed: null,
      showPlayOptions: null,

      currency: "GBP",
      currencySign: "£",
      originalPrice: "9.99",
      subtotalPrice: "9.99",
      totalPrice: "9.99",
      showDiscounted: false,
      taleType: "Digital",
      taleId: 1,

      promotion: null,

      otherTales: [],

      revealModal: false,
      revealModalType: null,

    }
  },
  props: {
  },
  computed: {
    getAuth () {
      return this.$store.getters.getAuth
    },
    getTales() {
      return this.$store.getters.getTalesData.data
    },
    ...mapGetters(['getAuth','getTalesData']),
  },
  watch: {
    getAuth (val) {
      this.authed = val
      this.getOtherTales()
    }
  },
  methods: {
    clearAuth() {
      sessionStorage.removeItem('Cursed_CustomerReference')
      this.$cookies.remove('Cursed_CustomerReference')
      this.$store.dispatch("setAuth", null)
    },
    getOtherTales: function() {
        let found = false
        this.otherTales = []

        for (let tale of this.getTalesData.data) {
            found = false
            for (let authTale of this.getAuth.tales) {
                if (tale.id == authTale.tale.id)
                    found = true
            }

            if (!found)
                this.otherTales.push(tale)
        }
        //console.log("this.getTalesData", this.getTalesData)
    },
    facebookShare: function(tale) {
        window.open('https://www.facebook.com/dialog/share?app_id=264902055566132&display=popup&href=' + encodeURIComponent('https://cursed-tales.com') + '&quote=' + encodeURIComponent('I freed the mother in ' + tale.completed_time + '. Can you beat my time?') + '&hashtag=' + encodeURIComponent('#freedthemother'), 'sharer', 'toolbar=0,status=0,width=620,height=280');
    },
    setCurrency() {
      if (this.currency == 'USD') {
        this.currencySign = "$"
        this.originalPrice = "13.99"
        this.subtotalPrice = this.$defaultUSD || "13.99"
        this.totalPrice = this.$defaultUSD || "13.99"
      }
    },
    buyTale(taleId, type = "Digital") {
      this.taleType = type
      this.taleId = taleId
      this.revealModal = true
      this.revealModalType = "buy"
    },
    closeForm() {
      this.revealModal = false
      this.revealModalType = null
      this.$emit('modal-active', false)
    }
  },
  mounted: function() {
    this.authed = this.getAuth

    if (!this.authed) {
      let customerReference = sessionStorage.getItem('Cursed_CustomerReference')
      if (!customerReference && this.$cookies.isKey('Cursed_CustomerReference')) {
        console.log("no customer reference")
        customerReference = this.$cookies.get('Cursed_CustomerReference')
      }

      if (customerReference) {
        axios
          .get('https://api.cursed-tales.com/customers/reference/' + customerReference)
          .then(response => {
            this.authed = response.data;
            this.$store.dispatch("setAuth", response.data)
          })
          .catch(error => {
            console.log(error)
            this.clearAuth()
            this.$router.push({ name: 'login' })
          })
      } else {
        this.clearAuth()
        this.$router.push({ name: 'login' })
      }

    } else {
      this.loaded = true;
    }

    axios
        .post('https://api.cursed-tales.com/misc/get_active_promotion?type=customer' + (this.$developer ? '&developer=true' : ''), {})
        .then(response => {
            console.log("get_active_promotion", response.data)
            if (response.data.country == "US") {
                this.currency = "USD"
                this.setCurrency()
            }

            if (response.data.code) {
                this.promotion = response.data.code
                this.subtotalPrice = response.data.default
                this.totalPrice = response.data.default

                this.showDiscounted = false;
                if (parseFloat(this.totalPrice) < parseFloat(this.originalPrice))
                    this.showDiscounted = true;
            }

        })


    this.getOtherTales()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .account-container {
    max-width: 90% !important;
  }
  .talesContent {
      background: #fff;
      margin: 0 12px;
      padding-top: 40px;
      border-top-right-radius: 5px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
  }

  .talesContent h4 {
      color: #711414;
      font-size: 30px;
  }

  .talesContent .table {
      color: #d6d6d6;
  }

  .talesContent .table tr {
      position: relative;
      border-bottom: 2px solid #ddd;
  }
  .talesContent .table tr:last-child {
      border-bottom: none;
  }
  .talesContent .table td {
    position: relative;
    padding: 10px 0;
    vertical-align: top;
    border: none;
  }

  .talesContent .table th {
      font-size: 18px;
      color: #711414;
  }
  .talesContent .table th.thCenter {
      text-align: center;
  }
  .talesContent .table .taleCell {
    padding-left: 220px;
    padding-right: 20px;
    width: 60%;
    padding-bottom: 40px;
  }
  .talesContent.otherTales .table .taleCell {
    width: 100%;
  }

  .talesContent .table .taleCell .statusLabel {
    position: absolute;
    top: 20px;
    right: 0;
  }
  .talesContent .table .taleCell .statusLabel .completed {
    font-family: 'Andada Pro', sans-serif;
    color: #3bad16;
    padding: 8px;
    border: 2px solid #3bad16;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 400;
    margin: 0 20px 20px;
  }
  .talesContent .table .taleCell .statusLabel .pending {
    font-family: 'Andada Pro', sans-serif;
    color: #d59f07;
    padding: 8px;
    border: 2px solid #d59f07;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 400;
    margin: 0 20px 20px;
  }

  .talesContent .table .taleCell img.tableImg {
      position: absolute;
      top: 0;
      left: 0;
      width: 180px;
      margin-right: 10px;
  }

  .talesContent .table .taleCell h3 {
      font-family: 'Andada Pro', sans-serif;
      font-size: 50px;
      color: #762323;
      margin-bottom: 0;
  }
  .talesContent .table .taleCell .difficultySelect {
    padding-top: 40px;
    border-top: 2px solid #aaa;
  }
  .talesContent .table .taleCell .difficultySelect h4 {
      font-family: 'Andada Pro', sans-serif;
      font-size: 30px;
      color: #000;
      text-align: center;
      margin-bottom: 10px;
  }
  .talesContent .table .taleCell .difficultySelect h5 {
      font-family: 'Andada Pro', sans-serif;
      font-size: 20px;
      color: #777;
      text-align: center;
      padding-bottom: 20px;
  }
  .talesContent .table .taleCell .difficultySelect .col-6 {
      text-align: center;
  }
  .talesContent .table .taleCell .difficultySelect .col-6.first {
    border-right: 2px solid #ddd
  }
  .talesContent .table .taleCell .difficultySelect .col-6 p {
    padding-top: 20px;
  }
  .talesContent .table .taleCell .difficultySelect .col-6 h5 {
    font-family: 'Andada Pro', sans-serif;
    font-size: 18px;
    color: #555;
    text-align: center;
    padding-top: 10px;
  }
  .talesContent .table .taleCell .difficultySelect .col-6 h5 u {
    color: #911414;
  }
  .talesContent .table .taleCell p {
      font-size: 18px;
      color: #333;
      margin-bottom: 10px;
  }
  .talesContent .table .taleCell p.warning {
    font-size: 20px;
    color: #da1717;
  }

  .talesContent .table .taleCell i.skullFirst {
    margin-left: 10px;
  }
  .talesContent .table .taleCell i.skullBlack {
    color: #000;
    opacity: 0.2;
  }

  .talesContent .table .detailsCell {
    width: 15%;
    text-align: center;
    vertical-align: middle;
    padding: 0 20px;
    border-left: 1px solid #eee;
    border-right: 1px solid #ddd;
  }
  .talesContent .table .detailsCell .playTime {
    padding: 10px;
    background: #eee;
    margin-bottom: 10px;
    border: 3px solid #ddd;
  }
  .talesContent .table .detailsCell h4 {
    font-family: 'Andada Pro', sans-serif;
    font-size: 22px;
    color: #762323;
    margin-bottom: 10px;
  }
  .talesContent .table .detailsCell p {
    font-size: 15px;
    color: #333;
    margin-bottom: 10px;
  }
  .talesContent .table .detailsCell a.facebookShare {
    display: block;
    color: #072aa7;
    padding: 5px;
    border: 2px solid #072aa7;
    border-radius: 3px;
    font-size: 15px;
    font-weight: 700;
    margin: 10px 0;
    cursor: pointer;
  }
  .talesContent .table .detailsCell p.gameHints {
    color: #c84d4d
  }
  .talesContent .table .detailsCell p.gameSolutions {
    color: #b01717
  }

  .talesContent .table .playCell {
    width: 15%;
    text-align: center;
    vertical-align: middle;
    border-left: 1px solid #ddd;
  }

  .talesContent .table .playTale,
  .noTales a {
    color: #711414;
    padding: 10px;
    border: 5px solid #711414;
    border-radius: 5px;
    font-size: 32px;
    font-weight: 700;
    margin-top: 20px !important;
  }
  .talesContent .table .playTale:hover,
  .noTales a:hover {
    color: #222;
    border: 5px solid #911414;
  }
  .talesContent .table .playTale.expertOption {
    display: block;
    color: #711414;
    padding: 7px;
    border: 3px solid #711414;
    border-radius: 5px;
    font-size: 22px;
    font-weight: 700;
    margin-top: 20px !important;
  }
  .talesContent .table .playTale.expertOption:hover {
    color: #222;
    border: 3px solid #911414;
  }

  .talesContent .table .zoomHelp {
    display: block;
    padding-top: 20px;
    color: #333;
    font-size: 14px;
  }

  .noTales {
    text-align: center;
    padding: 40px;
    color: #711414;
  }
  .noTales i {
    display: block;
    font-size: 60px;
  }
  .noTales span {
    display: block;
    font-size: 20px;
    padding-bottom: 20px;
  }
    .moreTales {
        margin-top: 100px;
    }
    .moreTales ul {
        list-style: none;
        margin: 0;
    }
    .moreTales ul li {
        display: inline-block;
        list-style: none;
        font-size: 20px;
        padding: 5px 20px;
        background: #fff;
        border: 2px solid #fff;
        border-bottom: none;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
    }
    .moreTales ul li span {
        font-family: 'Andada Pro', sans-serif;
        color: #fff;
        font-size: 30px;
        color: #762323;
        text-shadow: none;
    }
    .moreTales ul li i {
        color: #762323;
        text-shadow: none;
    }

    @media (max-width: 589px) {
        .sidebar ul li.profileLink {
            display: none;
        }
    }

  @media (max-width: 881px) {
    .statusLabel {
      display: none;
    }
  }
  @media (max-width: 799px) {
    .talesContent .table .taleCell img {
      display: none;
    }
    .talesContent .table .taleCell {
      padding-left: 20px;
    }
  }
  @media (max-width: 600px) {
    .talesContent .table .detailsCell,
    .talesContent .table .playCell {
      display: none;
    }
    .talesContent .table .taleCell {
      width: 100%;
    }
  }


</style>
